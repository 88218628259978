import React, { FC } from 'react';
import { Icon, Tooltip } from '@kontentino/ui';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import FileUploadSpecs from '../fileSourceDropZone/FileUploadSpecs';
import { useTranslation } from 'react-i18next';
import useDropZone from './DropZoneProvider';
const DropZoneSpecifications: FC = () => {
  const { t } = useTranslation();
  const { specifications } = useDropZone();

  return (
    <div className="tw-absolute tw-right-3 tw-top-3 tw-flex tw-items-center tw-text-grayscale-100">
      <span className="tw-mr-1 tw-text-xs">
        {t('fileUpload.specifications')}
      </span>
      <Tooltip
        title={t('fileUpload.supportedFiles')}
        content={<FileUploadSpecs specifications={specifications} />}
      >
        <Icon icon={faInfoCircle} size="sm" />
      </Tooltip>
    </div>
  );
};

export default DropZoneSpecifications;
