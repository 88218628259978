import type { ModuleError, ModuleStatus } from 'types/ModuleStatus';
import { MODULE_STATUS } from 'constants/modules';
import { AccountActionNames } from 'modules/account/accountActions';
import { Account } from 'types/Account';
import { InsightsLiteInvitationAccess } from 'app/modules/insightsLite/types/insightsLite';

export interface AccountStore extends Account {
  status: ModuleStatus;
  error: ModuleError;
  insightsLiteInvitationAccess: InsightsLiteInvitationAccess;
}

function getInitialState(): AccountStore {
  return {
    status: MODULE_STATUS.Idle,
    error: null,
    publicId: '',
    label: '',
    isExpired: false,
    isNgo: false,
    isSubscription: false,
    isTrial: false,
    lastTrialEndedAt: null,
    pageCount: null,
    paymentMeta: {
      currency: '',
      monthlyPrice: 0,
    },
    plan: {
      label: '',
      freeUserCount: null,
      pageCount: null,
      userCount: null,
    },
    userCount: null,
    featuresPermissions: {},
    isLastPaymentFailed: false,
    insightsLiteInvitationAccess: {
      invitationActive: false,
      hasInsightsLite: false,
      userAccountId: 0,
      userId: 0,
    },
    onboarded: true,
    onboardingData: null,
  };
}

function accountReducer(state = getInitialState(), action: any) {
  switch (action.type) {
    case AccountActionNames.SET_MODULE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case AccountActionNames.SET_ACCOUNT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case AccountActionNames.SET_ACCOUNT_ONBOARDING_DATA:
      return {
        ...state,
        onboardingData: action.payload,
      };
    default:
      return state;
  }
}

export default accountReducer;
