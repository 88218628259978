import { FC } from 'react';
import { Alert } from '@kontentino/ui';

type Props = {
  children?: string;
};

const ErrorLoadingDetailProperty: FC<Props> = ({ children }) => {
  return (
    <Alert variant="danger">
      {children ||
        'We were unable to complete your request. Close the modal and try again.'}
    </Alert>
  );
};

export default ErrorLoadingDetailProperty;
