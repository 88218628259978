import { FC } from 'react';
import style from 'components/in-app-notifications/history/header/Header.module.scss';
import { useMarkAllNotificationsAsRead } from '../InAppNotifications.hooks';
import { Button } from '@kontentino/ui';

const Header: FC = () => {
  const { mutate: markAllAsRead } = useMarkAllNotificationsAsRead();

  return (
    <div className={style.header}>
      <div className={style.title}>Notifications</div>
      <Button variant="tertiary" onClick={() => markAllAsRead()}>
        Mark all as read
      </Button>
    </div>
  );
};

export default Header;
