import React, { FC } from 'react';
import { Icon } from '@kontentino/ui';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
type Props = {
  message: string;
};

const ProfileSelectorInfoMessage: FC<Props> = ({ message }) => {
  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
      <div className="tw-flex tw-h-[3rem] tw-w-[3rem] tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-[50%] tw-bg-primary-100">
        <Icon icon={faInfoCircle} size={28} className="tw-text-primary-100" />
      </div>
      <div className="tw-mt-3">
        <span className="tw-text-base tw-font-medium">{message}</span>
      </div>
    </div>
  );
};

export default ProfileSelectorInfoMessage;
