import { faImageSlash } from '@fortawesome/pro-regular-svg-icons/faImageSlash';
import { Icon } from '@kontentino/ui';
import React, { FC, memo, useMemo } from 'react';
import PostTypeIcon from 'app/modules/posts/components/PostTypeIcon';
import { getPseudoTypeByPost } from 'utils/post';
import clsx from 'clsx';

const PLACEMENT_STYLES = {
  'top-right': 'tw-top-1 tw-right-1',
  'bottom-right': 'tw-bottom-1 tw-right-1',
};

type Props = {
  postType: number;
  postSubtype: number;
  postTypePlacement?: keyof typeof PLACEMENT_STYLES;
  image: {
    src: string | null | undefined;
    width?: number | string;
    height?: number | string;
  };
};

const HorizontalPostCardImagePreviewWithPostTypeIcon: FC<Props> = ({
  postType,
  postSubtype,
  postTypePlacement = 'bottom-right',
  image,
}) => {
  const type = useMemo(
    () =>
      getPseudoTypeByPost({
        type: postType,
        subtype: postSubtype,
      }),
    [postSubtype, postType],
  );

  return (
    <div
      className="tw-relative tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-grayscale-10 tw-bg-cover tw-bg-center tw-bg-no-repeat"
      style={{
        backgroundImage: `url(${image.src})`,
        height: image.height ?? 60,
        width: image.width ?? 60,
      }}
    >
      {!image.src && (
        <Icon icon={faImageSlash} className="tw-text-grayscale-50" />
      )}
      {type && (
        <div
          className={clsx(
            'tw-absolute tw-flex tw-rounded-sm tw-bg-white tw-px-1 tw-py-[2px]',
            PLACEMENT_STYLES[postTypePlacement],
          )}
        >
          <PostTypeIcon type={type} />
        </div>
      )}
    </div>
  );
};

export default memo(
  HorizontalPostCardImagePreviewWithPostTypeIcon,
  (prevProps, nextProps) => {
    return (
      prevProps.postType === nextProps.postType &&
      prevProps.postSubtype === nextProps.postSubtype &&
      prevProps.postTypePlacement === nextProps.postTypePlacement &&
      prevProps.image.src === nextProps.image.src &&
      prevProps.image.width === nextProps.image.width &&
      prevProps.image.height === nextProps.image.height
    );
  },
);
