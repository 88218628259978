import { useEffect, useState } from 'react';
import {
  Comment,
  CommentAction,
  CommentThread,
} from 'app/modules/comments/types';
import { FileAttachmentsExtensionStorage } from 'app/modules/textEditor/utils/getTextEditorExtenstions';
import { PostCommunicationContent } from 'app/modules/posts/modules/post/components/postCommunication/types/postCommunicationContent';
import { Editor } from '@tiptap/core';
import { isEditCommentAction } from 'app/modules/posts/modules/post/components/postCommunication/utils/commentActions';
import { useActiveContent } from 'app/modules/posts/modules/post/components/postCommunication/hooks/useActiveContent';

type Props = {
  editorRef: Editor | undefined;
  setCommentValue: (value: string) => void;
};

export function useCommentActions({ editorRef, setCommentValue }: Props) {
  const { setActiveContent, allowedActiveContent, activeContent } =
    useActiveContent();
  const [comment, setComment] = useState<Comment>();
  const [commentAction, setCommentAction] = useState<CommentAction | null>(
    null,
  );

  function focusEditor() {
    editorRef?.commands.focus();
  }

  const initReplyCommentAction = (comment: Comment) => {
    setCommentAction('reply');
    setComment(comment);
    focusEditor();

    if (!comment.user.its_me) {
      const { name = '', surname = '' } = comment.user;

      editorRef
        ?.chain()
        .focus()
        .insertUniqueUserMention(
          {
            id: comment.user.id,
            name,
            surname,
            role: comment.user.role,
          },
          { position: 1 },
        )
        .run();
    }
  };

  const initEditCommentAction = (comment: Comment) => {
    setCommentAction('edit');
    setComment(comment);
    focusEditor();
    (
      editorRef?.storage.fileAttachments as FileAttachmentsExtensionStorage
    ).setFiles(
      comment.attachments.map((attachment) => ({
        id: attachment.id,
        isUploading: false,
        hasError: false,
        rawFile: undefined,
        file: attachment,
      })),
    );
  };

  const initReworkCommentAction = (
    threadType: Exclude<CommentThread, 'pinned'>,
  ) => {
    setCommentAction(`${threadType}_rework`);
    setActiveContent(threadType as PostCommunicationContent);
    focusEditor();
  };

  const resetCommentAction = () => {
    setCommentAction(null);
    editorRef?.commands.clearContent();
  };

  useEffect(() => {
    if (!commentAction) {
      setComment(undefined);
      (
        editorRef?.storage.fileAttachments as FileAttachmentsExtensionStorage
      )?.setFiles([]);
      return;
    }

    if (isEditCommentAction(commentAction)) {
      setCommentValue(comment ? comment.content : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentAction, setCommentValue, setComment, comment]);

  const changeActiveContent = (content: PostCommunicationContent) => {
    setActiveContent(content);
    resetCommentAction();
    focusEditor();
  };

  return {
    commentAction,
    initReplyCommentAction,
    initEditCommentAction,
    initReworkCommentAction,
    resetCommentAction,
    comment,
    changeActiveContent,
    activeContent,
    allowedActiveContent,
  };
}
