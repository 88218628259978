import React, { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Divider } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import DatePickerDropdown from 'app/components/datepicker-dropdown';
import DatePickerHeaderCustomPeriod from 'app/components/datepicker-dropdown/custom-header/DatePickerHeaderCustomPeriod';
import DatePickerFooter from 'app/components/datepicker-dropdown/DatePickerFooter';
import DatePickerPresets from 'app/components/datepicker-dropdown/DatePickerPresets';
import {
  getDatesFromPreset,
  getDatesWithConstraints,
  isDateWithinRange,
} from 'app/components/datepicker-dropdown/utils';
import useSetState from 'utils/hooks/useSetState';
import { getRangePlaceholderLabel } from 'app/components/datepicker-dropdown/utils/placeholder';
import { DatePreset } from 'app/components/datepicker-dropdown/enums';

const MAX_DATE = dayjs().endOf('day');

const DATE_PRESETS = [
  DatePreset.TODAY,
  DatePreset.YESTERDAY,
  DatePreset.THIS_WEEK,
  DatePreset.LAST_WEEK,
  DatePreset.THIS_MONTH,
  DatePreset.LAST_MONTH,
  DatePreset.THIS_YEAR,
  DatePreset.LAST_YEAR,
];

type Props = {
  initialPreset: string | null;
  startDate: Dayjs;
  endDate: Dayjs;
  onChange: (
    {
      startDate,
      endDate,
    }: {
      startDate: Dayjs | null;
      endDate: Dayjs | null;
    },
    preset?: string,
  ) => void;
};

const InsightsPresetsDatePicker: FC<Props> = ({
  initialPreset,
  startDate,
  endDate,
  onChange,
}) => {
  const [preset, setPreset] = useState<string | undefined>(
    initialPreset ?? undefined,
  );
  const [error, setError] = useSetState<{
    startDate: boolean;
    endDate: boolean;
  }>({
    startDate: true,
    endDate: true,
  });
  const [isRangeDateViewActive, setIsRangeDateViewActive] = useState<boolean>(
    !initialPreset,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [startDateTemp, setStartDateTemp] = useState<Dayjs | null>(null);
  const [endDateTemp, setEndDateTemp] = useState<Dayjs | null>(null);

  const { t } = useTranslation();

  const onClearCustomPeriod = () => {
    const dates = getDatesWithConstraints(
      {
        startDate: dayjs().startOf('M'),
        endDate: dayjs().endOf('M'),
      },
      { maxEndDate: MAX_DATE },
    );

    onChange(
      {
        startDate: dates.startDate,
        endDate: dates.endDate,
      },
      undefined,
    );
    setStartDateTemp(null);
    setEndDateTemp(null);
    setIsRangeDateViewActive(false);
    setPreset(DatePreset.THIS_MONTH);
  };

  const onCancelCustomPeriod = () => {
    setStartDateTemp(startDate);
    setEndDateTemp(endDate);
  };

  const onDateRangeSubmit = () => {
    onChange(
      {
        startDate: startDateTemp ?? null,
        endDate: endDateTemp ?? null,
      },
      undefined,
    );
    setIsOpen(false);
  };

  const handlePresetSelect = (preset?: string) => {
    setPreset(preset);

    if (preset) {
      const presetDates = getDatesWithConstraints(getDatesFromPreset(preset), {
        maxEndDate: MAX_DATE,
      });

      onChange(presetDates, preset);
      setIsOpen(false);
      return;
    }

    onChange(
      {
        startDate,
        endDate,
      },
      undefined,
    );
  };

  const onRangeSelect = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setStartDateTemp(start ? dayjs(start) : null);
    setEndDateTemp(end ? dayjs(end) : null);
  };

  const selectedDate = isRangeDateViewActive ? null : startDate ?? null;

  useEffect(() => {
    if (startDate && endDate) {
      setError({
        startDate: startDate
          ? isDateWithinRange(startDate, null, MAX_DATE)
          : false,
        endDate: endDate ? isDateWithinRange(endDate, null, MAX_DATE) : false,
      });
    }
  }, [startDate, endDate, setError]);

  return (
    <DatePickerDropdown
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      label={getRangePlaceholderLabel({
        startDateTemp: startDateTemp ?? startDate,
        endDateTemp: endDateTemp ?? endDate,
      })}
      selected={selectedDate?.toDate() ?? null}
      startDate={startDateTemp?.toDate() ?? null}
      endDate={endDateTemp?.toDate() ?? null}
      selectsRange={isRangeDateViewActive}
      showMonthYearPicker={!isRangeDateViewActive}
      dateFormat={!isRangeDateViewActive ? 'MM/yyyy' : undefined}
      hideDefaultDatePicker={!isRangeDateViewActive}
      maxDate={MAX_DATE.toDate()}
      onChange={(val) => {
        isRangeDateViewActive &&
          onRangeSelect(val as [Date | null, Date | null]);
      }}
      renderCustomHeader={(headerProps) =>
        isRangeDateViewActive && (
          <DatePickerHeaderCustomPeriod
            {...headerProps}
            maxDate={MAX_DATE}
            error={error}
            startDate={startDateTemp ?? startDate}
            endDate={endDateTemp ?? endDate}
            onStartDateChange={(date) => setStartDateTemp(date)}
            onEndDateChange={(date) => setEndDateTemp(date)}
          />
        )
      }
    >
      {!isRangeDateViewActive && (
        <div>
          <DatePickerPresets
            presets={DATE_PRESETS}
            onPresetSelect={handlePresetSelect}
            selectedPreset={preset}
          />
          <Divider />
          <Button
            variant={'ghost'}
            onClick={() => {
              setIsRangeDateViewActive(true);
              handlePresetSelect(undefined);
              setEndDateTemp(endDate);
              setStartDateTemp(startDate);
            }}
            className="tw-flex-0 tw-m-1 tw-h-min !tw-p-2"
          >
            {t('customPeriod')}
          </Button>
        </div>
      )}
      {isRangeDateViewActive && (
        <DatePickerFooter
          isCancelDisabled={
            startDate === startDateTemp && endDate === endDateTemp
          }
          onDateRangeSubmit={onDateRangeSubmit}
          onRangeSelectCancel={onCancelCustomPeriod}
          onClearCustomPeriod={onClearCustomPeriod}
        />
      )}
    </DatePickerDropdown>
  );
};

export default InsightsPresetsDatePicker;
