import React, { FC } from 'react';
import { Alert, Badge, Icon, Tooltip } from '@kontentino/ui';
import Skeleton from 'react-loading-skeleton';
import { UseQueryResult } from 'react-query/types/react';
import { AIContentOptions } from 'app/modules/posts/api/aiContent';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { useTranslation } from 'react-i18next';
type Props = {
  options: UseQueryResult<AIContentOptions, unknown>;
};

const AiContentRemainingAttemptsBadge: FC<Props> = ({ options }) => {
  const { t } = useTranslation();

  if (options.isError) {
    return <Alert variant="danger">{t('unableToLoadRemainingAttempts')}</Alert>;
  }

  if (options.isLoading) {
    return (
      <div className="tw-leading-6">
        <Skeleton width="135px" />
      </div>
    );
  }

  return (
    <Badge variant="purple" size="medium">
      Available uses&nbsp;
      {options?.data?.remainingAttempts.all}/
      {options?.data?.availableAttempts.all}
      <Tooltip content={t('dailyGenerateLimitInfo')}>
        <Icon icon={faInfoCircle} size="sm" />
      </Tooltip>
    </Badge>
  );
};

export default AiContentRemainingAttemptsBadge;
