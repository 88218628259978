import { TYPE as PAGE_TYPE } from '@kontentino/kontentino-constants/Pages';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCircleO } from '@fortawesome/pro-regular-svg-icons/faCircleO';
import { faClapperboardPlay } from '@fortawesome/pro-regular-svg-icons/faClapperboardPlay';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faText } from '@fortawesome/pro-regular-svg-icons/faText';
import { faVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import {
  FB_METRICS_PROPERTIES,
  IG_METRICS_PROPERTIES,
  LI_METRICS_PROPERTIES,
} from '../utils/metricProperty';
import {
  POST_TYPE_FB,
  POST_TYPE_IG,
  POST_TYPE_LI,
} from 'app/modules/insights/constants/postType';
export const postTypeFilterOptions: Record<
  number,
  Array<{
    type: number[];
    label: string;
    icon: IconDefinition;
    countKey: string;
  }>
> = {
  [PAGE_TYPE.FACEBOOK]: [
    {
      type: [POST_TYPE_FB.STATUS],
      label: 'Text',
      icon: faText,
      countKey: 'status',
    },
    {
      type: [POST_TYPE_FB.PHOTO],
      label: 'Photo',
      icon: faImage,
      countKey: 'photo',
    },
    {
      type: [POST_TYPE_FB.GALLERY],
      label: 'Gallery',
      icon: faImages,
      countKey: 'gallery',
    },
    {
      type: [POST_TYPE_FB.VIDEO],
      label: 'Video',
      icon: faVideo,
      countKey: 'video',
    },
    {
      type: [POST_TYPE_FB.LINK],
      label: 'Link',
      icon: faLink,
      countKey: 'link',
    },
  ],
  [PAGE_TYPE.INSTAGRAM]: [
    {
      type: [POST_TYPE_IG.IMAGE],
      label: 'Photo',
      icon: faImage,
      countKey: 'image',
    },
    {
      type: [POST_TYPE_IG.CAROUSEL],
      label: 'Gallery',
      icon: faImages,
      countKey: 'carousel_album',
    },
    {
      type: [POST_TYPE_IG.VIDEO],
      label: 'Video',
      icon: faVideo,
      countKey: 'video',
    },
    {
      type: [POST_TYPE_IG.STORY],
      label: 'Story',
      icon: faCircleO,
      countKey: 'story',
    },
    {
      type: [POST_TYPE_IG.REEL],
      label: 'Reel',
      icon: faClapperboardPlay,
      countKey: 'reels',
    },
  ],
  [PAGE_TYPE.LINKEDIN]: [
    {
      type: [POST_TYPE_LI.NONE],
      label: 'Text',
      icon: faText,
      countKey: 'status',
    },
    {
      type: [POST_TYPE_LI.IMAGE, POST_TYPE_LI.RICH],
      label: 'Photo/Gallery',
      icon: faImages,
      countKey: 'photo',
    },
    {
      type: [POST_TYPE_LI.VIDEO],
      label: 'Video',
      icon: faVideo,
      countKey: 'video',
    },
    {
      type: [POST_TYPE_LI.ARTICLE],
      label: 'Link',
      icon: faLink,
      countKey: 'link',
    },
  ],
};

export const restrictedTypesByMetric: Record<
  number,
  Record<string, number[]>
> = {
  [PAGE_TYPE.FACEBOOK]: {
    [FB_METRICS_PROPERTIES.videoAverageTimeWatched.key]: [POST_TYPE_FB.VIDEO],
    [FB_METRICS_PROPERTIES.videoCompleteViewsOrganic.key]: [POST_TYPE_FB.VIDEO],
    [FB_METRICS_PROPERTIES.videoCompleteViewsPaid.key]: [POST_TYPE_FB.VIDEO],
    [FB_METRICS_PROPERTIES.videoViews.key]: [POST_TYPE_FB.VIDEO],
  },
  [PAGE_TYPE.INSTAGRAM]: {
    [IG_METRICS_PROPERTIES.videoViews.key]: [
      POST_TYPE_IG.VIDEO,
      POST_TYPE_IG.REEL,
    ],

    [IG_METRICS_PROPERTIES.comments.key]: [
      POST_TYPE_IG.IMAGE,
      POST_TYPE_IG.VIDEO,
      POST_TYPE_IG.REEL,
      POST_TYPE_IG.CAROUSEL,
    ],
    [IG_METRICS_PROPERTIES.saved.key]: [
      POST_TYPE_IG.IMAGE,
      POST_TYPE_IG.VIDEO,
      POST_TYPE_IG.REEL,
      POST_TYPE_IG.CAROUSEL,
    ],
    [IG_METRICS_PROPERTIES.likes.key]: [
      POST_TYPE_IG.IMAGE,
      POST_TYPE_IG.VIDEO,
      POST_TYPE_IG.REEL,
      POST_TYPE_IG.CAROUSEL,
    ],
    [IG_METRICS_PROPERTIES.engagement.key]: [
      POST_TYPE_IG.IMAGE,
      POST_TYPE_IG.VIDEO,
      POST_TYPE_IG.REEL,
      POST_TYPE_IG.CAROUSEL,
    ],
    [IG_METRICS_PROPERTIES.replies.key]: [POST_TYPE_IG.STORY],
    [IG_METRICS_PROPERTIES.tapsBack.key]: [POST_TYPE_IG.STORY],
    [IG_METRICS_PROPERTIES.tapsForward.key]: [POST_TYPE_IG.STORY],
    [IG_METRICS_PROPERTIES.exits.key]: [POST_TYPE_IG.STORY],
  },
  [PAGE_TYPE.LINKEDIN]: {
    [LI_METRICS_PROPERTIES.videoViews.key]: [POST_TYPE_LI.VIDEO],
    [LI_METRICS_PROPERTIES.viewers.key]: [POST_TYPE_LI.VIDEO],
    [LI_METRICS_PROPERTIES.timeWatched.key]: [POST_TYPE_LI.VIDEO],
  },
};
