import DateUtils, { formatDate, formatDateToMonthAndDay } from 'app/utils/date';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';

export const getPresetPlaceholderLabel = ({
  startDate,
  endDate,
}: {
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
}) => {
  let startDateString = t('notSelected');
  let endDateString = t('notSelected');
  const isTempStartDateValid = DateUtils.isValid(startDate);
  const isTempEndDateValid = DateUtils.isValid(endDate);

  if (!isTempStartDateValid && !isTempEndDateValid) {
    return `${t('selectDateRange')}...`;
  }

  if (isTempStartDateValid) {
    startDateString = dayjs(startDate).isSame(dayjs(endDate), 'year')
      ? formatDateToMonthAndDay(startDate)
      : formatDate(startDate);
  }

  if (isTempEndDateValid) {
    endDateString = formatDate(endDate);
  }

  return `${startDateString} - ${endDateString}`;
};

export const getRangePlaceholderLabel = ({
  startDateTemp,
  endDateTemp,
}: {
  startDateTemp: Dayjs | null;
  endDateTemp: Dayjs | null;
}) => {
  let startDateString = t('notSelected');
  let endDateString = t('notSelected');
  const isTempStartDateValid = DateUtils.isValid(startDateTemp);
  const isTempEndDateValid = DateUtils.isValid(endDateTemp);

  if (!isTempStartDateValid && !isTempEndDateValid) {
    return `${t('selectDateRange')}...`;
  }

  if (isTempStartDateValid) {
    startDateString = dayjs(startDateTemp).isSame(dayjs(endDateTemp), 'year')
      ? formatDateToMonthAndDay(startDateTemp)
      : formatDate(startDateTemp);
  }

  if (isTempEndDateValid) {
    endDateString = formatDate(endDateTemp);
  }

  return `${startDateString} - ${endDateString}`;
};
