import { Dispatch, Store } from 'types/Store';
import { createAction } from 'modules/helpers';
import { requestUserAccountData } from 'api/account';
import type { ModuleStatus } from 'types/ModuleStatus';

import { MODULE_STATUS } from 'constants/modules';
import Logger from 'utils/logger';
import { AccountStore } from 'modules/account/accountReducer';
import { InsightsLiteApi } from 'app/modules/insightsLite/api/insightsLite';
import { Account } from 'types/Account';

export const AccountActionNames = {
  SET_ACCOUNT_DATA: 'AccountActionNames-SET_ACCOUNT_DATA',
  SET_MODULE_STATUS: 'AccountActionNames-SET_MODULE_STATUS',
  SET_ACCOUNT_ONBOARDING_DATA: 'AccountActionNames-SET_ACCOUNT_ONBOARDING_DATA',
};

export const AccountActions = {
  setAccountData: createAction<Omit<AccountStore, 'status' | 'error'>>(
    AccountActionNames.SET_ACCOUNT_DATA,
  ),
  setModuleStatus: createAction<ModuleStatus>(
    AccountActionNames.SET_MODULE_STATUS,
  ),
  setAccountOnboardingData: createAction<Account['onboardingData']>(
    AccountActionNames.SET_ACCOUNT_ONBOARDING_DATA,
  ),
};

export const AccountThunks = {
  requestAccount: () => async (dispatch: Dispatch, getState: () => Store) => {
    dispatch(AccountActions.setModuleStatus(MODULE_STATUS.Loading));

    try {
      const account = await requestUserAccountData();
      const insightsLiteInvitationAccess =
        await InsightsLiteApi.getInvitationAccess();

      dispatch(
        AccountActions.setAccountData({
          ...account,
          insightsLiteInvitationAccess,
        }),
      );
      dispatch(AccountActions.setModuleStatus(MODULE_STATUS.Succeeded));
    } catch (e) {
      Logger.error(e);
      dispatch(AccountActions.setModuleStatus(MODULE_STATUS.Failed));
    }
  },
};
