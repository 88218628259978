import React, { FC, useState } from 'react';
import i18n from 'i18n';
import { useMutation } from 'react-query';
import { SuggestionApi } from 'api/suggestions';
import { preventDefault } from 'utils/mouseEvent';
import { useToast } from 'app/hooks/useToast';
import TextEditorConvertor from 'app/modules/textEditor/utils/textEditorConvertor';
import { Button, Field, TextInput } from '@kontentino/ui';

type Props = {
  onCopyToText(link: string): void;
};

const DirectFbMention: FC<Props> = (props) => {
  const toast = useToast();
  const [value, setValue] = useState('');
  const findMention = useMutation(SuggestionApi.getFacebookMention, {
    onSettled(res) {
      if (res) {
        toast('Mention added.');
        props.onCopyToText(TextEditorConvertor.suggestionToMentionTag(res));
      } else {
        toast('Unable to find mention.', 'warning');
      }
    },
  });

  return (
    <div className="tw-h-auto tw-rounded-md tw-border tw-border-solid tw-border-grayscale-20 tw-bg-white tw-p-4">
      <div className="tw-mb-4 tw-flex tw-items-center tw-gap-1">
        <span className="tw-text-lg tw-font-semibold">
          Add a page mention by name slug or url
        </span>
      </div>
      <form
        onSubmit={preventDefault(() => findMention.mutate(value))}
        className="tw-flex tw-flex-col tw-gap-4 tw-px-1"
      >
        <Field.Group>
          <Field.Label required>Name slug or url</Field.Label>
          <TextInput
            placeholder="e.g. @nutella or facebook.com/nutella..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Field.Group>
        <Field.Group>
          <Button
            type="submit"
            className="tw-mb-1 tw-ml-auto"
            isLoading={findMention.isLoading}
          >
            {i18n.add}
          </Button>
        </Field.Group>
      </form>
    </div>
  );
};

export default DirectFbMention;
