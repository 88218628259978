import React, { FC, useMemo } from 'react';
import { postTypeFilterOptions } from 'app/modules/insights/modules/postsPerformance/constants/postTypeFilter';
import { Icon, mergeTailwindClasses, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';

type Props = {
  pageType: number;
  postType: number;
  className?: string;
};

const PostTypeIndicator: FC<Props> = ({ postType, pageType, className }) => {
  const postTypeOption = useMemo(
    () =>
      postTypeFilterOptions[pageType]?.find((option) =>
        option.type.some((type) => type === postType),
      ),
    [postType, pageType],
  );

  if (postTypeOption) {
    return (
      <Tooltip content={`This is ${postTypeOption.label.toLowerCase()} post`}>
        <div
          className={mergeTailwindClasses(
            clsx(
              'tw-flex tw-h-[24px] tw-w-[28px] tw-items-center tw-justify-center tw-rounded-sm tw-bg-grayscale-5',
              className,
            ),
          )}
        >
          <Icon icon={postTypeOption.icon} className="tw-text-grayscale-100" />
        </div>
      </Tooltip>
    );
  }

  return null;
};

export default PostTypeIndicator;
