import { FC } from 'react';
import { modalRoutes } from 'constants/routes';
import { useModalHistory } from 'context/ModalRouter';
import { Button, Icon } from '@kontentino/ui';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faClose } from '@fortawesome/pro-regular-svg-icons/faClose';

export const PostPreviewFooter: FC = () => {
  const modalHistory = useModalHistory();

  return (
    <div className="tw-flex tw-justify-end tw-gap-2 tw-border-t tw-border-t-grayscale-20 tw-bg-white tw-px-6 tw-py-4">
      <Button
        iconBefore={<Icon icon={faCheck} />}
        variant="success"
        onClick={() => {
          modalHistory.push({
            hash: modalRoutes.PUBLIC_INVITE_USER,
            state: { actionType: 'cta' },
          });
        }}
        data-name="public-link-workflow-buttons"
        data-cy="public-link-workflow-button_approve"
      >
        Approve
      </Button>
      <Button
        iconBefore={<Icon icon={faClose} />}
        className="tw-ml-4"
        variant="danger"
        onClick={() =>
          modalHistory.push({
            hash: modalRoutes.PUBLIC_INVITE_USER,
            state: { actionType: 'cta' },
          })
        }
        data-name="public-link-workflow-buttons"
        data-cy="public-link-workflow-button_rework"
      >
        Rework
      </Button>
    </div>
  );
};
