import { post } from 'api/client';
import { NavigationItemKey } from 'components/navigation/utils/libs';

const AppPreferencesApi = {
  toggleToOldModule(module: NavigationItemKey): Promise<{ old: boolean }> {
    const data = { old: 1 };

    switch (module) {
      case NavigationItemKey.Insights:
        return post('/user/ajaxToggleAnalytics', undefined, { params: data });
      case NavigationItemKey.Posts:
        return post('/user/ajaxToggleNewCalendar', undefined, { params: data });
    }

    return Promise.resolve({ old: false });
  },
};

export default AppPreferencesApi;
