import { FC } from 'react';
import { STEPS } from 'components/workflowOptions/sendForApproval/utils/constants';
import { Button, Icon } from '@kontentino/ui';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import Modal from 'components/shared/Modal';
type Props = {
  onGoNext: () => void;
  activeStep: number;
  canGoNext: boolean;
  isLoading: boolean;
  onSendForApproval: () => void;
  dataNamePrefix: string;
};

const Footer: FC<Props> = ({
  onGoNext,
  activeStep,
  canGoNext,
  isLoading,
  onSendForApproval,
  dataNamePrefix,
}) => {
  if (activeStep === STEPS.SELECT_POSTS.step) {
    return (
      <Modal.Footer>
        <Button
          data-name={`${dataNamePrefix}_next-step`}
          key="next-step"
          onClick={onGoNext}
          disabled={!canGoNext}
          iconAfter={<Icon icon={faArrowRight} />}
        >
          Next step
        </Button>
      </Modal.Footer>
    );
  }

  return (
    <Modal.Footer>
      <Button
        data-name={`${dataNamePrefix}_submit`}
        onClick={onSendForApproval}
        isLoading={isLoading}
        iconAfter={<Icon icon={faUser} />}
      >
        Send for approval
      </Button>
    </Modal.Footer>
  );
};

export default Footer;
