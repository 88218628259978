import React, { FC } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Icon, Tooltip } from '@kontentino/ui';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { formatMetricValue } from 'app/modules/insights/utils/formatMetricValue';
import style from './metricRowSimple/MetricRowSimple.module.scss';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import clsx from 'clsx';
type Props = {
  icon?: IconDefinition;
  label: string;
  value: number | string | null;
  expandable?: boolean;
  expanded?: boolean;
  name: string;
  info?: string;
};

const MetricRowSimple: FC<Props> = ({
  icon,
  label,
  value,
  expandable,
  expanded,
  name,
  info,
}) => {
  return (
    <div className="tw-my-3 tw-flex tw-flex-row tw-items-center tw-justify-between">
      <div className="tw-flex tw-items-center tw-text-[#172B4D]">
        {icon && <Icon icon={icon} className="tw-mr-2 tw-text-grayscale-100" />}
        <div className="tw-text-md tw-font-medium">{label}</div>
        {info && (
          <Tooltip content={info}>
            <Icon icon={faCircleInfo} className={style.icon} marginLeft={8} />
          </Tooltip>
        )}
      </div>
      <div className="tw-flex tw-flex-row">
        <div
          className={clsx('tw-text-lg tw-font-bold tw-text-[#172B4D]', {
            'tw-mr-4': expandable,
            'tw-mr-9': !expandable,
          })}
        >
          {formatMetricValue(name, value)}
        </div>
        {expandable && (
          <Icon
            icon={expanded ? faAngleUp : faAngleDown}
            className="tw-text-grayscale-100"
          />
        )}
      </div>
    </div>
  );
};

export default MetricRowSimple;
