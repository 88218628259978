import { z } from 'zod';
import { PASSWORDS_VALIDATION_MESSAGES } from 'app/components/PasswordValidationPoints';

const ValidationScheme = {
  Email: z
    .string()
    .trim()
    .min(1, 'Email is not allowed to be empty')
    .email({ message: 'The email address is not valid' }),
  RequiredString: (
    options: { message: string } = {
      message: 'Field is not allowed to be empty',
    },
  ) =>
    z.string().trim().min(1, {
      message: options.message,
    }),
  ValidPassword: z
    .string()
    .min(8, { message: PASSWORDS_VALIDATION_MESSAGES.length })
    .refine((password) => /[A-Z]/.test(password), {
      message: PASSWORDS_VALIDATION_MESSAGES.uppercase,
    })
    .refine((password) => /[0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password), {
      message: PASSWORDS_VALIDATION_MESSAGES.numberOrSymbol,
    }),
};

export default ValidationScheme;
