import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare';
import { Icon, Tooltip } from '@kontentino/ui';
import React, { FC } from 'react';
import { stopPropagation } from 'utils/mouseEvent';
type Props = {
  onClick: () => void;
  className?: string;
};

const ProfileSelectorOptionEditButton: FC<Props> = ({ onClick, className }) => {
  return (
    <Tooltip content="Edit">
      <div onClick={stopPropagation(onClick)} className={className}>
        <Icon icon={faPenToSquare} size={16} className="tw-text-grayscale-70" />
      </div>
    </Tooltip>
  );
};

export default ProfileSelectorOptionEditButton;
