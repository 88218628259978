import PostsConfig from 'app/config/posts';
import MediaUtils from 'app/utils/media';
import { FC } from 'react';
import { TYPE } from '@kontentino/kontentino-constants/Pages';

function getFileSpecifications(specifications: Props['specifications']) {
  const imageAllowed = specifications?.accept?.includes('image');
  const videoAllowed = specifications?.accept?.includes('video');

  return [
    {
      name: 'Image',
      value: MediaUtils.getAcceptedImageAttachments().label,
      available: imageAllowed,
    },
    {
      name: 'Video',
      value: MediaUtils.getAcceptedVideoAttachments().label,
      available: videoAllowed,
    },
    {
      name: 'Max image size',
      value: PostsConfig.MAX_IMAGE_SIZE,
      available: imageAllowed,
    },
    {
      name: 'Max video size',
      value: PostsConfig.MAX_VIDEO_SIZE,
      available: videoAllowed,
    },
    {
      name: 'Max files',
      value: specifications?.multiple
        ? PostsConfig.MAX_FILES_TO_ACCEPT
        : PostsConfig.MIN_FILES_TO_ACCEPT,
      available: true,
    },
  ].filter((specification) => specification.available);
}

type Props = {
  specifications: {
    accept?: string;
    multiple?: boolean;
    pageType?: number;
  };
};

const FileUploadSpecs: FC<Props> = ({ specifications }) => {
  return (
    <div>
      <div>
        {getFileSpecifications(specifications).map(
          (spec: { name: string; value: string | number }, index: number) => (
            <div key={`file-upload-spec-${index}`} className="tw-flex">
              <div className="tw-basis-2/5 tw-font-medium">{spec.name}:</div>
              <div>{spec.value}</div>
            </div>
          ),
        )}
      </div>
      {specifications?.pageType === TYPE.INSTAGRAM && (
        <div className="tw-mt-4">
          <span className="tw-font-medium">Instagram direct publishing:</span>
          <div>
            Videos and images are processed to match Instagram technical
            requirements
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadSpecs;
