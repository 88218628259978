import React, { FC } from 'react';
import {
  Button,
  DropdownMenu,
  Icon,
  IconButton,
  SplitButton,
} from '@kontentino/ui';
import { stopPropagation } from 'utils/mouseEvent';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { FileSource } from 'app/components/fileSourceInput/types/fileSource';
type Props = {
  fileSources: [FileSource, ...FileSource[]];
  disabled?: boolean;
};

const FileSourceSelect: FC<Props> = ({ fileSources, disabled }) => {
  const firstSource = fileSources[0];
  const restSources = fileSources.slice(1);

  return (
    <div onClick={stopPropagation()}>
      {restSources.length === 0 ? (
        <Button
          size="small"
          onClick={firstSource.onClick}
          data-name={firstSource.attributes?.dataName}
          data-cy={firstSource.attributes?.dataCy}
          disabled={disabled}
        >
          {firstSource.shortLabel || firstSource.label}
        </Button>
      ) : (
        <SplitButton
          size="small"
          button={
            <Button
              onClick={firstSource.onClick}
              data-name={firstSource.attributes?.dataName}
              data-cy={firstSource.attributes?.dataCy}
              disabled={disabled}
            >
              {firstSource.shortLabel || firstSource.label}
            </Button>
          }
          dropdownButton={
            <IconButton
              size="small"
              icon={<Icon icon={faChevronDown} />}
              disabled={disabled}
            />
          }
          dropdownProps={{ className: 'tw-z-10' }}
        >
          {fileSources.map((fileSource, index) => (
            <DropdownMenu.Item
              key={`file-source-${index}`}
              onSelect={() => fileSource.onClick()}
              onClick={stopPropagation()}
              data-name={fileSource.attributes?.dataName}
              data-cy={fileSource.attributes?.dataCy}
            >
              {fileSource.label}
            </DropdownMenu.Item>
          ))}
        </SplitButton>
      )}
    </div>
  );
};

export default FileSourceSelect;
