import React, { FC } from 'react';
import { faBullseye } from '@fortawesome/pro-regular-svg-icons/faBullseye';
import { faSackDollar } from '@fortawesome/pro-regular-svg-icons/faSackDollar';
import { getAmountSpent } from 'app/modules/insights/utils/getAmountSpent';
import { getObjectivesArray } from 'app/modules/insights/utils/getObjectivesArray';
import { FB_METRICS_PROPERTIES } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import ValueBox from './ValueBox';
import { ExpenseName, TotalExpenseObject } from 'types/AnalyticsPost';
import { StandardPostDetailedMetric } from 'app/types/StandardPostDetailed';
import { getValueBox } from 'app/modules/insights/modules/postsPerformance/utils/getValueBox';
type Props = {
  metrics: Record<string, StandardPostDetailedMetric>;
  totalExpenses?: Record<ExpenseName, TotalExpenseObject>;
};

const FacebookValueBoxes: FC<Props> = ({ metrics, totalExpenses }) => {
  const totalReachUnique = getValueBox(
    metrics[FB_METRICS_PROPERTIES.totalReachUnique.key],
    FB_METRICS_PROPERTIES.totalReachUnique,
  );

  const amountSpent = {
    icon: faSackDollar,
    label: 'Amount Spent',
    value: getAmountSpent(totalExpenses),
  };

  const usedObjectives = {
    icon: faBullseye,
    label: 'Used Objectives',
    value: getObjectivesArray(totalExpenses),
  };

  return (
    <div className="tw-flex tw-flex-wrap">
      <div className="tw-flex-1 tw-p-4">
        <ValueBox {...amountSpent} />
      </div>
      <div className="tw-w-[1px] tw-bg-grayscale-20" />
      <div className="tw-flex-[2] tw-p-4">
        <ValueBox {...usedObjectives} />
      </div>
      <div className="tw-w-[1px] tw-bg-grayscale-20" />
      <div className="tw-flex-1 tw-p-4">
        <ValueBox {...totalReachUnique} />
      </div>
    </div>
  );
};

export default FacebookValueBoxes;
