import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import routes, { modalRoutes } from 'constants/routes';
import { getBasePath } from 'utils/url';
import i18n from 'app/utils/i18n';
import { PostDetail } from 'types/PostDetail';
import { copyLinkToClipboard } from 'actions/helpers/copyLinkToClipboard';
export const copyPostLink = (context: ActionContext) =>
  createAction(
    {
      id: 'copy-post-link',
      label: i18n.t('copyLink'),
      icon: <Icon icon={faLink} />,
      perform: ({ id }: { id: PostDetail['id'] }) => {
        const url = `${window.location.origin}${getBasePath()}${
          routes.POSTS_CALENDAR
        }?id=${id}#${modalRoutes.POST_DETAIL}`;

        copyLinkToClipboard(url);
      },
    },
    context,
  );
