import React, { FC } from 'react';
import PageTypeUtils from 'app/utils/pageType';
import SelectSocialTypeOption from './selectNewProfileType/SelectSocialTypeOption';
import SimpleGrid from 'app/components/SimpleGrid';
import NewManualProfileCard from './NewManualProfileCard';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { useToast } from 'app/hooks/useToast';

export type SelectNewProfileTypeProps = {
  socialMediaTypes: number[];
  manualSocialMediaTypes?: number[];
  onSelectSocialMediaType: (type: number) => void;
  openManualForm?: (type?: number) => void;
  dataNamePrefix?: {
    socialTypeOption?: string;
  };
};

const SelectNewProfileType: FC<SelectNewProfileTypeProps> = ({
  openManualForm,
  socialMediaTypes,
  manualSocialMediaTypes,
  onSelectSocialMediaType,
  dataNamePrefix,
}) => {
  const toast = useToast();

  return (
    <div>
      <SimpleGrid columns={3} gap={16}>
        {socialMediaTypes.map((type) => (
          <SelectSocialTypeOption
            key={type}
            type={type}
            buttonLabel="Connect"
            onClick={
              TYPE.TIKTOK === type
                ? () => toast('Tiktok scheduling is coming soon')
                : () => onSelectSocialMediaType(type)
            }
            dataName={
              dataNamePrefix?.socialTypeOption
                ? `${dataNamePrefix?.socialTypeOption}${PageTypeUtils.getName(
                    type,
                  )}`
                : undefined
            }
          />
        ))}
      </SimpleGrid>
      {!!manualSocialMediaTypes && (
        <div className="tw-mt-4 tw-rounded-md tw-bg-grayscale-5 tw-p-4">
          <h3 className="tw-mb-2 tw-font-semibold">
            or create a profile manually
          </h3>
          <p className="tw-text-grayscale-180">
            You won’t be able to publish posts with profiles that are created
            manually.
          </p>
          <div className="tw-mt-4 tw-space-y-2">
            {manualSocialMediaTypes.map((type) => (
              <NewManualProfileCard
                key={type}
                type={type}
                openManualForm={openManualForm}
                dataCy={`create-new-profile-button-manually`}
                dataName={
                  dataNamePrefix?.socialTypeOption
                    ? `${
                        dataNamePrefix?.socialTypeOption
                      }${PageTypeUtils.getName(type)}_manually`
                    : undefined
                }
              />
            ))}
            <NewManualProfileCard
              type="other"
              openManualForm={openManualForm}
              dataCy={`create-new-profile-button-manually`}
              dataName={
                dataNamePrefix?.socialTypeOption
                  ? `${dataNamePrefix?.socialTypeOption}_manually`
                  : undefined
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectNewProfileType;
