import {
  AttachmentCard as AttachmentCardType,
  AttachmentCard,
  AttachmentCards,
  EmptyMediumAttachmentCard,
  ImageMediumAttachmentCard,
  VideoMediumAttachmentCard,
} from 'types/Attachments';
import { MediaLibraryItem, MediaLibraryItems } from 'types/MediaLibrary';
import { VIDEO_STATUS } from '@kontentino/kontentino-constants/Video';
import { LinkInfo } from 'types/LinkInfo';
import { GalleryItem } from 'lightgallery/lg-utils';

export function isVideoAttachmentCard(
  attachmentCard: AttachmentCard,
): attachmentCard is AttachmentCard<VideoMediumAttachmentCard> {
  return attachmentCard.medium?.type === 'video';
}

export function isImageAttachmentCard(
  attachmentCard: AttachmentCard,
): attachmentCard is AttachmentCard<ImageMediumAttachmentCard> {
  return attachmentCard.medium?.type === 'image';
}

export function getAttachmentsCardsSources(attachmentsCards: AttachmentCards) {
  return attachmentsCards.map((card) => {
    const obj: GalleryItem = {};

    if (isImageAttachmentCard(card)) {
      obj.src = card.medium.sizes.original.src;
      obj.thumb = card.medium.sizes.thumb.src;
    }

    if (isVideoAttachmentCard(card)) {
      obj.poster = card.medium?.original_thumb?.src;
      obj.thumb = card.medium?.original_thumb?.src;
      obj.video = {
        source: [
          {
            src: card.medium.sizes.medium?.src ?? '',
            type: 'video/mp4',
          },
        ],
        // @ts-ignore
        // opened PR https://github.com/sachinchoolur/lightGallery/pull/1424
        attributes: { controls: true },
        tracks: [],
      };
    }

    return obj;
  });
}

export function getAttachmentCardThumbnail(card: AttachmentCardType) {
  if (isImageAttachmentCard(card)) {
    return card.medium.sizes.thumb.src;
  }

  if (isVideoAttachmentCard(card)) {
    return card.medium.custom_thumb?.src
      ? card.medium.custom_thumb.src
      : card.medium.original_thumb?.src;
  }
}

export const getSelectedOrderIndex = (
  selectedIds: number[],
  media: MediaLibraryItem,
) => {
  const index = selectedIds.findIndex((id) => id === media.id);

  return index !== -1 ? index + 1 : undefined;
};

export const AttachmentConvertor = {
  fromMediaLibraryItems(items: MediaLibraryItems): AttachmentCards {
    return items.map(AttachmentConvertor.fromMediaLibraryItem);
  },
  fromMediaLibraryItem(item: MediaLibraryItem): AttachmentCard {
    const base = {
      url: '',
      caption: '',
      call_to_action: null,
      title: '',
      description: '',
      is_original_link_media: false,
    };

    if (item.type === 'video') {
      return {
        ...base,
        medium: {
          id: item.id,
          type: item.type,
          sizes: {
            medium: item.data?.src
              ? {
                  src: item.data.src,
                  height: item.data.height,
                  width: item.data.width,
                }
              : null,
          },
          status: item.meta?.is_available
            ? VIDEO_STATUS.AVAILABLE
            : VIDEO_STATUS.QUEUED,
          original_thumb: item.data?.thumbnail.src
            ? {
                id: item.data?.thumbnail.id,
                src: item.data?.thumbnail.src,
                height: item.data?.thumbnail.height,
                width: item.data?.thumbnail.width,
              }
            : null,
          custom_thumb: null,
          meta: {
            duration: item.meta?.duration ?? 0,
            file_size: 0,
            mime_type: '',
          },
        },
      };
    }

    return {
      ...base,
      medium: {
        id: item.id,
        type: item.type,
        meta: {
          is_animated_gif: item.meta.is_animated_gif,
          is_panorama: item.meta.is_panorama,
          is_spherical: item.meta.is_spehrical,
        },
        sizes: {
          thumb: item.data.thumbnail,
          original: item.data.original,
          lightbox: {
            src: item.data.src,
            height: item.data.height,
            width: item.data.width,
          },
        },
      },
    };
  },
  toMediaLibraryItem(
    cardMedium: ImageMediumAttachmentCard | VideoMediumAttachmentCard,
  ): MediaLibraryItem {
    if (cardMedium?.type === 'video') {
      const videoMedium = {
        src: cardMedium.sizes.medium?.src,
        height: cardMedium.sizes.medium?.height,
        width: cardMedium.sizes.medium?.width,
      };

      return {
        id: cardMedium.id,
        type: cardMedium.type,
        meta: {
          duration: cardMedium.meta.duration,
          is_error: cardMedium.status === VIDEO_STATUS.ERROR,
          is_available: cardMedium.status === VIDEO_STATUS.AVAILABLE,
          is_processed: cardMedium.status === VIDEO_STATUS.PROCESSED,
        },
        data: {
          ...videoMedium,
          original: videoMedium,
          thumbnail: videoMedium,
        },
      };
    }

    return {
      id: cardMedium.id,
      type: cardMedium.type,
      meta: {
        is_animated_gif: cardMedium.meta.is_animated_gif,
        is_panorama: cardMedium.meta.is_panorama,
        is_spehrical: cardMedium.meta.is_spherical,
        usages: 0,
      },
      data: {
        src: cardMedium.sizes.lightbox.src,
        height: cardMedium.sizes.lightbox.height,
        width: cardMedium.sizes.lightbox.width,
        original: cardMedium.sizes.original,
        thumbnail: cardMedium.sizes.thumb,
      },
    };
  },
  fromLinkInfo(
    linkInfo: LinkInfo,
  ): AttachmentCard<ImageMediumAttachmentCard | EmptyMediumAttachmentCard> {
    const linkInfoMedium = linkInfo.media[0];

    return {
      url: linkInfo.original_url ?? linkInfo.url,
      title: linkInfo.title,
      description: linkInfo.description,
      caption: '',
      call_to_action: null,
      is_original_link_media: true,
      medium: linkInfoMedium
        ? {
            type: 'image',
            id: linkInfoMedium.id,
            meta: {
              is_spherical: false,
              is_panorama: false,
              is_animated_gif: Array.isArray(linkInfoMedium.metadata)
                ? false
                : linkInfoMedium.metadata.is_animated_gif ?? false,
            },
            sizes: {
              ...linkInfoMedium.sizes,
              original: linkInfoMedium.sizes.lightbox,
            },
          }
        : null,
    };
  },
};
