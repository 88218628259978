import React, { FC } from 'react';
import useDropZone from './DropZoneProvider';
import FileSourcePicker from '../FileSourcePicker';

const DropZoneFilePicker: FC = () => {
  const { fileSources, disabled, isDragActive } = useDropZone();
  return (
    <FileSourcePicker
      isDragActive={isDragActive}
      fileSources={fileSources}
      disabled={disabled}
      hasDropZoneWrapper
    />
  );
};

export default DropZoneFilePicker;
