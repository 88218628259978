import React, { FC, useEffect, useRef, useState } from 'react';
import { Tag } from '@kontentino/ui';
import { PostLabel } from 'types/Post';
import LabelTag from 'components/shared/LabelTag';

type Props = {
  tags: PostLabel[];
};

const MAX_TAG_WIDTH = 60;

const HorizontalPostCardTags: FC<Props> = ({ tags }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState<PostLabel[]>([]);
  const [truncatedTagsCount, setTruncatedTagsCount] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      let currentWidth = 0;
      const newVisibleTags = [];
      for (const tag of tags) {
        if (currentWidth + MAX_TAG_WIDTH <= containerWidth) {
          newVisibleTags.push(tag);
          currentWidth += MAX_TAG_WIDTH;
        } else {
          break;
        }
      }
      setVisibleTags(newVisibleTags);
      setTruncatedTagsCount(tags.length - newVisibleTags.length);
    }
  }, [tags]);

  return (
    <div
      ref={containerRef}
      className="tw-mt-3 tw-flex tw-items-center tw-gap-1"
    >
      {visibleTags.map((tag) => (
        <LabelTag
          key={tag.id}
          size="small"
          className={`tw-max-w-[${MAX_TAG_WIDTH}px]`}
          name={tag.name}
          color={tag.color}
        />
      ))}
      {truncatedTagsCount > 0 && (
        <Tag size="small" className="tw-whitespace-nowrap">
          + {truncatedTagsCount}
        </Tag>
      )}
    </div>
  );
};

export default HorizontalPostCardTags;
