import React, { FC } from 'react';
import ProfileSelector from 'app/components/ProfileSelector';
import { AdAccount } from 'types/AdAccount';
import { Button, Icon } from '@kontentino/ui';
import Link from 'components/shared/Link';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
import UserPermissionGate from 'components/UserPermissionGate';
import useAdAccountsSelectorFilter from 'app/modules/adsPlanner/components/adAccountsSelector/hooks/useAdAccountsSelectorFilter';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { TYPE_FACEBOOK_AD } from '@kontentino/kontentino-constants/Pages';
import AdAccountsSelectorOptions from 'app/modules/adsPlanner/components/adAccountsSelector/components/AdAccountsSelectorOptions';
type Props = {
  adAccounts: AdAccount[];
  onSelectAdAccount: (adAccount: AdAccount) => void;
  selectedAdAccountId: number | null;
};

const AdAccountsSelector: FC<Props> = ({
  adAccounts,
  onSelectAdAccount,
  selectedAdAccountId,
}) => {
  const { searchQuery, setSearchQuery, filteredAdAccounts } =
    useAdAccountsSelectorFilter(adAccounts);

  const getPlaceholderData = () => {
    const selectedAdAccount = adAccounts.find(
      (adAccount) => adAccount.id === selectedAdAccountId,
    );

    return {
      name: selectedAdAccount?.name ?? 'Select Ad account',
      type: TYPE_FACEBOOK_AD,
    };
  };

  return (
    <ProfileSelector
      isSelectedAll={false}
      title="Ad accounts"
      placeholderData={[getPlaceholderData()]}
      dataName={{
        placeholder: 'adsplanner-adaccount-select',
      }}
      filter={{
        searchQuery,
        onSearch: setSearchQuery,
      }}
      optionsElement={
        <AdAccountsSelectorOptions
          adAccounts={filteredAdAccounts}
          onSelectAdAccount={onSelectAdAccount}
          selectedAdAccountId={selectedAdAccountId}
        />
      }
      footer={
        <UserPermissionGate scopes={UserPermission.AD_ACCOUNT_MANAGE}>
          <div className="tw-border-t tw-border-solid tw-border-grayscale-20 tw-p-4">
            <Link
              style={{ width: '100%' }}
              to={{
                hash: modalRoutes.ADD_AD_ACCOUNT,
              }}
            >
              <Button
                style={{ width: '100%' }}
                variant="tertiary"
                size="small"
                data-name="adsplanner-adaccount-select-new-ad-account"
                iconBefore={<Icon icon={faPlus} />}
              >
                Add Ad account
              </Button>
            </Link>
          </div>
        </UserPermissionGate>
      }
    />
  );
};

export default AdAccountsSelector;
