import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { Avatar, Button, Divider, Icon } from '@kontentino/ui';
import clsx from 'clsx';
import UserPermissionGate from 'components/UserPermissionGate';
import Link from 'components/shared/Link';
import { Module } from 'config';
import { UserPermission } from 'constants/userPermission';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
  user: {
    id: number;
    name: string;
    role: string;
    email?: string;
    avatarSrc?: string;
    isCurrent: boolean;
  };
  className?: string;
};

const PostCommunicationUserCard: FC<Props> = ({
  user: { email, role, name, avatarSrc, isCurrent, id },
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'tw-w-[240px] tw-rounded-md tw-border tw-border-grayscale-20 tw-bg-white tw-shadow-xl',
        className,
      )}
      data-cy="comments_user-card"
      data-name="comments_user-card"
    >
      <div className="tw-flex tw-items-center tw-gap-3 tw-px-2 tw-pb-2 tw-pt-4">
        <Avatar src={avatarSrc} size={48} name={name} />
        <div className="tw-flex tw-flex-col ">
          <span className="tw-w-[150px] tw-truncate tw-text-md tw-font-semibold tw-text-grayscale-180">
            {name}
          </span>
          <span className="tw-text-md tw-font-regular tw-text-grayscale-90">
            {role}
          </span>
        </div>
      </div>

      <div className="tw-mt-2 tw-flex tw-flex-col tw-gap-4 tw-px-4 tw-pb-3">
        {email && (
          <div className="tw-flex tw-gap-2">
            <Icon icon={faEnvelope} className="tw-text-grayscale-100" />
            <Link
              to={`mailto:${email}`}
              className="tw-max-w-[180px] tw-truncate tw-text-md tw-text-primary-100"
            >
              {email}
            </Link>
          </div>
        )}
      </div>

      <Divider />

      <div className="tw-p-4">
        {isCurrent ? (
          <Link to={Module.OldApp.URL.MY_PROFILE} target="_blank">
            <Button
              variant="secondary"
              className="tw-w-full"
              size="large"
              iconBefore={<Icon icon={faUser} />}
            >
              {t('editProfile')}
            </Button>
          </Link>
        ) : (
          <UserPermissionGate scopes={UserPermission.ACCESS_USERS}>
            <Link
              to={`${Module.OldApp.URL.SETTINGS.USER}${id}`}
              target="_blank"
            >
              <Button
                variant="secondary"
                className="tw-w-full"
                size="large"
                iconBefore={<Icon icon={faUser} />}
              >
                {t('seeProfile')}
              </Button>
            </Link>
          </UserPermissionGate>
        )}
      </div>
    </div>
  );
};
export default PostCommunicationUserCard;
