import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import {
  getUniquePrimitiveItemsFromArray,
  togglePrimitiveItemInArray,
} from 'utils/helpers';
import {
  getAreAllPostsSelected,
  getSelectedPostsBetweenRange,
} from 'utils/post';
import { PostDetailWithDate } from 'types/PostDetail';

export const useSelectedPostsState = (data: PostDetailWithDate[]) => {
  const [selectedPosts, setSelectedPosts] = useState<number[]>([]);

  function toggleSelectedPosts(postId: number, selectRange: boolean = false) {
    if (selectRange) {
      const selectedPostsBetween = getSelectedPostsBetweenRange({
        posts: data,
        startPostId: selectedPosts[0],
        endPostId: postId,
      });

      setSelectedPosts(selectedPostsBetween);
    } else {
      setSelectedPosts((prevPosts) =>
        togglePrimitiveItemInArray<number>(postId, prevPosts),
      );
    }
  }

  function selectAllPosts() {
    const allPostIds = data.map((post) => post.id);
    setSelectedPosts(allPostIds);
  }

  function clearSelectedPosts() {
    setSelectedPosts([]);
  }

  function clearSelectedPost(postId: number) {
    setSelectedPosts((prevSelected) =>
      prevSelected.filter((id) => id !== postId),
    );
  }

  function getPostIdsByMonth(monthStartDate: string) {
    return data
      .filter((post) => {
        return dayjs(post.date).isSame(monthStartDate, 'month');
      })
      .map((post) => post.id);
  }

  function getPostIdsbyDay(dayDate: string) {
    return data
      .filter((post) => {
        return dayjs(post.date).isSame(dayDate, 'day');
      })
      .map((post) => post.id);
  }

  function areAllPostsInMonthSelected(monthStartDate: string) {
    const postsInMonth = getPostIdsByMonth(monthStartDate);
    return (
      postsInMonth.length > 0 &&
      postsInMonth.every((postId) => selectedPosts.includes(postId))
    );
  }

  function areAllPostsInDaySelected(dayDate: string) {
    const postsInDay = getPostIdsbyDay(dayDate);
    return (
      postsInDay.length > 0 &&
      postsInDay.every((postId) => selectedPosts.includes(postId))
    );
  }

  function toggleAllPostsInSelectedDay(
    dayDate: string,
    selectRange: boolean = false,
  ) {
    const postsInDay = getPostIdsbyDay(dayDate);
    if (postsInDay.length > 0) {
      if (selectRange) {
        const firstSelectedDay = data.find(
          (post) => post.id === selectedPosts[0],
        )?.date;
        const nextSelectedDay = dayDate;
        const isFirstSelectedDayBeforeNextSelectedDay =
          dayjs(firstSelectedDay).isBefore(nextSelectedDay);

        const selectedPostsBetween = getSelectedPostsBetweenRange({
          posts: data,
          startPostId:
            selectedPosts[
              isFirstSelectedDayBeforeNextSelectedDay
                ? 0
                : selectedPosts.length - 1
            ],
          endPostId:
            postsInDay[
              isFirstSelectedDayBeforeNextSelectedDay
                ? postsInDay.length - 1
                : 0
            ],
        });

        setSelectedPosts(selectedPostsBetween);
      } else {
        setSelectedPosts((prevSelectedPosts) => {
          return areAllPostsInDaySelected(dayDate)
            ? prevSelectedPosts.filter((postId) => !postsInDay.includes(postId))
            : getUniquePrimitiveItemsFromArray([
                ...prevSelectedPosts,
                ...postsInDay,
              ]);
        });
      }
    }
  }

  function toggleSelectedPostsByMonth(monthStartDate: string) {
    const monthPostIds = getPostIdsByMonth(monthStartDate);

    if (monthPostIds.length > 0) {
      setSelectedPosts((prevSelectedPosts) => {
        return areAllPostsInMonthSelected(monthStartDate)
          ? prevSelectedPosts.filter((postId) => !monthPostIds.includes(postId))
          : getUniquePrimitiveItemsFromArray([
              ...prevSelectedPosts,
              ...monthPostIds,
            ]);
      });
    }
  }

  const allPostsSelected = useMemo(
    () => getAreAllPostsSelected(data, selectedPosts),
    [data, selectedPosts],
  );

  return {
    selectedPosts,
    clearSelectedPosts,
    clearSelectedPost,
    selectAllPosts,
    toggleSelectedPosts,
    toggleSelectedPostsByMonth,
    areAllPostsInMonthSelected,
    areAllPostsInDaySelected,
    toggleAllPostsInSelectedDay,
    allPostsSelected,
  };
};
