import React, { ComponentProps, FC, FocusEventHandler } from 'react';
import PageTypeUtils from 'app/utils/pageType';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { Select, SocialMediaIcon, SocialMediaIconProps } from '@kontentino/ui';

type Props = {
  onSelect: (type: number | null) => void;
  defaultValue?: {
    value: number;
  };
  value?: {
    value: number;
  } | null;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onMenuOpen?: () => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  menuPortalTarget?: HTMLElement;
  styles?: ComponentProps<typeof Select<any, false, any>>['styles'];
};

const OPTIONS = [
  TYPE.FACEBOOK,
  TYPE.INSTAGRAM,
  TYPE.LINKEDIN,
  TYPE.TWITTER,
  TYPE.PINTEREST,
  TYPE.GOOGLE_MY_BUSINESS,
  TYPE.YOUTUBE,
  TYPE.TIKTOK,
  TYPE.THREADS,
];

const SocialMediaTypeSelect: FC<Props> = ({
  onSelect,
  defaultValue,
  value,
  ...props
}) => {
  return (
    <Select
      options={OPTIONS.map((option) => ({ value: option }))}
      placeholder="Social media channel"
      menuPlacement="auto"
      formatOptionLabel={(option) => (
        <div
          className="tw-flex tw-items-center tw-gap-2"
          data-cy={`social-type-select-option-${PageTypeUtils.getLabel(
            option.value,
          ).toLowerCase()}`}
        >
          <SocialMediaIcon
            type={
              PageTypeUtils.getName(
                option.value,
              ) as SocialMediaIconProps['type']
            }
            size={24}
          />
          {PageTypeUtils.getLabel(option.value)}
        </div>
      )}
      value={value}
      onChange={(option) => onSelect(option?.value ?? null)}
      defaultValue={defaultValue}
      isSearchable={false}
      {...props}
    />
  );
};

export default SocialMediaTypeSelect;
