import React, { FC } from 'react';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons/faCloudArrowUp';
import { Icon, mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useDropZone from './DropZoneProvider';
const DropZoneHelperText: FC = () => {
  const { t } = useTranslation();
  const { disabled } = useDropZone();

  const textColorClassName = mergeTailwindClasses(
    clsx('tw-text-primary-100', {
      'tw-text-grayscale-50': disabled,
    }),
  );

  return (
    <>
      <Icon icon={faCloudArrowUp} size="xl" className={textColorClassName} />
      <div
        className={clsx('tw-my-3 tw-text-sm tw-font-medium', {
          'tw-text-grayscale-50': disabled,
        })}
      >
        <span className={textColorClassName}>
          {t('fileUpload.clickToUpload')}
        </span>{' '}
        {t('fileUpload.orDnd')}
      </div>
    </>
  );
};

export default DropZoneHelperText;
