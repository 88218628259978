import { useQuery } from '@apollo/client';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { useMemo } from 'react';
import { StandardPost } from 'app/types/StandardPostPreview';
import {
  igPostsQueryNew,
  igPostsQueryNew_posts_edges_node_metrics_edges_node,
} from 'graphql/insights/queries/__generated__/igPostsQueryNew';
import { StandardPostUtils } from 'app/modules/insights/utils/standardPost';
import { IG_POSTS_QUERY_NEW } from 'graphql/insights/queries/igPostsQueryNew';
import { FB_POSTS_QUERY_NEW } from 'graphql/insights/queries/fbPostsQueryNew';
import { fbPostsQueryNew } from 'graphql/insights/queries/__generated__/fbPostsQueryNew';
import { Page } from 'app/modules/insights/types/Page';
import { LI_POSTS_QUERY_NEW } from 'graphql/insights/queries/liPostsQueryNew';
import { liPostsQueryNew } from 'graphql/insights/queries/__generated__/liPostsQueryNew';
import { parseIgPostTypesToQueryVariables } from '../utils/parseIgPostTypesToQueryVariables';
import InsightsConfig from 'app/config/insights';
import { OrderDirection } from 'app/types';
import { getSupportedPostTypesForMetric } from '../utils/getSupportedPostTypesForMetric';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  types: number[];
  first?: number;
};

type Posts =
  | {
      post: StandardPost;
      metrics: igPostsQueryNew_posts_edges_node_metrics_edges_node[];
    }[]
  | undefined;

export function useBestPostsForAllMetricsQuery({
  page,
  startDate,
  endDate,
  metric,
  types,
  first = InsightsConfig.MAX_POSTS_PER_METRIC,
}: Props) {
  const variables = {
    pageId: page.id,
    startDate: DateUtils.toDateString(startDate),
    endDate: DateUtils.toDateString(endDate),
    first,
    types:
      types.length === 0
        ? getSupportedPostTypesForMetric(metric.key, page?._type)
        : types,
    orderBy: [
      {
        field: metric.queryParameter,
        direction: OrderDirection.Desc.toUpperCase(),
      },
    ],
  };

  const ig = useQuery<igPostsQueryNew>(IG_POSTS_QUERY_NEW, {
    variables: {
      ...variables,
      ...parseIgPostTypesToQueryVariables(types),
    },
    skip: page._type !== TYPE.INSTAGRAM,
  });

  const fb = useQuery<fbPostsQueryNew>(FB_POSTS_QUERY_NEW, {
    variables,
    skip: page._type !== TYPE.FACEBOOK,
  });

  const li = useQuery<liPostsQueryNew>(LI_POSTS_QUERY_NEW, {
    variables,
    skip: page._type !== TYPE.LINKEDIN,
  });

  const posts: Posts = useMemo(() => {
    const postPage = {
      name: page.name ?? 'Unknown Page',
      logo: page.picture,
    };

    switch (page._type) {
      case TYPE.INSTAGRAM:
        return ig.data?.posts?.edges
          .map(({ node }) => ({
            post: StandardPostUtils.fromIgPost(node, postPage),
            metrics: node.metrics?.edges.map(({ node }) => node) ?? [],
          }))
          .slice(0, first);
      case TYPE.FACEBOOK:
        return fb.data?.posts?.edges
          .map(({ node }) => ({
            post: StandardPostUtils.fromFbPost(node, postPage),
            metrics: node.metrics?.edges.map(({ node }) => node) ?? [],
          }))
          .slice(0, first);
      case TYPE.LINKEDIN:
        return li.data?.posts?.edges
          .map(({ node }) => ({
            post: StandardPostUtils.fromLiPost(node, postPage),
            metrics: node.metrics?.edges.map(({ node }) => node) ?? [],
          }))
          .slice(0, first);
    }
  }, [ig.data, fb.data, li.data, page._type, page.name, page.picture, first]);

  return {
    posts,
    loading: ig.loading || fb.loading || li.loading,
    error: ig.error || fb.error || li.error,
  };
}
