import { FC } from 'react';
import clsx from 'clsx';

type Props = {
  steps: {
    label: string;
    step: number;
  }[];
  activeStep: number;
};

const StepsHeader: FC<Props> = ({ steps, activeStep }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      {steps.map((step, index) => (
        <div
          key={index}
          className="tw-cursir-pointer tw-bg-su tw-w-full tw-select-none tw-outline-0"
        >
          <div
            className={clsx('tw-h-1 tw-rounded tw-bg-grayscale-20', {
              'tw-bg-success-100': step.step === activeStep,
            })}
          />
          <div className="tw-mt-2 tw-flex tw-items-center tw-text-md">
            <span className="tw-mr-2 tw-font-semibold">Step {step.step}</span>
            <span
              className={clsx('tw-text-grayscale-70', {
                'tw-text-success-100': step.step === activeStep,
              })}
            >
              {step.label}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepsHeader;
