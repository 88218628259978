import { ReferenceLine, XAxis, YAxis } from 'recharts';
import React, { FC, useMemo } from 'react';
import NumberUtil from 'utils/number';
import { Locales } from 'app/config/locales';
import { useTheme } from 'utils/hooks/useTheme';
import { GraphVariant } from 'app/modules/insights/types/PagePerformance';

type Props = {
  graphVariant: GraphVariant;
  dataKey: string;
  showAxis?: {
    xAxis: boolean;
    yAxis: boolean;
  };
  customTickFormat?: boolean;
};

const numberTickFormatter = (value: number) =>
  NumberUtil.format(value, {
    notation: 'compact',
    compactDisplay: 'short',
    locales: Locales.EN_US,
    maximumFractionDigits: 1,
  });

const GraphAxis: FC<Props> = ({ dataKey, graphVariant }) => {
  const theme = useTheme();
  const tickProps = useMemo(
    () => ({
      fill: theme.colors.grayscale['100'],
      fontSize: 10,
      fontWeight: 400,
    }),
    [theme],
  );

  switch (graphVariant) {
    case 'horizontalBar':
      return (
        <>
          <YAxis
            hide
            dataKey={dataKey}
            type="category"
            tick={tickProps}
            orientation="left"
          />
          <ReferenceLine y={0} strokeWidth={0} />
          <XAxis hide type="number" padding={{ right: 60 }} />
        </>
      );
    default:
      return (
        <>
          <XAxis
            dataKey={dataKey}
            type="category"
            tick={tickProps}
            interval="preserveStartEnd"
            tickLine={false}
            axisLine={false}
            dy={9}
          />
          <ReferenceLine y={0} strokeWidth={0} />
          <YAxis
            axisLine={false}
            tickLine={false}
            type="number"
            tickFormatter={numberTickFormatter}
            tick={tickProps}
            orientation="right"
            dx={8}
            width={40}
          />
        </>
      );
  }
};

export default GraphAxis;
