import { useMutation, useQueryClient } from 'react-query';
import { IN_APP_NOTIFICATIONS_QUERY } from 'components/in-app-notifications/InAppNotifications.constants';
import { InAppNotification } from 'types/InAppNotification';
import { createSearchFromParams, openBlank } from 'utils/url';
import InAppNotificationsApi from 'app/api/inAppNotifications';

export const useMarkAllNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation(InAppNotificationsApi.markAllAsRead, {
    onMutate: () => {
      queryClient.setQueryData<{ count: number }>(
        IN_APP_NOTIFICATIONS_QUERY.NOTICIATIONS_COUNT,
        () => {
          return {
            count: 0,
          };
        },
      );
      queryClient.setQueryData([IN_APP_NOTIFICATIONS_QUERY.RETRIEVE], (old) => {
        const prevData = old as {
          pageParams: any[];
          pages: Array<InAppNotification[]>;
        };

        prevData.pages.forEach((items) => {
          items.forEach((item) => {
            item.is_read = true;
          });
        });

        return prevData;
      });
    },
  });
};

export const useToggleNotificationRead = (id: number, read: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(() => InAppNotificationsApi.toggleRead(id, read), {
    onMutate: () => {
      queryClient.setQueryData<{ count: number } | undefined>(
        IN_APP_NOTIFICATIONS_QUERY.NOTICIATIONS_COUNT,
        (prevData) => {
          return prevData
            ? { count: prevData.count + (read ? 1 : -1) }
            : prevData;
        },
      );
      queryClient.setQueryData([IN_APP_NOTIFICATIONS_QUERY.RETRIEVE], (old) => {
        const prevData = old as {
          pageParams: any[];
          pages: Array<InAppNotification[]>;
        };

        prevData.pages.forEach((items, pageIndex) => {
          items.forEach((item, itemIndex) => {
            if (item.id === id) {
              prevData.pages[pageIndex][itemIndex] = {
                ...item,
                is_read: !item.is_read,
              };
            }
          });
        });

        return prevData;
      });
    },
  });
};

export const useInAppNotificationClick = (notification: InAppNotification) => {
  const { mutate: markAsRead } = useToggleNotificationRead(
    notification.id,
    false,
  );

  return () => {
    markAsRead();

    if (notification.ad_post_id && notification.ad_id) {
      const adsPlannerLink = `${window.location.origin}/app/adsplanner/list`;
      const searchParams = createSearchFromParams({
        id: notification.ad_id,
      });

      return openBlank(`${adsPlannerLink}${searchParams}#ad`);
    }

    if (notification.link === null || !notification.link?.length) {
      return;
    }

    return openBlank(notification.link);
  };
};
