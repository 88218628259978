import { gql } from '@apollo/client';
import { CARD_DATA_FRAGMENT } from '../fragments/graphCardData';

export const LI_PAGE_CARD_QUERY = gql`
  ${CARD_DATA_FRAGMENT}
  query liPageCardQuery(
    $id: String!
    $type: String!
    $dateFrom: String!
    $dateTo: String!
    $compareFrom: String
    $compareTo: String
  ) {
    linkedInPage(id: $id) {
      cardData(
        type: $type
        dateFrom: $dateFrom
        dateTo: $dateTo
        compareFrom: $compareFrom
        compareTo: $compareTo
      ) {
        ...CardData
      }
    }
  }
`;
