import React, { FC, ReactNode } from 'react';
import {
  GraphData,
  GraphVariant,
} from 'app/modules/insights/types/PagePerformance';
import { AreaChart, BarChart, LineChart, ResponsiveContainer } from 'recharts';

type Props = {
  variant: GraphVariant;
  data: GraphData[];
  children: ReactNode;
  width?: string | number;
  height?: string | number;
};

const GraphComponent: FC<Props> = ({
  variant,
  data,
  children,
  width,
  height,
}) => {
  const getChartComponentByVariant = (variant: GraphVariant) => {
    switch (variant) {
      case 'line':
        return LineChart;
      case 'area':
        return AreaChart;
      case 'bar':
        return BarChart;
      case 'horizontalBar':
        return BarChart;
      default:
        return null;
    }
  };

  const ChartComponent = getChartComponentByVariant(variant);

  if (!ChartComponent) return null;

  return (
    <ResponsiveContainer
      height={
        height ? height : ['horizontalBar', 'bar'].includes(variant) ? 290 : 208
      }
      width={width}
    >
      <ChartComponent
        data={data}
        layout={variant === 'horizontalBar' ? 'vertical' : 'horizontal'}
        margin={{
          left: variant === 'horizontalBar' ? 0 : 6,
          top: variant === 'horizontalBar' ? 8 : 6,
        }}
      >
        {children}
      </ChartComponent>
    </ResponsiveContainer>
  );
};

export default GraphComponent;
