import { FC, useMemo } from 'react';
import VerticalNavigationMenuOption from 'components/navigation/verticalNavigation/VerticalNavigationMenuOption';
import InAppNotificationsIcon from 'components/in-app-notifications/Icon';
import useInAppNotificationsCountQuery from 'app/modules/inAppNotifications/hooks/useInAppNotificationsCountQuery';
import { VerticalMenuItemWrapper } from '../VerticalMenuItemWrapper';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@kontentino/ui';
import History from 'components/in-app-notifications/History';
import Link from 'components/shared/Link';
import routes from 'constants/routes';
import useBoolean from 'utils/hooks/useBoolean';

type Props = {};

const Notifications: FC<Props> = () => {
  const { data } = useInAppNotificationsCountQuery();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useBoolean(false);

  return useMemo(() => {
    return (
      <Tooltip
        content={<History className="tw-h-[70vh]" />}
        placement="right-start"
        interactive
        interactiveBorder={10}
        interactiveDebounce={100}
        arrow={false}
        className="tw-w-[600px] !tw-max-w-none tw-border !tw-border-grayscale-10 !tw-bg-transparent !tw-p-0 md:tw-w-[700px] lg:tw-w-[800px]"
        onShow={() => setIsOpen.on()}
        onHide={() => setIsOpen.off()}
      >
        <Link
          to={routes.IN_APP_NOTIFICATIONS}
          data-name="navbar_notifications"
          isRouterLink
        >
          <VerticalMenuItemWrapper isActiveMenu={isOpen}>
            <VerticalNavigationMenuOption
              label={t('navigation.userSubActions.notifications')}
              icon={
                <InAppNotificationsIcon notificationsCount={data?.count ?? 0} />
              }
            />
          </VerticalMenuItemWrapper>
        </Link>
      </Tooltip>
    );
  }, [data?.count, t, isOpen, setIsOpen]);
};

export default Notifications;
