import { FC } from 'react';
import Link from 'components/shared/Link';
import config from 'config';
import { Button } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';

export const PreviewExpired: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="tw-my-16 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
      <span>{t('publicPostPreviewExpired')}</span>
      <Link to={config.KONTENTINO_HOMEPAGE_URL}>
        <Button variant="primary">{t('backToHomepage')}</Button>
      </Link>
    </div>
  );
};

export default PreviewExpired;
