export enum EntityTypes {
  Pages = 'pages',
  Posts = 'posts',
  CompetitionAnalysis = 'competition-analysis',
}

export enum GraphType {
  AreaChart = 1,
  LineChart = 2,
  BarChart = 3,
  PieChart = 4,
}

export const GraphLabels = {
  [GraphType.AreaChart]: 'Area Chart',
  [GraphType.LineChart]: 'Line Chart',
  [GraphType.BarChart]: 'Bar Chart',
  [GraphType.PieChart]: 'Pie Chart',
};
