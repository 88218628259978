import {
  MediaLibraryImageItem,
  MediaLibraryImageV1,
  MediaLibraryVideoItem,
} from 'types/MediaLibrary';
import { Video } from 'types/Video';

export function imageToMediaLibraryItem({
  v2,
  additional_info,
}: MediaLibraryImageV1): MediaLibraryImageItem {
  return {
    id: v2.id,
    data: {
      ...v2.sizes.lightbox,
      thumbnail: v2.sizes.thumb,
      original: v2.sizes.original,
    },
    meta: {
      usages: 0,
      is_animated_gif: additional_info.is_animated_gif,
      is_panorama: additional_info.is_panorama,
      is_spehrical: additional_info.is_spherical,
    },
    type: 'image',
  };
}

export function unprocessedVideoToMediaLibraryItem(
  id: number,
): MediaLibraryVideoItem {
  return {
    id,
    type: 'video',
    data: { thumbnail: { src: undefined } },
  };
}

export function processedVideoToMediaLibraryItem(
  video: Required<Video>,
): MediaLibraryVideoItem {
  return {
    id: video.id,
    type: 'video',
    meta: {
      is_processed: true,
      is_available: true,
      is_error: false,
      duration: video?.meta?.duration ?? 0,
    },
    data: {
      src: video.stream_link,
      height: video.height,
      width: video.width,
      thumbnail: {
        src: video.thumbnail.src,
        height: video.thumbnail.height,
        width: video.thumbnail.width,
      },
    },
  };
}
