import React, { FC } from 'react';
import {
  WIDGET_VARIANT_PROPERTIES,
  WidgetVariant,
} from 'app/modules/posts/components/accordionWidget/constants/widgetVariant';
import { Icon, Tooltip } from '@kontentino/ui';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import clsx from 'clsx';
type Props = {
  variant: WidgetVariant;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const AccordionWidgetTitle: FC<Props> = ({
  variant,
  className,
  children,
  ...rest
}) => {
  const widget = WIDGET_VARIANT_PROPERTIES[variant];

  return (
    <div
      className={clsx(
        'tw-flex tw-items-center tw-gap-1 tw-text-base tw-font-semibold tw-text-grayscale-180',
        className,
      )}
      {...rest}
    >
      {widget.title}
      {widget.titleTooltip && (
        <Tooltip content={widget.titleTooltip}>
          <Icon icon={faInfoCircle} className="tw-text-grayscale-100" />
        </Tooltip>
      )}
      {children}
    </div>
  );
};

export default AccordionWidgetTitle;
