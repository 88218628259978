import React, { FC } from 'react';

type Props = {
  src: string; //base64 or url
  onRemove: AnyFunction;
};

const ImagePreview: FC<Props> = (props) => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-sm tw-border tw-border-dashed tw-border-grayscale-50 tw-p-6">
      <img
        src={props.src}
        alt="Preview"
        className="tw-h-[82px] tw-w-auto tw-max-w-full"
      />
      <div
        className="tw-mt-2 tw-cursor-pointer tw-px-2 tw-py-1 tw-text-md tw-text-danger-100 hover:tw-text-danger-120"
        onClick={props.onRemove}
      >
        Remove Image
      </div>
    </div>
  );
};

export default ImagePreview;
