import React, { FC } from 'react';
import { Icon, Tabs } from '@kontentino/ui';
import clsx from 'clsx';
import { usePageMetricCardContentContext } from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardContentProvider';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons/faEllipsis';

type Props = {
  expanded: boolean;
  onToggleExpandClick: () => void;
};

const COLLAPSED_TABS_LIMIT = 4;

const PageMetricCardSubmetricsTabs: FC<Props> = ({
  expanded,
  onToggleExpandClick,
}) => {
  const { activeTabMetric, setActiveTabMetric, metricCardData } =
    usePageMetricCardContentContext();

  if (metricCardData.metricGraphData.length === 0) {
    return null;
  }

  return (
    <>
      {metricCardData.metricGraphData.length > 1 && (
        <Tabs.Root
          defaultValue={activeTabMetric}
          onValueChange={setActiveTabMetric}
          value={activeTabMetric}
        >
          <Tabs.List>
            {metricCardData.metricGraphData
              .map((metric, index) => (
                <Tabs.Tab value={metric.key} key={index}>
                  {!!metric.icon && (
                    <Icon
                      icon={metric.icon}
                      className={clsx('tw-text-grayscale-100', {
                        'tw-text-primary-70': metric.key === activeTabMetric,
                      })}
                    />
                  )}
                  {metric.label}
                </Tabs.Tab>
              ))
              .slice(0, expanded ? undefined : COLLAPSED_TABS_LIMIT)}
            {metricCardData.metricGraphData.length > COLLAPSED_TABS_LIMIT &&
              !expanded && (
                <Tabs.Tab
                  value={
                    metricCardData.metricGraphData[COLLAPSED_TABS_LIMIT].key
                  }
                  onClick={() => onToggleExpandClick()}
                  className="tw-text-grayscale-100"
                >
                  <Icon icon={faEllipsis} />
                </Tabs.Tab>
              )}
          </Tabs.List>
        </Tabs.Root>
      )}
    </>
  );
};

export default PageMetricCardSubmetricsTabs;
