import { ApiClientError, queryClient } from 'api/client';
import FacebookAPI from 'app/api/facebook';
import i18n from 'i18n';
import { useMutation } from 'react-query';
import { useSocialLogin } from 'utils/hooks/useSocialLogin';
import { useToast } from 'app/hooks/useToast';
import { INSIGHTS_FB_AND_IG_LOGIN_SCOPES } from '../constants/socialLoginScopes';
import { QueryKey } from 'components/insights/constants';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { sleep } from 'utils/helpers';
import { useApolloClient } from '@apollo/client';
import { AuthorizationDoneResponse } from 'types/SocialLogin';

type Props = {
  onSuccess?(): void;
  onBeforeSuccess?(): Promise<void>;
};

const useInsightsSocialLogin = (props?: Props) => {
  const toast = useToast();
  const apolloClient = useApolloClient();
  const socialLogin = useSocialLogin<AuthorizationDoneResponse>({
    pageType: TYPE.FACEBOOK,
    onAuthorized: ({ authorizationOptions: { pageType }, accessToken }) => {
      syncToken.mutate({ pageType, accessToken });
    },
    onError: (data) => {
      toast(data.reason ?? i18n.unableToAddYourProfiles, 'error');
    },
  });

  const syncToken = useMutation(
    async ({
      pageType,
      accessToken,
    }: {
      pageType: number;
      accessToken?: string;
    }) => {
      if (TYPE.LINKEDIN === pageType) {
        await sleep(5000);
      }

      if (
        ([TYPE.FACEBOOK, TYPE.INSTAGRAM] as number[]).includes(pageType) &&
        accessToken
      ) {
        await FacebookAPI.updateFacebookAccessToken(
          accessToken,
          INSIGHTS_FB_AND_IG_LOGIN_SCOPES,
        );
      }

      if (props?.onBeforeSuccess) {
        await props.onBeforeSuccess();
      }

      return Promise.resolve();
    },
    {
      onSuccess: () => {
        toast(i18n.yourProfilesWereAdded, 'success');
        apolloClient.resetStore();
        queryClient.resetQueries(QueryKey.initialization());
        props?.onSuccess?.();
      },
      onError: (e: ApiClientError) => {
        toast(e.userMessage ?? i18n.unableToAddYourProfiles, 'error');
      },
    },
  );

  function authorize(pageType: number) {
    const scopes = ([TYPE.INSTAGRAM, TYPE.FACEBOOK] as number[]).includes(
      pageType,
    )
      ? INSIGHTS_FB_AND_IG_LOGIN_SCOPES
      : undefined;

    socialLogin.authorize({ pageType, scopes });
  }

  return {
    authorize,
    isLoading: socialLogin.isLoading || syncToken.isLoading,
  };
};

export default useInsightsSocialLogin;
