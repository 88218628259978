import { create } from 'zustand';
import { useInsightsContainer } from 'components/insights/context';
import { CompetitionAnalysisPage } from 'app/modules/insights/modules/competitionAnalysis/types/page';
import { useEffect, useMemo } from 'react';
import { TYPE } from '@kontentino/kontentino-constants/Pages';

export type CompetitionAnalysisState = {
  selectedPages: CompetitionAnalysisPage[];
  setSelectedPages: (pages: CompetitionAnalysisPage[]) => void;
  onTogglePage: (page: CompetitionAnalysisPage) => void;
  showReactions: boolean;
  onToggleShowReactions: () => void;
};

const useCompetitionAnalysisState = create<CompetitionAnalysisState>(
  (set, get) => ({
    selectedPages: [],
    showReactions: false,
    onToggleShowReactions: () => {
      set({ showReactions: !get().showReactions });
    },
    setSelectedPages: (pages: CompetitionAnalysisPage[]) =>
      set({ selectedPages: pages }),
    onTogglePage: (page) => {
      const { selectedPages } = get();
      const selectedPagesIds = selectedPages.map((page) => page._id);

      set({
        selectedPages: selectedPagesIds.includes(page._id)
          ? selectedPages.filter(({ _id }) => _id !== page._id)
          : [...selectedPages, page],
      });
    },
  }),
);

export const useCompetitionAnalysis = () => {
  const { activeState } = useInsightsContainer();
  const competitionAnalysisState = useCompetitionAnalysisState();

  const selectedPages = useMemo(() => {
    if (activeState.page) {
      const activePage: CompetitionAnalysisPage = {
        _id:
          activeState.page._type === TYPE.INSTAGRAM
            ? activeState.page.username ?? activeState.page._id
            : activeState.page._id,
        _type: activeState.page._type,
        picture: activeState.page.picture ?? undefined,
        name: activeState.page?.name ?? activeState.page?.username ?? 'Unknown',
      };

      return [activePage, ...competitionAnalysisState.selectedPages];
    }

    return competitionAnalysisState.selectedPages;
  }, [competitionAnalysisState.selectedPages, activeState.page]);

  useEffect(() => {
    competitionAnalysisState.setSelectedPages([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeState.page?._type]);

  return {
    ...competitionAnalysisState,
    selectedPages,
  };
};
