import { Dispatch, Store } from 'types/Store';
import { createAction } from 'modules/helpers';
import UserApi from 'api/user';
import { MODULE_STATUS } from 'constants/modules';
import { User, UserLastPostsQueryParams } from 'types/User';
import type { ModuleStatus } from 'types/ModuleStatus';
import Logger from 'utils/logger';
import config from 'config';
import { UserPermission } from 'constants/userPermission';
import { createGetAvailableStatuses } from 'utils/statuses';
import dayjs from 'dayjs';

export const UserActionNames = {
  SET_USER_DATA: 'UserActionNames-SET_USER_DATA',
  SET_MODULE_STATUS: 'UserActionNames-SET_MODULE_STATUS',
  SET_USER_PERMISSIONS: 'UserActionNames-SET_USER_PERMISSIONS',
  SET_ALLOWED_POST_STATUSES: 'UserActionNames-SET_ALLOWED_POST_STATUSES',
  SAVE_LAST_FILTER_STATE: 'UserActionNames-SAVE_LAST_FILTER_STATE',
};

export const UserActions = {
  setUserData: createAction<User>(UserActionNames.SET_USER_DATA),
  setModuleStatus: createAction<ModuleStatus>(
    UserActionNames.SET_MODULE_STATUS,
  ),
  setUserPermissions: createAction<string[]>(
    UserActionNames.SET_USER_PERMISSIONS,
  ),
  setAllowedPostsStatuses: createAction<(pageTypes: number[]) => number[]>(
    UserActionNames.SET_ALLOWED_POST_STATUSES,
  ),
  saveLastFilterState: createAction<UserLastPostsQueryParams>(
    UserActionNames.SAVE_LAST_FILTER_STATE,
  ),
};

export const UserThunks = {
  requestUser: () => async (dispatch: Dispatch, getState: () => Store) => {
    dispatch(UserActions.setModuleStatus(MODULE_STATUS.Loading));

    try {
      const userData = await UserApi.getData();

      dispatch(UserActions.setUserData(userData));
      dispatch(UserActions.setModuleStatus(MODULE_STATUS.Succeeded));

      dayjs.updateLocale('en', {
        weekStart: !!userData.useMeridianTime ? 0 : 1,
      });
    } catch (e) {
      Logger.error(e);
      dispatch(UserActions.setModuleStatus(MODULE_STATUS.Failed));
    }
  },
  loadUserPermissions: () => async (dispatch: Dispatch) => {
    try {
      let permissions = await UserApi.getPermissions();

      if (config.ALL_USER_PERMISSIONS_ENABLED) {
        permissions = Object.values(UserPermission);
      }

      dispatch(UserActions.setUserPermissions(permissions));

      const availableStatuses = createGetAvailableStatuses(permissions);

      dispatch(UserActions.setAllowedPostsStatuses(availableStatuses));
    } catch (e) {
      Logger.error(e);
    }
  },
};
