import React, { FC } from 'react';
import PDF_EXPORT_COVER_IMAGE from '../images/pdf-export-cover-image.svg';
import { BrandLogo } from '@kontentino/ui';
import { Page } from 'app/modules/insights/types/Page';
import DateUtils, { formatDate } from 'app/utils/date';
import { Dayjs } from 'dayjs';
import PageTypeUtils from 'app/utils/pageType';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
import { useInsightsContainer } from 'components/insights/context';

type Props = {
  title?: string;
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
};

const PdfExportCoverPage: FC<Props> = ({
  title = 'Report',
  page,
  startDate,
  endDate,
}) => {
  const { filterState } = useInsightsContainer();

  return (
    <div className="tw-relative tw-flex tw-h-full tw-flex-col tw-text-grayscale-180">
      <div className="tw-absolute tw-left-1/2 tw-top-1/2 tw-flex -tw-translate-x-1/2 -tw-translate-y-1/2 tw-flex-col tw-items-center tw-justify-center">
        <img
          className="tw-h-[400px] tw-w-[400px] tw-object-contain"
          src={PDF_EXPORT_COVER_IMAGE}
          alt="Cover"
        />
        <div className="tw-text-4xl tw-font-semibold">{title}</div>
        <span className="tw-text-center tw-text-xl">
          {`${formatDate(DateUtils.toDateString(startDate))} - ${formatDate(
            DateUtils.toDateString(endDate),
          )}`}
        </span>
        {filterState.comparedStartDate && filterState.comparedEndDate && (
          <span className="tw-text-center tw-text-lg">
            {` compared to ${formatDate(
              DateUtils.toDateString(filterState.comparedStartDate),
            )} - ${formatDate(
              DateUtils.toDateString(filterState.comparedEndDate),
            )}`}
          </span>
        )}
      </div>
      <div className="gap-2 tw-mt-auto tw-flex tw-items-center">
        <BrandLogo
          iconSize={30}
          size={80}
          className="!tw-rounded"
          socialMediaType={getSocialMediaTypeForBrandLogo(page._type)}
          src={page.picture ?? ''}
        />
        <div className="tw-flex-start tw-ml-4 tw-flex tw-flex-col tw-justify-center tw-gap-1">
          <span className="tw-text-2xl tw-font-semibold">{page.name}</span>
          <span>{PageTypeUtils.getLabel(page._type)}</span>
        </div>
      </div>
    </div>
  );
};

export default PdfExportCoverPage;
