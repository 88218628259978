import { FC, ReactNode } from 'react';
import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';

type Props = {
  children: ReactNode;
  className?: string;
};

const Container: FC<Props> = ({ children, className }) => {
  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-mx-auto tw-w-full tw-px-4 sm:tw-max-w-[540px] md:tw-max-w-[720px] lg:tw-max-w-[960px] xl:tw-max-w-[1140px] 2xl:tw-max-w-[1265px]',
          className,
        ),
      )}
    >
      {children}
    </div>
  );
};

export default Container;
