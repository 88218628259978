import { TYPE } from '@kontentino/kontentino-constants/Pages';

export const MANUAL_ONLY_PAGE_TYPES: number[] = [TYPE.YOUTUBE, TYPE.TIKTOK];
export const SORTED_PAGE_TYPES: number[] = [
  TYPE.FACEBOOK,
  TYPE.FACEBOOK_AD,
  TYPE.INSTAGRAM,
  TYPE.THREADS,
  TYPE.LINKEDIN,
  TYPE.TIKTOK,
  TYPE.YOUTUBE,
  TYPE.TWITTER,
  TYPE.PINTEREST,
  TYPE.GOOGLE_MY_BUSINESS,
  TYPE.GOOGLE_PLUS,
] as const;
