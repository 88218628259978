import React, { FC } from 'react';
import { faMedal } from '@fortawesome/pro-regular-svg-icons/faMedal';
import { Icon, mergeTailwindClasses, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
export type MedalRank = 'gold' | 'silver' | 'bronze';

type Props = {
  rank: MedalRank;
  className: string;
};

const rankColors: Record<MedalRank, string> = {
  gold: '#FFC400',
  silver: '#B3B9CE',
  bronze: '#EC8F25',
};

const MedalRankBadge: FC<Props> = (props) => {
  return (
    <Tooltip
      content="These medals indicate the top 3 best performing
    posts according to your selected metric."
      placement="top-start"
    >
      <div
        style={{
          backgroundColor: rankColors[props.rank],
        }}
        className={mergeTailwindClasses(
          clsx(
            'tw-flex tw-h-[34px] tw-w-[34px] tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-[50%] tw-border-[2px] tw-border-solid tw-border-white',
            props.className,
          ),
        )}
      >
        <Icon icon={faMedal} className="tw-text-white" />
      </div>
    </Tooltip>
  );
};

export default MedalRankBadge;
