import React, { FC, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import MetricRowSimple from './MetricRowSimple';
import { StandardPostDetailedMetricAdditionalData } from '../../../../../../types/StandardPostDetailed';
import MetricRowWithAdditionalData from './MetricRowWithAdditionalData';
import {
  FB_METRICS_PROPERTIES,
  LI_METRICS_PROPERTIES,
} from '../../utils/metricProperty';
import Reactions from './Reactions';
import { shouldShowMetricRowExpanded } from '../../utils/shouldShowMetricRowExpanded';
import clsx from 'clsx';

type Props = {
  name: string;
  icon?: IconDefinition;
  label: string;
  value: number | string | null;
  additionalData?: StandardPostDetailedMetricAdditionalData[];
  withSegments?: boolean;
  pageType: number;
  info?: string;
};

const MetricRow: FC<Props> = ({
  name,
  icon,
  label,
  info,
  value,
  additionalData,
  withSegments = true,
  pageType,
}) => {
  const [expanded, setExpanded] = useState(false);

  const renderExpandedBodyComponent = (
    additionalData: StandardPostDetailedMetricAdditionalData[],
  ) => {
    if (
      name === FB_METRICS_PROPERTIES.reactions.key ||
      name === LI_METRICS_PROPERTIES.reactions.key
    ) {
      return <Reactions reactions={additionalData} pageType={pageType} />;
    }
    return (
      <MetricRowWithAdditionalData
        additionalData={additionalData}
        withSegments={withSegments}
      />
    );
  };

  return shouldShowMetricRowExpanded(value, additionalData) ? (
    <div className="tw-border-0">
      <div
        className="tw-cursor-pointer"
        onClick={() => setExpanded((prevState) => !prevState)}
      >
        <MetricRowSimple
          name={name}
          icon={icon}
          label={label}
          value={value}
          info={info}
          expandable
          expanded={expanded}
        />
      </div>
      <div
        className={clsx({
          'tw-hidden': !expanded,
        })}
      >
        {renderExpandedBodyComponent(additionalData!)}
      </div>
    </div>
  ) : (
    <MetricRowSimple
      icon={icon}
      label={label}
      value={value}
      name={name}
      info={info}
    />
  );
};

export default MetricRow;
