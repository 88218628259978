import { FC } from 'react';
import {
  NavigationItem,
  NavigationItemKey,
} from 'components/navigation/utils/libs';
import UserPermissionGate from 'components/UserPermissionGate';
import MenuSecondaryLink from './MenuSecondaryLink';
import MenuSecondaryLinkGroup from './MenuSecondaryLinkGroup';
import { useLocation } from 'react-router-dom';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';

type Props = {
  activeItem?: NavigationItem;
  hoveredItem?: NavigationItem;
};

export const getNavigationItemUrl = (item: NavigationItem) => {
  return item.routerUrl ? item.routerUrl : item.url ?? '';
};

const MenuSecondaryItems: FC<Props> = ({ activeItem, hoveredItem }) => {
  const { showGridViewOptionInNavigation } = useAppLayoutState();
  const { pathname } = useLocation();

  return (
    <div className="tw-flex tw-flex-col tw-gap-y-2">
      {hoveredItem?.items &&
        hoveredItem.items
          .filter((item) =>
            !showGridViewOptionInNavigation
              ? item.key !== NavigationItemKey.PostsGrid
              : item,
          )
          .filter((item) => !item.isDisabled)
          .map((item) => {
            return (
              <UserPermissionGate key={item.key} scopes={item.permissionsScope}>
                {item.items?.length ? (
                  <MenuSecondaryLinkGroup item={item} activeItem={activeItem} />
                ) : (
                  <MenuSecondaryLink
                    item={item}
                    isActive={pathname === getNavigationItemUrl(item)}
                  />
                )}
              </UserPermissionGate>
            );
          })}
    </div>
  );
};

export default MenuSecondaryItems;
