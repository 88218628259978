import React, { FC, ReactNode, useEffect } from 'react';
import Navigation from 'components/Navigation';
import Head from 'components/Head';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';
import { VerticalNavigationWidth } from 'constants/appLayout';
import { animated, useSpring } from '@react-spring/web';
import { useUserPilotReload } from 'app/hooks/useUserPilotReload';
import BackdropLoader from 'app/components/BackdropLoader';
import { useLastPaymentStatusBanner } from './useLastPaymentStatusBanner';
import NetworkStatus from 'app/components/NetworkStatus';

type Props = {
  children: ReactNode | ReactNode[];
};

const AppLayout: FC<Props> = (props) => {
  const { isNavigationExpanded, backdropLoaderShown } = useAppLayoutState();
  const styles = useSpring({
    paddingLeft: isNavigationExpanded
      ? VerticalNavigationWidth.large
      : VerticalNavigationWidth.minimal,
  });

  useEffect(() => {
    document.body.dataset.navigationExpanded = `${isNavigationExpanded}`;
  }, [isNavigationExpanded]);

  useUserPilotReload();
  useLastPaymentStatusBanner();

  return (
    <>
      <Head />
      <div
        className="tw-relative tw-flex tw-flex-1 tw-overflow-hidden"
        data-cy="app-layout"
      >
        <Navigation />
        <animated.main className="bg-gradient-primary tw-w-full" style={styles}>
          {props.children}
        </animated.main>
        <NetworkStatus />
      </div>
      {backdropLoaderShown && <BackdropLoader />}
    </>
  );
};

export default AppLayout;
