import React, { FC } from 'react';
import {
  DropdownMenu,
  Icon,
  IconButton,
  mergeTailwindClasses,
} from '@kontentino/ui';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons/faCloudArrowUp';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';
import { stopPropagation } from 'utils/mouseEvent';
import { FileSource } from 'app/components/fileSourceInput/types/fileSource';
import clsx from 'clsx';
type Props = {
  fileSources: [FileSource, ...FileSource[]];
  className?: string;
  disabled?: boolean;
  hasDropZoneWrapper?: boolean;
  isDragActive?: boolean;
};

const FileSourcePicker: FC<Props> = (props) => {
  const FILE_SOURCE_DROPDOWN_MENU_DATATYPE = 'file-source-dropdown-menu';

  const textColorClassName = mergeTailwindClasses(
    clsx('tw-text-primary-100', {
      'tw-text-grayscale-50': props.disabled,
    }),
  );

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const targetDataType = (e.target as HTMLElement).getAttribute('datatype');

    if (FILE_SOURCE_DROPDOWN_MENU_DATATYPE === targetDataType) {
      e.stopPropagation();
      return;
    }

    if (props?.hasDropZoneWrapper) return;

    props.fileSources[0].onClick();
  };

  return (
    <div
      data-name="file-source_picker"
      className={mergeTailwindClasses(
        clsx(
          'tw-group tw-relative tw-mr-2 tw-flex tw-h-[5.75rem] tw-w-[5.75rem] tw-flex-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-dashed tw-border-primary-100 tw-bg-white tw-transition-colors hover:tw-bg-primary-10',
          {
            'tw-pointer-events-none tw-cursor-not-allowed tw-border-grayscale-30 tw-bg-grayscale-5':
              props.disabled,
          },
          props.className,
        ),
      )}
      onClick={handleOnClick}
    >
      <Icon icon={faCloudArrowUp} className={textColorClassName} size="xl" />
      <DropdownMenu.Root>
        <DropdownMenu.Trigger datatype={FILE_SOURCE_DROPDOWN_MENU_DATATYPE}>
          <IconButton
            variant="ghost"
            size="small"
            className="tw-absolute tw-bottom-1 tw-right-1 tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-100"
          >
            <Icon icon={faEllipsisVertical} className={textColorClassName} />
          </IconButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="tw-z-10">
          {props.fileSources.map((fileSource, index) => (
            <DropdownMenu.Item
              key={`file-source-${index}`}
              onSelect={fileSource.onClick}
              onClick={stopPropagation()}
              data-name={`file-source_option-${fileSource.attributes?.dataName}`}
              data-cy={`file-source_option-${fileSource.attributes?.dataCy}`}
            >
              {fileSource.label}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};

export default FileSourcePicker;
