import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  CoreSuggestion,
  HashtagSuggestion,
  MentionSuggestion,
} from 'types/Suggestions';
import PAGE_VERIFIED from 'images/posts/pageVerifiedBadge.png';
import DemoBadge from 'app/components/DemoBadge';
import { Avatar } from '@kontentino/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  items: (CoreSuggestion & Partial<HashtagSuggestion & MentionSuggestion>)[];
  command(editorMention: {
    label: string;
    id: string;
    role: string | undefined;
  }): void;
};

export const SuggestionsList = forwardRef<any, Props>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({
        id: item.id,
        label: item.name,
        role: item.role,
      });
    }
  };

  const keyDownHandler: Record<string, AnyFunction> = {
    ArrowUp: () =>
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length,
      ),
    ArrowDown: () => setSelectedIndex((selectedIndex + 1) % props.items.length),
    Enter: () => selectItem(selectedIndex),
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: any) => {
      if (Object.keys(keyDownHandler).includes(event.key)) {
        keyDownHandler[event.key]();
        return true;
      } else {
        return false;
      }
    },
  }));

  if (props.items.length === 0) {
    return null;
  }

  return (
    <div className="tw-rounded-lg tw-bg-white tw-p-2 tw-text-grayscale-180 tw-shadow-xl">
      <div
        className="tw-flex tw-max-h-[30vh] tw-flex-col tw-gap-2 tw-overflow-auto"
        ref={ref}
      >
        {props.items.map((item, index) => (
          <button
            className={clsx(
              'tw-min-h-8 tw-flex tw-flex-shrink-0 tw-items-center tw-rounded-md  tw-p-2 tw-text-left hover:tw-bg-primary-20',
              {
                'tw-bg-primary-20': index === selectedIndex,
              },
            )}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.image !== null && (
              <Avatar
                src={item.image}
                name={item.name}
                size={20}
                className="tw-mr-2"
              />
            )}
            <div>
              <div className="tw-flex tw-max-w-[300px] tw-items-center tw-text-left tw-text-md tw-font-medium">
                <span className="tw-min-w-0 tw-truncate">{item.name}</span>
                {item.isVerified && (
                  <img
                    src={PAGE_VERIFIED}
                    alt="page verified"
                    className="tw-ml-1 tw-h-3 tw-w-3"
                  />
                )}
                {item.isDemo && (
                  <div className="tw-ml-1">
                    <DemoBadge />
                  </div>
                )}
              </div>
              <div className="tw-text-xs">{item.description}</div>
            </div>
          </button>
        ))}
      </div>
      <div className="tw-mt-2 tw-px-2 tw-text-sm">
        {t('textEditor.suggestionsTip')}
      </div>
    </div>
  );
});
