import {
  liPageCardQuery_linkedInPage_cardData,
  liPageCardQuery_linkedInPage_cardData_detail_edges,
  liPageCardQuery_linkedInPage_cardData_detail_edges_node_data,
  liPageCardQuery_linkedInPage_cardData_general_edges,
  liPageCardQuery_linkedInPage_cardData_graphData_edges,
  liPageCardQuery_linkedInPage_cardData_graphData_edges_node_data_edges,
} from 'graphql/insights/queries/__generated__/liPageCardQuery';
import {
  igPageCardQuery_instagramPage_cardData,
  igPageCardQuery_instagramPage_cardData_detail_edges,
  igPageCardQuery_instagramPage_cardData_detail_edges_node_data,
  igPageCardQuery_instagramPage_cardData_general_edges,
  igPageCardQuery_instagramPage_cardData_graphData_edges,
  igPageCardQuery_instagramPage_cardData_graphData_edges_node_data_edges,
} from 'graphql/insights/queries/__generated__/igPageCardQuery';
import {
  AggregatedMetric,
  GraphData,
  MetricCardData,
  MetricGraphData,
  MetricTableData,
} from '../../../types/PagePerformance';
import { convertToNumber, formatNumberValue } from './format';
import { getPageMetricCardProperty } from '../constants/pageMetricCardProperties';
import {
  fbPageCardQuery_facebookPage_cardData,
  fbPageCardQuery_facebookPage_cardData_detail_edges,
  fbPageCardQuery_facebookPage_cardData_detail_edges_node_data,
  fbPageCardQuery_facebookPage_cardData_general_edges,
  fbPageCardQuery_facebookPage_cardData_graphData_edges,
  fbPageCardQuery_facebookPage_cardData_graphData_edges_node_data_edges,
} from 'graphql/insights/queries/__generated__/fbPageCardQuery';

export const mapGraphqlPageCardQuery = (
  metricKey: string,
  cardData:
    | liPageCardQuery_linkedInPage_cardData
    | igPageCardQuery_instagramPage_cardData
    | fbPageCardQuery_facebookPage_cardData
    | undefined
    | null,
  pageType: number,
): MetricCardData => {
  const metricCardProperties = getPageMetricCardProperty(pageType, metricKey);

  const { isCombinedMetric, metricGraphData } = mapGraphData(
    metricKey,
    cardData?.graphData?.edges ?? [],
    pageType,
    {
      sliceLimit: metricCardProperties?.sliceLimit,
    },
  );

  const metricTableData = mapMetricTableData(
    metricKey,
    cardData?.detail?.edges ?? [],
    pageType,
  );

  const generalData = mapGeneralData(
    metricKey,
    cardData?.general?.edges ?? [],
    pageType,
  );

  return {
    metricCardProperties,
    metricGraphData,
    metricTableData,
    generalData,
    isCombinedMetric,
  };
};

const mapGeneralData = (
  metricKey: string,
  generalData:
    | liPageCardQuery_linkedInPage_cardData_general_edges[]
    | fbPageCardQuery_facebookPage_cardData_general_edges[]
    | igPageCardQuery_instagramPage_cardData_general_edges[],
  pageType: number,
): AggregatedMetric[] =>
  generalData.map((edge) => {
    const cardProperties = getPageMetricCardProperty(pageType, metricKey);

    const metricName = edge.node.name;

    return {
      value:
        cardProperties?.valueFormatter(edge.node.value, {
          metricKey: metricName,
        }) ?? '',
      metricKey: metricName,
      compareValue: edge.node.compareValue
        ? cardProperties?.valueFormatter(edge.node.compareValue, {
            metricKey: metricName,
          })
        : undefined,
      label: cardProperties.generalDataLabels?.[metricName] ?? '',
      difference:
        typeof edge.node.percentage === 'string'
          ? convertToNumber(edge.node.percentage)
          : undefined,
    };
  });

const mapMetricTableData = (
  metricKey: string,
  detailData:
    | liPageCardQuery_linkedInPage_cardData_detail_edges[]
    | fbPageCardQuery_facebookPage_cardData_detail_edges[]
    | igPageCardQuery_instagramPage_cardData_detail_edges[],
  pageType: number,
) => {
  const metricTableData: MetricTableData = {};
  detailData.forEach((detailDataEdge) => {
    if (detailDataEdge.node.name && detailDataEdge.node.data) {
      metricTableData[detailDataEdge.node.name] = getMetricTableDataRows(
        detailDataEdge.node.data,
        metricKey,
        pageType,
      );
    }
  });
  return metricTableData;
};

const getMetricTableDataRows = (
  data:
    | liPageCardQuery_linkedInPage_cardData_detail_edges_node_data
    | fbPageCardQuery_facebookPage_cardData_detail_edges_node_data
    | igPageCardQuery_instagramPage_cardData_detail_edges_node_data,
  metricKey: string,
  pageType: number,
) => {
  return data.edges.map((edge) => ({
    value: formatNumberValue(edge.node.value),
    icon: getPageMetricCardProperty(pageType, metricKey)?.detailDataLabels?.[
      edge.node.metricName
    ]?.icon,
    label:
      getPageMetricCardProperty(pageType, metricKey)?.detailDataLabels?.[
        edge.node.metricName
      ]?.label ?? '',
    comparedValue: edge.node.compareValue
      ? formatNumberValue(edge.node.compareValue)
      : undefined,
  }));
};

const mapGraphData = (
  metricKey: string,
  graphData:
    | liPageCardQuery_linkedInPage_cardData_graphData_edges[]
    | fbPageCardQuery_facebookPage_cardData_graphData_edges[]
    | igPageCardQuery_instagramPage_cardData_graphData_edges[] = [],
  pageType: number,
  options?: {
    sliceLimit?: number;
  },
): {
  isCombinedMetric: boolean;
  metricGraphData: MetricGraphData[];
} => {
  const metricGraphData = graphData.map((graphDataEdge) => ({
    key: graphDataEdge.node.metricName,
    label:
      getPageMetricCardProperty(pageType, metricKey)?.graphLabels?.[
        graphDataEdge.node.metricName
      ] ?? '',
    icon: getPageMetricCardProperty(pageType, metricKey)?.detailDataLabels?.[
      graphDataEdge.node.metricName
    ]?.icon,
    legendLabels: graphDataEdge.node.data.edges.map(
      (edge) => edge.node.lineName,
    ),
    graphValues: {
      data: getGraphDataValues(graphDataEdge.node.data.edges, options),
    },
  }));

  const isCombinedMetric = (() => {
    if (metricGraphData.length === 0) {
      return false;
    }

    return (
      !!metricGraphData[0].graphValues.data[0] &&
      Object.hasOwn(metricGraphData[0].graphValues.data[0], 'secondValue')
    );
  })();

  return {
    metricGraphData,
    isCombinedMetric,
  };
};

const getGraphDataValues = (
  graphDataEdge:
    | liPageCardQuery_linkedInPage_cardData_graphData_edges_node_data_edges[]
    | fbPageCardQuery_facebookPage_cardData_graphData_edges_node_data_edges[]
    | igPageCardQuery_instagramPage_cardData_graphData_edges_node_data_edges[],
  options: {
    sliceLimit?: number;
  } = {},
) => {
  const firstMetric = graphDataEdge[0];
  const secondMetric = graphDataEdge[1];

  return (
    firstMetric.node.data?.edges.map((edge, index) => {
      let data: GraphData = {
        value: Number(edge.node.value),
        comparedValue: edge.node.compareValue
          ? Number(edge.node.compareValue)
          : undefined,
        date: edge.node.date,
        comparedDate: edge.node.compareDate ?? undefined,
      };
      if (!!secondMetric) {
        data = {
          ...data,
          secondValue: secondMetric.node.data.edges[index]?.node.value
            ? Number(secondMetric.node.data.edges[index].node.value)
            : undefined,
          comparedSecondValue: secondMetric.node.data.edges[index]?.node
            .compareValue
            ? Number(secondMetric.node.data.edges[index].node.compareValue)
            : undefined,
        };
      }
      return data;
    }) ?? []
  ).slice(0, options.sliceLimit);
};
