import { queryKey } from 'constants/queryKey';
import InspirationsApi from 'api/inspiration';
import { ActiveState } from 'app/modules/posts/hooks/useActiveState';
import { FilterState } from 'app/modules/posts/hooks/useFilterState';
import { PostAction } from 'modules/post/postActions';
import { useDispatch } from 'react-redux';
import { usePost } from 'modules/post/postSelector';
import PostsConfig from 'app/config/posts';
import { useQuery, useQueryClient } from 'react-query';
import { PostsApi } from '../api/posts';
import axios, { CancelTokenSource } from 'axios';
import { ApiErrorMsg } from 'constants/api';
import { ListPost } from 'types/PostDetail';
import { useEffect } from 'react';
import { MODULE_STATUS } from 'constants/modules';
import dayjs from 'dayjs';

export function usePostsQueries({
  activeState,
  filterState,
}: {
  activeState: ActiveState;
  filterState: FilterState;
}) {
  const dispatch = useDispatch();
  const { posts, status: postsStatus } = usePost();
  const queryClient = useQueryClient();

  const postsQueryVariables = {
    dateFrom: filterState.startDate,
    dateTo: filterState.endDate,
    profiles: filterState.selectedProfiles,
    profileGroups: filterState.selectedGroups,
    statuses: filterState.selectedStatuses,
    labels: filterState.selectedLabels,
    assignedUsers: filterState.selectedUserIds,
    pseudoTypes: filterState.selectedPseudoTypes,
    projects: filterState.selectedProjects,
    limit: PostsConfig.POSTS_LIMIT,
    orderDirection: filterState.orderDirection,
  };

  const isQueryEnabled =
    filterState.selectedProfiles.length > 0 ||
    filterState.selectedGroups.length > 0;

  const prefetchPostsForMonth = (date: Date | dayjs.Dayjs) => {
    const queryVariables = {
      ...postsQueryVariables,
      dateFrom: dayjs(date).startOf('month').format('YYYY-MM-DD'),
      dateTo: dayjs(date).endOf('month').format('YYYY-MM-DD'),
    };

    queryClient.prefetchQuery(
      queryKey.posts(queryVariables),
      () => PostsApi.posts(queryVariables),
      {
        cacheTime: PostsConfig.POST_QUERIES_CACHE_TIME,
        staleTime: PostsConfig.POST_QUERIES_STALE_TIME,
      },
    );
  };

  const prefetchSurroundingMonths = () => {
    const currentMonth = dayjs(filterState.startDate);
    const previousMonth = currentMonth.subtract(1, 'month');
    const nextMonth = currentMonth.add(1, 'month');

    prefetchPostsForMonth(previousMonth);
    prefetchPostsForMonth(nextMonth);
  };

  const postsQueryData = useQuery(
    queryKey.posts(postsQueryVariables),
    () => {
      dispatch(PostAction.setModuleStatus(MODULE_STATUS.Loading));

      let requestSource: CancelTokenSource | undefined;
      requestSource?.cancel(ApiErrorMsg.AbortedDueNextRequest);
      requestSource = axios.CancelToken.source();

      return PostsApi.posts(postsQueryVariables, requestSource.token);
    },
    {
      cacheTime: PostsConfig.POST_QUERIES_CACHE_TIME,
      staleTime: PostsConfig.POST_QUERIES_STALE_TIME,
      onError: () => {
        dispatch(PostAction.setModuleStatus(MODULE_STATUS.Failed));
      },
      onSuccess: (data) => {
        dispatch(PostAction.setModuleStatus(MODULE_STATUS.Succeeded));
        prefetchSurroundingMonths();
        return data;
      },
      enabled: isQueryEnabled,
    },
  );

  const inspirationsQueryVariables = {
    startDate: filterState.startDate,
    endDate: filterState.endDate,
    pages: activeState.profilesIds,
  };

  const inspirations = useQuery(
    queryKey.inspirations(inspirationsQueryVariables),
    () => InspirationsApi.get(inspirationsQueryVariables),
    {
      cacheTime: PostsConfig.POST_QUERIES_CACHE_TIME,
      staleTime: PostsConfig.POST_QUERIES_STALE_TIME,
      enabled: isQueryEnabled,
    },
  );

  const meta = useQuery(
    queryKey.postsMeta({
      profiles: filterState.selectedProfiles,
      profileGroups: filterState.selectedGroups,
      dateFrom: filterState.startDate,
      dateTo: filterState.endDate,
    }),
    () =>
      PostsApi.postsMeta({
        profiles: filterState.selectedProfiles,
        profileGroups: filterState.selectedGroups,
        dateFrom: filterState.startDate,
        dateTo: filterState.endDate,
      }),
    {
      cacheTime: PostsConfig.POST_QUERIES_CACHE_TIME,
      staleTime: PostsConfig.POST_QUERIES_STALE_TIME,
      enabled: isQueryEnabled,
    },
  );

  useEffect(() => {
    let posts: ListPost[] = [];
    let totalCount = 0;

    if (postsQueryData.data) {
      posts = postsQueryData.data.data;
      totalCount = postsQueryData.data.pagination.totalCount;
    }

    dispatch(
      PostAction.setPostData({
        posts,
        totalCount,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsQueryData.data, dispatch]);

  return {
    posts,
    meta,
    prefetchPostsForMonth,
    isPostsLoading: postsStatus === 'loading',
    inspirations: !inspirations.data ? {} : inspirations.data,
    isInspirationsLoading: inspirations.isLoading,
  };
}
