export enum DatePreset {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  TOMORROW = 'tomorrow',
  LAST_7_DAYS = 'last_7_days',
  LAST_14_DAYS = 'last_14_days',
  LAST_30_DAYS = 'last_30_days',
  THIS_WEEK = 'this_week',
  LAST_WEEK = 'last_week',
  THIS_MONTH = 'this_month',
  LAST_MONTH = 'last_month',
  NEXT_7_DAYS = 'next_7_days',
  NEXT_14_DAYS = 'next_14_days',
  NEXT_30_DAYS = 'next_30_days',
  NEXT_MONTH = 'next_month',
  NEXT_WEEK = 'next_week',
  THIS_YEAR = 'this_year',
  LAST_YEAR = 'last_year',
}
