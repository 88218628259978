import { PageDetail, Project } from 'types/Page';
import DateUtils, { formatTime } from 'app/utils/date';
import {
  PostStatus,
  PostSubtype,
  PostType,
  STATUS_COMPLETED,
  SUBTYPE as POST_SUBTYPE,
  TYPE as POST_TYPE,
} from '@kontentino/kontentino-constants/Posts';
import { PostDetail } from 'types/PostDetail';
import { Attachments } from 'types/Attachments';
import { PostLabel } from 'types/Post';
import { AudienceRestrictions } from 'app/modules/posts/types/AudienceRestrictionsOptions';
import { PostModalQueryParams } from 'app/modules/posts/modules/post/hooks/usePostModal';
import { PseudoPostType } from 'constants/post';
import { PseudoPostTypesUtils } from 'app/modules/posts/utils/pseudoPostType';
import dayjs, { Dayjs } from 'dayjs';

export type PostComposerFormState = {
  id: number | undefined;
  pseudoType: PseudoPostType;
  status: PostStatus | number;
  type: PostType;
  subtype: PostSubtype;
  date: Dayjs | null;
  time: string | null;
  text: string;
  raw_text: string;
  translationText: string;
  note: string;
  tags: PostLabel[];
  project: Pick<Project, 'id'> | null;
  budget: string;
  budgetCurrency: number;
  attachments: Attachments;
  audienceRestrictions: AudienceRestrictions | null;
} & Pick<PostDetail, 'page'>;

export function constructFormState({
  post,
  queryParams,
  page,
}:
  | {
      queryParams: PostModalQueryParams;
      page: PageDetail;
      post?: undefined;
    }
  | {
      post: PostDetail;
      page?: undefined;
      queryParams?: undefined;
    }): PostComposerFormState {
  if (post) {
    return {
      pseudoType:
        PseudoPostTypesUtils.findOptionByCombination({
          type: post.type,
          subtype: post.subtype,
          pageType: post.page.type,
          attachments: post.attachments,
        })?.key ?? PseudoPostType.Unknown,
      id: post.id,
      page: post.page,
      date: post.date ? dayjs(post.date) : null,
      time: post.time ? formatTime(post.time, 'HH:mm') : null,
      status: post.status,
      type: post.type,
      subtype: post.subtype,
      text: post.text,
      raw_text: post.raw_text,
      translationText: post.translation_text,
      tags: post.tags,
      project: post.project,
      note: post.noteWithMentions?.text ?? '',
      budget: post.budget ? String(post.budget) : '',
      budgetCurrency: post.budget_currency ?? 0, // EUR
      attachments: post.attachments,
      audienceRestrictions: post.audience_restrictions,
    };
  }

  let initialState: PostComposerFormState = {
    pseudoType: PseudoPostType.Unknown,
    id: undefined,
    page: pageToPostComposerFormPage(page),
    status: STATUS_COMPLETED,
    type: POST_TYPE.COMMENT,
    subtype: POST_SUBTYPE.DEFAULT,
    attachments: {
      cards: [],
      multi_share_end_card: false,
      publishingMethod: 'direct',
      shareToFeed: true,
    },
    date: null,
    time: null,
    text: '',
    raw_text: '',
    translationText: '',
    note: '',
    tags: [],
    project: null,
    budget: '',
    budgetCurrency: 0, // EUR
    audienceRestrictions: null,
  };

  if (queryParams) {
    if (queryParams.date && DateUtils.isValid(queryParams.date)) {
      initialState.date = queryParams.date.clone();
    }

    if (page) {
      initialState.page = pageToPostComposerFormPage(page);
      initialState.pseudoType = PseudoPostTypesUtils.getOptionsByPageType(
        initialState.page.type,
      )[0].key;
    }

    if (queryParams.text) {
      initialState.text = queryParams.text;
      initialState.raw_text = queryParams.text;
    }

    const initialPseudoType = PseudoPostTypesUtils.getOptionsByPageType(
      initialState.page.type,
    )[0];

    if (initialPseudoType) {
      initialState.pseudoType = initialPseudoType.key;
      const { type, subtype } = initialPseudoType.getTypeAndSubtype({
        attachments: initialState.attachments,
      });
      initialState.type = type;
      initialState.subtype = subtype;
    }
  }

  return initialState;
}

export function pageToPostComposerFormPage(page: PageDetail) {
  return {
    id: page.id,
    name: page.name,
    type: page.type,
    logo_src: page.logo?.src ?? null,
    timezone: page.timezone,
    is_demo: page.isDemo,
  };
}
