import { Area, Bar, LabelList, Line } from 'recharts';
import React, { FC } from 'react';
import GraphDot from './GraphDot';
import { GraphVariant } from 'app/modules/insights/types/PagePerformance';
import GraphContentHorizontalBarValueLabel from 'app/modules/insights/modules/pagePerformance/components/graph/GraphContentHorizontalBarValueLabel';

type Props = {
  variant: GraphVariant;
  dataKey: string;
  secondDataKey?: string;
  comparedDataKey?: string;
  comparedSecondDataKey?: string;
  isCompared?: boolean;
};

const primaryColor = '#505FEE';
const secondaryColor = '#99E7FC';

const GraphContent: FC<Props> = ({
  variant,
  dataKey,
  secondDataKey,
  comparedDataKey,
  comparedSecondDataKey,
  isCompared,
}) => {
  const renderLineGraphContent = () => (
    <>
      <Line
        type="linear"
        dataKey={dataKey}
        stroke={primaryColor}
        strokeWidth={3}
        dot={false}
        activeDot={<GraphDot outerFill={primaryColor} />}
        strokeLinecap={'round'}
        isAnimationActive={false}
        strokeLinejoin="round"
      />
      {secondDataKey && (
        <Line
          type="linear"
          dataKey={secondDataKey}
          stroke={secondaryColor}
          strokeWidth={3}
          dot={false}
          activeDot={<GraphDot outerFill={secondaryColor} />}
          strokeLinecap={'round'}
          isAnimationActive={false}
          strokeLinejoin="round"
        />
      )}
      {!secondDataKey && isCompared && comparedDataKey && (
        <Line
          type="linear"
          dataKey={comparedDataKey}
          stroke={secondaryColor}
          strokeWidth={3}
          dot={false}
          activeDot={<GraphDot outerFill={secondaryColor} />}
          strokeLinecap={'round'}
          isAnimationActive={false}
          strokeLinejoin="round"
        />
      )}
    </>
  );

  const renderAreaGraphContent = () => (
    <>
      <Area
        type="monotone"
        dataKey={dataKey}
        stroke={primaryColor}
        strokeWidth={3}
        fill={
          secondDataKey || (isCompared && comparedDataKey)
            ? 'transparent'
            : 'url(#colorValue)'
        }
        activeDot={<GraphDot outerFill={primaryColor} />}
        strokeLinecap={'round'}
        isAnimationActive={false}
      />
      {secondDataKey && (
        <Area
          type="monotone"
          dataKey={secondDataKey}
          stroke={secondaryColor}
          strokeWidth={3}
          fill="transparent"
          activeDot={<GraphDot outerFill={secondaryColor} />}
          strokeLinecap={'round'}
          isAnimationActive={false}
        />
      )}
      {!secondDataKey && isCompared && comparedDataKey && (
        <Area
          type="monotone"
          dataKey={comparedDataKey}
          stroke={secondaryColor}
          strokeWidth={3}
          fill="transparent"
          activeDot={<GraphDot outerFill={secondaryColor} />}
          strokeLinecap={'round'}
          isAnimationActive={false}
        />
      )}
    </>
  );

  const renderBarGraphContent = () => (
    <>
      <Bar
        dataKey={dataKey}
        barSize={16}
        isAnimationActive={false}
        fill={primaryColor}
        radius={[4, 4, 0, 0]}
      />
      {isCompared && secondDataKey && (
        <Bar
          dataKey={secondDataKey}
          fill={secondaryColor}
          radius={[4, 4, 0, 0]}
          barSize={16}
          isAnimationActive={false}
        />
      )}
      {isCompared && comparedDataKey && (
        <Bar
          dataKey={comparedDataKey}
          fill={secondaryColor}
          radius={[4, 4, 0, 0]}
          isAnimationActive={false}
          barSize={16}
        />
      )}
    </>
  );

  const renderHorizontalBarContent = () => (
    <>
      <Bar
        dataKey={dataKey}
        barSize={8}
        isAnimationActive={false}
        fill={primaryColor}
        radius={16}
        style={{
          marginTop: -10,
        }}
      >
        <LabelList
          dataKey="date"
          content={({ x, y, value }) => {
            if (typeof x === 'number' && typeof y === 'number') {
              return (
                <text
                  x={x}
                  y={Math.round(y - 8)}
                  className="tw-text-md tw-font-medium tw-text-grayscale-180"
                  textAnchor="start"
                >
                  {value}
                </text>
              );
            }

            return null;
          }}
          position="top"
        />
        {GraphContentHorizontalBarValueLabel({ dataKey })}
      </Bar>

      {isCompared && secondDataKey && (
        <Bar
          dataKey={secondDataKey}
          fill={secondaryColor}
          radius={16}
          barSize={8}
          isAnimationActive={false}
        >
          {GraphContentHorizontalBarValueLabel({})}
        </Bar>
      )}
      {isCompared && comparedDataKey && (
        <Bar
          dataKey={comparedDataKey}
          fill={secondaryColor}
          radius={16}
          isAnimationActive={false}
          barSize={8}
        >
          {GraphContentHorizontalBarValueLabel({})}
        </Bar>
      )}
    </>
  );

  switch (variant) {
    case 'line':
      return renderLineGraphContent();
    case 'area':
      return renderAreaGraphContent();
    case 'bar':
      return renderBarGraphContent();
    case 'horizontalBar':
      return renderHorizontalBarContent();
    default:
      return null;
  }
};

export default GraphContent;
