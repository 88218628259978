import React, { FC } from 'react';
import PostsSelection from 'components/workflowOptions/PostsSelection';
import { STATUSES_ALLOWED_FOR_SEND_TO_INTERNAL_APPROVAL } from '@kontentino/kontentino-constants/Posts';
import PostsSelectionContainer from 'components/workflowOptions/postsSelection/PostsSelectionContainer';
import { STEPS } from 'components/workflowOptions/sendForApproval/utils/constants';
import Footer from 'components/workflowOptions/sendForApproval/modal/Footer';
import ModalBodyWithSidebarLayout from 'components/shared/workflowOptions/ModalBodyWithSidebarLayout';
import SendForApprovalForm from 'components/workflowOptions/sendForApproval/SendForApprovalForm';
import Modal from 'components/shared/Modal';
import { ListPost } from 'types/PostDetail';

type Props = {
  type: ApprovalType;
  selectedStatuses: number[];
  onSelectStatus: (status: number) => void;
  selectedPosts: ListPost[];
  initialSelectedUserIds: number[];
  onSelectPost: (post: ListPost) => void;
  onSelectMultiplePosts: (posts: ListPost[]) => void;
  activeStep: number;
  onGoNext: () => void;
  onSelectedUsersChange: (users: number[]) => void;
  subject: string | null;
  bodyMessage: string | null;
  onSubjectChange: (value: string) => void;
  onBodyMessageChange: (value: string) => void;
  formErrors: Record<string, string>;
  onSendForApprovalSubmit: () => void;
  additionalEmails?: string;
  onAdditionalEmailsChange?: (value: string) => void;
  dataNamePrefix: string;
};

const ModalBody: FC<Props> = ({
  type,
  selectedStatuses,
  onSelectStatus,
  selectedPosts,
  initialSelectedUserIds,
  onSelectPost,
  onSelectMultiplePosts,
  activeStep,
  onGoNext,
  subject,
  bodyMessage,
  onSubjectChange,
  onBodyMessageChange,
  formErrors,
  onSendForApprovalSubmit,
  additionalEmails,
  onAdditionalEmailsChange,
  onSelectedUsersChange,
  dataNamePrefix,
}) => {
  let bodyComponent = null;

  switch (activeStep) {
    case STEPS.SELECT_POSTS.step:
      bodyComponent = (
        <PostsSelectionContainer
          allowedStatuses={STATUSES_ALLOWED_FOR_SEND_TO_INTERNAL_APPROVAL}
          selectedStatuses={selectedStatuses}
        >
          {(params) => (
            <PostsSelection
              allowedStatuses={STATUSES_ALLOWED_FOR_SEND_TO_INTERNAL_APPROVAL}
              selectedStatuses={selectedStatuses}
              onSelectPostStatus={onSelectStatus}
              posts={params.posts}
              onSelectPost={onSelectPost}
              selectedPosts={selectedPosts}
              onSelectAllPosts={() => onSelectMultiplePosts(params.posts)}
              onUnselectAllPosts={() => onSelectMultiplePosts([])}
            />
          )}
        </PostsSelectionContainer>
      );
      break;
    case STEPS.SELECT_RECIPIENTS.step:
      bodyComponent = (
        <ModalBodyWithSidebarLayout
          mainContent={
            <SendForApprovalForm
              type={type}
              pageIds={selectedPosts.map(
                (selectedPost) => selectedPost.page.id,
              )}
              onSelectedUsersChange={onSelectedUsersChange}
              initialSelectedUserIds={initialSelectedUserIds}
              formErrors={formErrors}
              subject={subject}
              bodyMessage={bodyMessage}
              additionalEmails={additionalEmails}
              onSubjectChange={onSubjectChange}
              onBodyMessageChange={onBodyMessageChange}
              onAdditionalEmailsChange={onAdditionalEmailsChange}
            />
          }
        />
      );
      break;
  }

  return (
    <>
      <Modal.Content>{bodyComponent}</Modal.Content>
      <Footer
        onGoNext={onGoNext}
        activeStep={activeStep}
        canGoNext={selectedPosts.length !== 0}
        isLoading={false} // TODO
        onSendForApproval={onSendForApprovalSubmit}
        dataNamePrefix={dataNamePrefix}
      />
    </>
  );
};

export default ModalBody;
