import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import config from 'config';
import { MediaAttachmentSize } from 'types/Attachments';
import { Post } from 'types/Post';
import { PostDetail } from 'types/PostDetail';
import { createSearchFromParams, openBlank } from 'utils/url';
type PostTypes =
  | Pick<PostDetail, 'attachments'>
  | Pick<Post, 'preview_image'>
  | undefined;

function isPostDetail(post: PostTypes): post is PostDetail {
  if (!post) return false;
  return 'attachments' in (post as PostDetail);
}

function isPost(post: PostTypes): post is Post {
  if (!post) return false;
  return 'preview_image' in (post as Post);
}

export const downloadPostAssets = (context: ActionContext) =>
  createAction(
    {
      id: 'download-post-assets',
      label: i18n.t('downloadPostAssets'),
      icon: <Icon icon={faArrowDownToLine} />,
      isApplicable: (post: PostTypes) => {
        if (isPostDetail(post)) {
          return !!post?.attachments.cards.some(({ medium }) => medium);
        }
        if (isPost(post)) {
          return !!post?.preview_image;
        }
        return false;
      },
      perform: ({
        id,
        revisionId,
        size = 'original',
      }: {
        id: PostDetail['id'];
        revisionId?: number;
        size?: MediaAttachmentSize;
      }) => {
        const searchParams = createSearchFromParams({
          jwt: context.appStore.authToken,
          revisionId,
          size,
        });

        openBlank(
          `${config.API_SERVICE_ENDPOINT}/posts/${id}/downloadAssets${searchParams}`,
        );
      },
    },
    context,
  );
