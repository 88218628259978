import { FC, useRef, useState } from 'react';
import { ApprovalDataUser } from 'types/PostWorkflow';
import { mapUserToSelectOption } from 'app/modules/posts/utils/mapUserToSelectOption';
import StringUtils from 'utils/string';
import { Field, Select, TextInput } from '@kontentino/ui';
import clsx from 'clsx';
import { openBlank } from 'utils/url';
import { useTranslation } from 'react-i18next';
import TextEditor from 'app/modules/textEditor';
import { Editor } from '@tiptap/core';
import { TextEditorFeature } from 'app/modules/textEditor/constants/textEditorFeature';
import Skeleton from 'react-loading-skeleton';
import { UserRole } from 'types/User';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';

type Props = {
  type: ApprovalType;
  dataNamePrefix?: string;
  className?: string;
  initialSelectedUserIds: number[];
  onSelectedUsersChange: (selectedUsers: number[]) => void;
  subject: string;
  bodyMessage: string;
  onSubjectChange: (value: string) => void;
  onBodyMessageChange: (value: string) => void;
  formErrors: Record<string, string>;
  availableUsers: ApprovalDataUser[];
  additionalEmails?: string;
  onAdditionalEmailsChange?: (value: string) => void;
  isLoading?: boolean;
};

type SelectOption = {
  value: number;
  name: string;
  label: JSX.Element;
  role: UserRole;
};

const Form: FC<Props> = ({
  type,
  className,
  onSelectedUsersChange,
  initialSelectedUserIds,
  subject,
  bodyMessage,
  onSubjectChange,
  onBodyMessageChange,
  formErrors,
  availableUsers,
  additionalEmails,
  onAdditionalEmailsChange,
  isLoading,
  dataNamePrefix = 'posts_send-for-approval',
}) => {
  const [isAdditionalFieldVisible, setAdditionalFieldVisible] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { accountPlanType } = useSubscriptionInfo();
  const textEditor = useRef<Editor>();
  const [userIds, setUserIds] = useState<number[]>([]);
  function getLabel() {
    return type === 'client' ? t('clients') : t('recipients');
  }

  function getSelectedUsersDefaultValue() {
    return (
      initialSelectedUserIds
        .map((userId) => availableUsers.find((user) => user.id === userId))
        .filter(Boolean) as ApprovalDataUser[]
    ).map(mapUserToSelectOption);
  }

  function handleSelectChange(
    options: SelectOption[],
    selectAction: {
      action: string;
      removedValue?: SelectOption;
    },
  ) {
    const selectedUsersIds = options.map((option) => option.value);
    setUserIds(selectedUsersIds);

    if (selectAction.action === 'clear') {
      textEditor.current?.commands.removeMentions(userIds);
    } else if (
      selectAction.action === 'remove-value' &&
      selectAction.removedValue
    ) {
      textEditor.current?.commands.removeMentions([
        selectAction.removedValue.value,
      ]);
    } else {
      options.forEach((user) => {
        if (!userIds.includes(user.value)) {
          textEditor.current?.commands.insertUniqueUserMention({
            id: user.value,
            name: user.name,
            surname: '',
            role: user.role,
          });
        }
      });
    }

    onSelectedUsersChange(selectedUsersIds);
  }

  return (
    <div className={clsx('tw-flex tw-w-full tw-flex-col tw-gap-4', className)}>
      <Field.Group>
        <Field.Label required>{getLabel()}</Field.Label>
        <div
          data-name={`${dataNamePrefix}_recipients-input`}
          data-cy="posts_send-for-approval_recipients-input"
        >
          <Select
            isMulti
            aria-invalid={!!formErrors.selectedUsers}
            closeMenuOnSelect={false}
            isDisabled={isLoading}
            defaultValue={getSelectedUsersDefaultValue()}
            options={availableUsers.map(mapUserToSelectOption)}
            filterOption={(option, input) =>
              StringUtils.isSubstring(input, option.data.name)
            }
            onChange={(options, actionMeta) => {
              handleSelectChange(options as SelectOption[], {
                action: actionMeta.action,
                removedValue: actionMeta.removedValue as SelectOption,
              });
            }}
            placeholder={`${t('select')} ${getLabel().toLowerCase()}...`}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (styles) => ({
                ...styles,
                zIndex: 1010,
              }),
            }}
          />
        </div>
        <Field.Error>{formErrors.selectedUsers}</Field.Error>
        {type === 'client' && !isAdditionalFieldVisible && (
          <Field.Caption
            cta={{
              label: t('inviteClientsByEmail'),
              onClick: () => setAdditionalFieldVisible(true),
              htmlAttributes: {
                'data-name': `${dataNamePrefix}_invite-clients-by-emails`,
              },
            }}
          />
        )}
      </Field.Group>
      {type === 'client' && isAdditionalFieldVisible && (
        <Field.Group>
          <Field.Label>{`${t('additionalClientsByMail')}:`}</Field.Label>
          <TextInput
            placeholder={`${t(
              'forExample',
            )}: mail1@example.com, mail@example.com, ...`}
            value={additionalEmails}
            onChange={(e) => onAdditionalEmailsChange?.(e.currentTarget.value)}
            disabled={isLoading}
          />
          <Field.Caption>
            {`${t('separatedByComma')}, ${t(
              'forExample',
            )}: mail1@example.com, mail2@example.com...`}
          </Field.Caption>
        </Field.Group>
      )}
      <Field.Group>
        <Field.Label required>{t('subject')}</Field.Label>
        <TextInput
          placeholder={`${t('subject')}...`}
          value={subject}
          onChange={(e) => onSubjectChange(e.currentTarget.value)}
          disabled={isLoading}
          error={!!formErrors.subject}
          data-cy="send-post-for-approval_subject-input"
        />
        <Field.Error>{formErrors.subject}</Field.Error>
        <Field.Caption
          cta={{
            label: t('customizeSubject'),
            onClick: () =>
              openBlank(`/dashboard/settings#${type}-approval-message`),
          }}
        />
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('body')}</Field.Label>
        {isLoading && <Skeleton height={120} />}

        {!isLoading && (
          <TextEditor
            classNames={{
              editor: 'tw-h-[120px]',
            }}
            accountPlanType={accountPlanType}
            dataPrefix="send-post-for-approval"
            placeholder={'Write a message...'}
            onDebouncedChange={(currentValue) =>
              onBodyMessageChange(currentValue)
            }
            initialValue={bodyMessage}
            disabled={isLoading}
            onInit={(editor) => {
              textEditor.current = editor;
            }}
            features={[TextEditorFeature.UserMention]}
            mentionableUsers={[]}
          />
        )}

        <Field.Error>{formErrors.bodyMessage}</Field.Error>
        <Field.Caption
          cta={{
            label: t('customizeMessage'),
            onClick: () =>
              openBlank(`/dashboard/settings#${type}-approval-message`),
          }}
        />
      </Field.Group>
    </div>
  );
};

export default Form;
