import { gql } from '@apollo/client';

export const CARD_DATA_FRAGMENT = gql`
  fragment CardData on PageMetricCardType {
    general {
      edges {
        node {
          name
          value
          compareValue
          percentage
        }
      }
    }
    graphData {
      edges {
        node {
          metricName
          data {
            edges {
              node {
                lineName
                data {
                  edges {
                    node {
                      date
                      value
                      compareDate
                      compareValue
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    detail {
      edges {
        node {
          name
          data {
            edges {
              node {
                metricName
                value
                compareValue
              }
            }
          }
        }
      }
    }
  }
`;
