import React, { FC } from 'react';
import KAI_HAND from 'app/modules/aiContent/assets/kai-hand.svg';
import { Icon, mergeTailwindClasses } from '@kontentino/ui';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import clsx from 'clsx';
type Props = {
  title: string;
  classNames?: {
    img?: string;
  };
};

const EmptyGeneratedResults: FC<Props> = (props) => {
  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-p-4">
      <img
        alt="Kai hand"
        src={KAI_HAND}
        className={mergeTailwindClasses(
          clsx(
            'tw-mb-8 tw-h-auto tw-w-full tw-max-w-[14.5rem]',
            props.classNames?.img,
          ),
        )}
      />
      <div className="tw-mb-2 tw-text-md tw-font-medium tw-text-grayscale-180">
        {props.title}
      </div>
      <div className="tw-mb-4 tw-max-w-[20.5rem] tw-text-center tw-text-md tw-text-grayscale-100">
        Describe your topic to our AI to start generating creative post ideas.
      </div>
      <a
        href="https://www.kontentino.com/ai-social-media-content-generator"
        target="_blank"
        rel="noopener noreferrer"
        className="tw-flex tw-items-center tw-gap-1 tw-text-md tw-text-primary-100 hover:tw-text-primary-120"
      >
        Learn more <Icon icon={faArrowUpRightFromSquare} />
      </a>
    </div>
  );
};

export default EmptyGeneratedResults;
