import {
  InsightsLiteExperimentRequestAccess,
  InsightsLiteInvitationAccess,
} from 'app/modules/insightsLite/types/insightsLite';
import { get, post } from 'api/client';
import config from 'config';
import Logger from 'utils/logger';
import { getExtendedTimeout } from 'utils/request';

export const InsightsLiteApi = {
  requestAccess: (data: {
    feedback: string;
  }): Promise<InsightsLiteExperimentRequestAccess> => {
    return post(`/api/lite/request-access`, data, {
      baseURL: config.ANALYTICS_ENDPOINT,
    });
  },

  async getInvitationAccess(): Promise<InsightsLiteInvitationAccess> {
    try {
      return await get<InsightsLiteInvitationAccess>(
        `/api/lite/invitation-access`,
        {
          baseURL: config.ANALYTICS_ENDPOINT,
          timeout: getExtendedTimeout(),
        },
      );
    } catch (error) {
      Logger.error(error, 'Error getting invitation access for insights lite.');

      return Promise.resolve({
        invitationActive: false,
        hasInsightsLite: false,
        userAccountId: 0,
        userId: 0,
      });
    }
  },
};
