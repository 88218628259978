import { FC } from 'react';
import Modal from 'components/shared/Modal';
import RequestContainer from 'app/components/timeSavers/assignMultipleTasksModal/RequestContainer';
import AssignContainer, {
  PreSelectedValues,
} from 'app/components/timeSavers/assignMultipleTasksModal/Container';
import Form from 'app/components/timeSavers/assignMultipleTasksModal/assignForm/Form';
import { ModalManager } from 'context/ModalRouter';
import { Button, Icon } from '@kontentino/ui';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
type Props = {
  onSuccess?: () => void;
  modalManager: ModalManager;
  initialValues?: PreSelectedValues;
};

const AssignTasksPreselectedPosts: FC<Props> = ({
  onSuccess,
  modalManager,
  initialValues,
}) => (
  <Modal size="md" modalManager={modalManager}>
    <Modal.Header title="Assign task" />
    <RequestContainer successCallback={onSuccess}>
      {(requestParams) => (
        <AssignContainer
          onSubmit={requestParams.submitAssignTasks}
          preselectedValues={initialValues}
        >
          {(params) => (
            <>
              <Modal.Content>
                <Form
                  pagesIds={params.selectedPagesIds}
                  taskMessage={params.formState.taskMessage}
                  isUrgent={params.formState.isUrgent}
                  canSendEmail={params.formState.canSendEmailNotification}
                  onSelectedUsersChange={params.handleChangeSelectedUsers}
                  initialSelectedUsersIds={params.formState.selectedUsers}
                  onTaskMessageChange={params.changeMessage}
                  onSelectUrgent={params.selectUrgent}
                  onSelectEmailNotification={params.selectEmailNotification}
                  formErrors={params.formErrors}
                />
              </Modal.Content>
              <Modal.Footer>
                <Button
                  onClick={params.assignTasks}
                  iconBefore={<Icon icon={faUserPlus} />}
                  isLoading={requestParams.isLoading}
                  data-cy="assign-post-task-form-submit-button"
                >
                  Assign task
                </Button>
              </Modal.Footer>
            </>
          )}
        </AssignContainer>
      )}
    </RequestContainer>
  </Modal>
);

export default AssignTasksPreselectedPosts;
