import dayjs, { Dayjs } from 'dayjs';
import { DateRangeOnChangeParams } from 'types/Date';
import { DatePreset } from '../enums';

export const isDateWithinRange = (
  date: Dayjs,
  startDate: Dayjs | null = null,
  endDate: Dayjs | null = null,
) => {
  if (startDate && endDate) {
    return date.isBetween(startDate, endDate, 'day', '[]');
  }

  if (startDate && !endDate) {
    return dayjs(date).isAfter(startDate) || dayjs(date).isSame(startDate);
  }

  if (!startDate && endDate) {
    return date.isBefore(endDate) || date.isSame(endDate);
  }

  return true;
};

export function getDatesWithConstraints(
  dates: DateRangeOnChangeParams,
  options: { maxEndDate?: Dayjs | null },
) {
  return Object.fromEntries(
    Object.entries(dates).map(([key, value]) => {
      if (
        value &&
        options?.maxEndDate &&
        value.isAfter(options.maxEndDate, 'day')
      ) {
        value = options.maxEndDate;
      }

      return [key, value];
    }),
  ) as DateRangeOnChangeParams;
}

export const getDatesFromPreset = (
  preset: string | null,
): DateRangeOnChangeParams => {
  let startDate, endDate;

  switch (preset) {
    case DatePreset.TODAY:
      startDate = dayjs();
      endDate = dayjs();
      break;
    case DatePreset.YESTERDAY:
      startDate = dayjs().subtract(1, 'days');
      endDate = dayjs().subtract(1, 'days');
      break;
    case DatePreset.TOMORROW:
      startDate = dayjs().add(1, 'days');
      endDate = dayjs().add(1, 'days');
      break;
    case DatePreset.LAST_7_DAYS:
      startDate = dayjs().subtract(7, 'days');
      endDate = dayjs();
      break;
    case DatePreset.LAST_14_DAYS:
      startDate = dayjs().subtract(14, 'days');
      endDate = dayjs();
      break;
    case DatePreset.LAST_30_DAYS:
      startDate = dayjs().subtract(30, 'days');
      endDate = dayjs();
      break;
    case DatePreset.LAST_WEEK:
      startDate = dayjs().startOf('week').subtract(7, 'days');
      endDate = dayjs().endOf('week').subtract(7, 'days');
      break;
    case DatePreset.THIS_WEEK:
      startDate = dayjs().startOf('week');
      endDate = dayjs().endOf('week');
      break;
    case DatePreset.THIS_MONTH:
      startDate = dayjs().startOf('month');
      endDate = dayjs().endOf('month');
      break;
    case DatePreset.LAST_MONTH:
      startDate = dayjs().subtract(1, 'months').startOf('month');
      endDate = dayjs().subtract(1, 'months').endOf('month');
      break;
    case DatePreset.NEXT_7_DAYS:
      startDate = dayjs().add(1, 'days');
      endDate = dayjs().add(7, 'days');
      break;
    case DatePreset.NEXT_14_DAYS:
      startDate = dayjs().add(1, 'days');
      endDate = dayjs().add(14, 'days');
      break;
    case DatePreset.NEXT_30_DAYS:
      startDate = dayjs().add(1, 'days');
      endDate = dayjs().add(30, 'days');
      break;
    case DatePreset.NEXT_WEEK:
      startDate = dayjs().startOf('week').add(7, 'days');
      endDate = dayjs().endOf('week').add(7, 'days');
      break;
    case DatePreset.NEXT_MONTH:
      startDate = dayjs().add(1, 'months').startOf('month');
      endDate = dayjs().add(1, 'months').endOf('month');
      break;
    case DatePreset.THIS_YEAR:
      startDate = dayjs().startOf('year');
      endDate = dayjs().endOf('year');
      break;
    case DatePreset.LAST_YEAR:
      startDate = dayjs().subtract(1, 'year').startOf('year');
      endDate = dayjs().subtract(1, 'year').endOf('year');
      break;
    default:
      startDate = null;
      endDate = null;
      break;
  }

  return { startDate, endDate };
};

type Options = { maxEndDate?: Dayjs | null };

export function getDatesAutoSelectPeriodWithConstrains(
  dates: DateRangeOnChangeParams,
  autoSelectPeriod: number,
  options: Options,
): DateRangeOnChangeParams {
  const newDates = { ...dates };

  if (newDates.startDate && options.maxEndDate) {
    const possibleEndDate = newDates.startDate
      .clone()
      .add(autoSelectPeriod, 'day');

    if (possibleEndDate.isAfter(options.maxEndDate, 'day')) {
      newDates.endDate = options.maxEndDate.clone();
      newDates.startDate = newDates.endDate
        .clone()
        .subtract(autoSelectPeriod, 'day');
    } else {
      newDates.endDate = possibleEndDate;
    }

    return newDates;
  }

  return {
    startDate: null,
    endDate: null,
  };
}

export const getPresetFromDates = (
  startDate: Dayjs,
  endDate: Dayjs,
): string | undefined => {
  if (endDate.isSame(dayjs(), 'day')) {
    if (startDate.isSame(dayjs().subtract(7, 'days'), 'day')) {
      return DatePreset.LAST_7_DAYS;
    }

    if (startDate.isSame(dayjs().subtract(14, 'days'), 'day')) {
      return DatePreset.LAST_14_DAYS;
    }

    if (startDate.isSame(dayjs().subtract(30, 'days'), 'day')) {
      return DatePreset.LAST_30_DAYS;
    }

    if (startDate.isSame(dayjs().startOf('month'), 'day')) {
      return DatePreset.THIS_MONTH;
    }

    if (startDate.isSame(dayjs().startOf('week'), 'day')) {
      return DatePreset.THIS_WEEK;
    }

    if (startDate.isSame(dayjs().startOf('year'), 'day')) {
      return DatePreset.THIS_YEAR;
    }
  }

  if (startDate.isSame(dayjs(), 'day') && endDate.isSame(dayjs(), 'day')) {
    return DatePreset.TODAY;
  }

  if (startDate.isSame(dayjs().add(1, 'days'), 'day')) {
    if (endDate.isSame(dayjs().add(7, 'days'), 'day')) {
      return DatePreset.NEXT_7_DAYS;
    }
    if (endDate.isSame(dayjs().add(14, 'days'), 'day')) {
      return DatePreset.NEXT_14_DAYS;
    }
    if (endDate.isSame(dayjs().add(30, 'days'), 'day')) {
      return DatePreset.NEXT_30_DAYS;
    }
  }

  if (
    startDate.isSame(dayjs().subtract(1, 'days'), 'day') &&
    endDate.isSame(dayjs().subtract(1, 'days'), 'day')
  ) {
    return DatePreset.YESTERDAY;
  }

  if (
    startDate.isSame(dayjs().add(1, 'days'), 'day') &&
    endDate.isSame(dayjs().add(1, 'days'), 'day')
  ) {
    return DatePreset.TOMORROW;
  }

  if (
    startDate.isSame(dayjs().startOf('week').add(7, 'days'), 'day') &&
    endDate.isSame(dayjs().endOf('week').add(7, 'days'), 'day')
  ) {
    return DatePreset.NEXT_WEEK;
  }

  if (
    startDate.isSame(dayjs().startOf('week').subtract(7, 'days'), 'day') &&
    endDate.isSame(dayjs().endOf('week').subtract(7, 'days'), 'day')
  ) {
    return DatePreset.LAST_WEEK;
  }

  if (
    startDate.isSame(dayjs().add(1, 'months').startOf('month'), 'day') &&
    endDate.isSame(dayjs().add(1, 'months').endOf('month'), 'day')
  ) {
    return DatePreset.NEXT_MONTH;
  }

  if (
    startDate.isSame(dayjs().subtract(1, 'months').startOf('month'), 'day') &&
    endDate.isSame(dayjs().subtract(1, 'months').endOf('month'), 'day')
  ) {
    return DatePreset.LAST_MONTH;
  }

  if (
    startDate.isSame(dayjs().subtract(1, 'year').startOf('year'), 'day') &&
    endDate.isSame(dayjs().subtract(1, 'year').endOf('year'), 'day')
  ) {
    return DatePreset.LAST_YEAR;
  }

  return undefined;
};
