import { DatePreset } from '../enums';

export const DATE_PRESET_LABELS: Record<DatePreset, string> = {
  [DatePreset.TODAY]: 'Today',
  [DatePreset.YESTERDAY]: 'Yesterday',
  [DatePreset.THIS_WEEK]: 'This week',
  [DatePreset.LAST_WEEK]: 'Last week',
  [DatePreset.THIS_MONTH]: 'This month',
  [DatePreset.LAST_MONTH]: 'Last month',
  [DatePreset.THIS_YEAR]: 'This year',
  [DatePreset.LAST_YEAR]: 'Last year',
  [DatePreset.TOMORROW]: 'Tomorrow',
  [DatePreset.NEXT_7_DAYS]: 'Next 7 days',
  [DatePreset.NEXT_14_DAYS]: 'Next 14 days',
  [DatePreset.NEXT_30_DAYS]: 'Next 30 days',
  [DatePreset.NEXT_WEEK]: 'Next week',
  [DatePreset.NEXT_MONTH]: 'Next month',
  [DatePreset.LAST_7_DAYS]: 'Last 7 days',
  [DatePreset.LAST_14_DAYS]: 'Last 14 days',
  [DatePreset.LAST_30_DAYS]: 'Last 30 days',
};
