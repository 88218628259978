import { TYPE } from '@kontentino/kontentino-constants/Pages';
import {
  FB_METRICS_PROPERTIES,
  IG_METRICS_PROPERTIES,
  LI_METRICS_PROPERTIES,
} from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';

import {
  FB_METRICS_PROPERTIES as FB_PAGE_METRICS_PROPERTIES,
  IG_METRICS_PROPERTIES as IG_PAGE_METRICS_PROPERTIES,
  LI_METRICS_PROPERTIES as LI_PAGE_METRICS_PROPERTIES,
} from 'app/modules/insights/modules/pagePerformance/utils/metricProperty';

export const PAGE_PERFORMANCE_PDF_EXPORT_POSTS_METRICS = {
  [TYPE.FACEBOOK]: [
    FB_METRICS_PROPERTIES.totalReachUnique.key,
    FB_METRICS_PROPERTIES.postImpressions.key,
    FB_METRICS_PROPERTIES.engagement.key,
  ],
  [TYPE.INSTAGRAM]: [
    IG_METRICS_PROPERTIES.reach.key,
    IG_METRICS_PROPERTIES.impressions.key,
    IG_METRICS_PROPERTIES.engagement.key,
  ],
  [TYPE.LINKEDIN]: [
    LI_METRICS_PROPERTIES.postImpressions.key,
    LI_METRICS_PROPERTIES.engagement.key,
  ],
} as { [key: number]: string[] };

export const PAGE_PERFORMANCE_PDF_EXPORT_CHARTS_METRICS = {
  [TYPE.FACEBOOK]: [
    FB_PAGE_METRICS_PROPERTIES.audience.key,
    FB_PAGE_METRICS_PROPERTIES.engagement.key,
    FB_PAGE_METRICS_PROPERTIES.page_impressions.key,
    FB_PAGE_METRICS_PROPERTIES.page_consumption.key,
    FB_PAGE_METRICS_PROPERTIES.viral_vs_nonviral.key,
  ],
  [TYPE.INSTAGRAM]: [
    IG_PAGE_METRICS_PROPERTIES.reachedAudience.key,
    IG_PAGE_METRICS_PROPERTIES.engagedAudience.key,
    IG_PAGE_METRICS_PROPERTIES.followerDemographics.key,
    IG_PAGE_METRICS_PROPERTIES.engagement.key,
  ],
  [TYPE.LINKEDIN]: [
    LI_PAGE_METRICS_PROPERTIES.followers.key,
    LI_PAGE_METRICS_PROPERTIES.followerGrowth.key,
    LI_PAGE_METRICS_PROPERTIES.impressions.key,
    LI_PAGE_METRICS_PROPERTIES.engagement.key,
  ],
} as { [key: number]: string[] };

export const PAGE_PERFORMANCE_CURRENT_SELECTION_PDF_METRICS = {
  [TYPE.FACEBOOK]: [
    FB_PAGE_METRICS_PROPERTIES.audience.key,
    FB_PAGE_METRICS_PROPERTIES.engagement.key,
    FB_PAGE_METRICS_PROPERTIES.engaged_users.key,
    FB_PAGE_METRICS_PROPERTIES.post_engagement.key,
    FB_PAGE_METRICS_PROPERTIES.unique_page_consumption.key,
    FB_PAGE_METRICS_PROPERTIES.page_consumption.key,
    FB_PAGE_METRICS_PROPERTIES.page_impressions.key,
    FB_PAGE_METRICS_PROPERTIES.page_unique_impressions.key,
    FB_PAGE_METRICS_PROPERTIES.viral_frequency_distribution.key,
    FB_PAGE_METRICS_PROPERTIES.paid_vs_organic_unique.key,
    FB_PAGE_METRICS_PROPERTIES.viral_vs_nonviral.key,
    FB_PAGE_METRICS_PROPERTIES.viral_vs_nonviral_unique.key,
    FB_PAGE_METRICS_PROPERTIES.paid_vs_organic.key,
  ],
  [TYPE.INSTAGRAM]: [
    IG_PAGE_METRICS_PROPERTIES.reachedAudience.key,
    IG_PAGE_METRICS_PROPERTIES.followerDemographics.key,
    IG_PAGE_METRICS_PROPERTIES.reach.key,
    IG_PAGE_METRICS_PROPERTIES.engagement.key,
    IG_PAGE_METRICS_PROPERTIES.impressions.key,
    IG_PAGE_METRICS_PROPERTIES.clicks.key,
  ],
  [TYPE.LINKEDIN]: [
    LI_PAGE_METRICS_PROPERTIES.followers.key,
    LI_PAGE_METRICS_PROPERTIES.followerGrowth.key,
    LI_PAGE_METRICS_PROPERTIES.impressions.key,
    LI_PAGE_METRICS_PROPERTIES.engagement.key,
  ],
} as { [key: number]: string[] };
