import React, { FC, ReactNode } from 'react';
import { Icon } from '@kontentino/ui';
import { faArrowDownLeftAndArrowUpRightToCenter } from '@fortawesome/pro-regular-svg-icons/faArrowDownLeftAndArrowUpRightToCenter';
import { faArrowUpRightAndArrowDownLeftFromCenter } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightAndArrowDownLeftFromCenter';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { useTranslation } from 'react-i18next';
type Props = {
  metric: MetricProperty;
  children: ReactNode;
  onToggleExpandClick?(): void;
  expanded?: boolean;
  dataName?: {
    card?: string;
    expandButton?: string;
  };
};

const PageMetricCardContent: FC<Props> = ({
  metric,
  children,
  onToggleExpandClick,
  expanded,
  dataName,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="tw-group/pageMetricCard tw-flex tw-h-full tw-min-h-[282px] tw-w-full tw-flex-col tw-rounded-lg tw-border tw-border-grayscale-20 tw-bg-white tw-p-4 hover:tw-shadow-md hover:tw-transition-shadow hover:tw-duration-[0.3s] hover:tw-ease-in-out"
      data-name={dataName?.card}
    >
      <div className="tw-flex tw-items-center">
        <div className="tw-text-base tw-font-semibold">{t(metric.title)}</div>
        {onToggleExpandClick && (
          <Icon
            icon={
              expanded
                ? faArrowDownLeftAndArrowUpRightToCenter
                : faArrowUpRightAndArrowDownLeftFromCenter
            }
            onClick={onToggleExpandClick}
            className="tw-none tw-ml-auto tw-hidden tw-h-[30px] tw-w-[30px] tw-cursor-pointer tw-text-grayscale-100 hover:tw-text-primary-100 group-hover/pageMetricCard:tw-flex"
            data-name={dataName?.expandButton}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default PageMetricCardContent;
