import { FC } from 'react';
import Header from 'components/in-app-notifications/history/Header';
import List from 'components/in-app-notifications/history/List';
import InAppNotificationsRequest from 'components/in-app-notifications/InAppNotificationsRequest';
import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';

type Props = {
  className?: string;
};

const History: FC<Props> = ({ className }) => {
  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-flex tw-h-full tw-flex-col tw-overflow-hidden tw-rounded tw-bg-white tw-p-0 tw-shadow-md tw-outline-none tw-transition-all tw-duration-150 tw-ease-out',
          className,
        ),
      )}
    >
      <Header />
      <InAppNotificationsRequest>
        {(params) => (
          <List
            isLoading={params.isLoading}
            notifications={params.data}
            onLoadMore={params.loadMore}
            isLoadingMore={params.isFetching}
            canLoadMore={params.canLoadMore}
          />
        )}
      </InAppNotificationsRequest>
    </div>
  );
};

export default History;
