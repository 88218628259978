import React, { FC } from 'react';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@kontentino/ui';
import { DATE_PRESET_LABELS } from './constants/presets';
import { DatePreset } from './enums';

type Props = {
  presets: DatePreset[];
  selectedPreset?: string;
  onPresetSelect: (preset: string) => void;
};

const DatePickerPresets: FC<Props> = ({
  presets,
  selectedPreset,
  onPresetSelect,
}) => {
  return (
    <div
      className="tw-grid tw-w-[272px] tw-grid-cols-2 tw-gap-2 tw-p-3"
      data-cy="datepicker-presets"
    >
      {presets?.map((preset) => (
        <Button
          key={preset}
          size={'small'}
          variant={selectedPreset === preset ? 'primary' : 'ghost'}
          onClick={() => onPresetSelect(preset)}
          className={clsx(
            'tw-flex tw-h-full tw-items-center tw-justify-center !tw-p-2',
            {
              'tw-font-normal': selectedPreset !== preset,
            },
          )}
        >
          {DATE_PRESET_LABELS[preset]}
        </Button>
      ))}
    </div>
  );
};

export default DatePickerPresets;
