import { FC, ReactNode } from 'react';
import style from './modalBodyWithSidebarLayout/ModalBodyWithSidebarLayout.module.scss';
import clsx from 'clsx';

type Props = {
  sidebarContent?: ReactNode;
  mainContent?: ReactNode;
  className?: string | null;
  sidebarClassName?: string | null;
  contentClassName?: string | null;
};

const ModalBodyWithSidebarLayout: FC<Props> = ({
  sidebarContent,
  mainContent,
  className,
  sidebarClassName,
  contentClassName,
}) => {
  return (
    <div className={clsx(style.wrapper, className)}>
      <div className={clsx(style.sidebar, sidebarClassName)}>
        {sidebarContent}
      </div>
      <div className={clsx(style.content, contentClassName)}>{mainContent}</div>
    </div>
  );
};

export default ModalBodyWithSidebarLayout;
