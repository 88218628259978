import { useState } from 'react';
import { DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import AdsPlannerFiltersObjectivesSubmenu from 'app/modules/adsPlanner/components/adsPlannerFiltersDropdown/AdsPlannerFiltersObjectivesSubmenu';
import AdsPlannerFiltersLabelsSubmenu from 'app/modules/adsPlanner/components/adsPlannerFiltersDropdown/AdsPlannerFiltersLabelsSubmenu';
import AdsPlannerFiltersEffectiveStatusSubmenu from 'app/modules/adsPlanner/components/adsPlannerFiltersDropdown/AdsPlannerFiltersEffectiveStatusSubmenu';
import AdsPlannerFiltersStatusSubmenu from 'app/modules/adsPlanner/components/adsPlannerFiltersDropdown/AdsPlannerFiltersStatusSubmenu';
const AdsPlannerFiltersDropdown = () => {
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  return (
    <DropdownMenu.Root
      open={isMainMenuOpen}
      onOpenChange={(open) => {
        if (!open) return;
        setIsMainMenuOpen(true);
      }}
    >
      <DropdownMenu.Trigger>
        <IconButton
          className="tw-h-[36px]"
          variant="secondary"
          icon={<Icon icon={faFilter} />}
          data-name="adsplanner-filters"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="tw-z-10"
        onInteractOutside={() => setIsMainMenuOpen(false)}
        onEscapeKeyDown={() => setIsMainMenuOpen(false)}
      >
        <AdsPlannerFiltersStatusSubmenu />
        <AdsPlannerFiltersEffectiveStatusSubmenu />
        <AdsPlannerFiltersLabelsSubmenu />
        <AdsPlannerFiltersObjectivesSubmenu />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default AdsPlannerFiltersDropdown;
