import { formatDateUTC, formatTimeUTC } from 'app/utils/date';
import i18n from 'app/utils/i18n';
import dayjs from 'dayjs';

export const getDateTimeLabel = (date: string | null, time: string | null) => {
  let label = '';

  if (date) {
    label += dayjs(date).isToday() ? i18n.t('today') : formatDateUTC(date);

    if (time) {
      label += ', ';
    }
  }

  if (time) {
    label += formatTimeUTC(time);
  }

  return label;
};
