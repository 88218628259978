import React, { FC } from 'react';
import { Page } from 'app/modules/insights/types/Page';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';
import DateUtils, { formatDate } from 'app/utils/date';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { Dayjs } from 'dayjs';
import { BrandLogo, Icon } from '@kontentino/ui';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
type Props = {
  title?: string;
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric?: MetricProperty;
  className?: string;
};

const ReportTitle: FC<Props> = ({
  title,
  metric,
  startDate,
  endDate,
  page,
  className,
}) => {
  return (
    <div className={className}>
      {title && <div className="tw-text-2xl">{title}</div>}
      <div className="tw-flex tw-gap-8">
        <div className="tw-flex tw-items-center">
          <BrandLogo
            size={28}
            socialMediaType={getSocialMediaTypeForBrandLogo(page._type)}
            src={page.picture ?? ''}
          />
          <div className="tw-ml-2 tw-text-md">
            <span>{page.name}</span>
          </div>
        </div>
        <div className="tw-flex tw-items-center">
          <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-sm tw-bg-grayscale-5">
            <Icon icon={faCalendarDays} className="tw-text-grayscale-100" />
          </div>
          <div className="tw-ml-2 tw-text-md">
            <span>{`${formatDate(
              DateUtils.toDateString(startDate),
            )} - ${formatDate(DateUtils.toDateString(endDate))}`}</span>
          </div>
        </div>
        {metric && (
          <div className="tw-flex tw-items-center">
            <div className="tw-ml-4">
              <span>{metric.title}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportTitle;
