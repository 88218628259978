import {
  COMBINED_MEDIA_DOWNLOAD_SIZES,
  VIDEO_MEDIA_DOWNLOAD_SIZES,
} from 'app/modules/posts/constants';
import { PostDetail } from 'types/PostDetail';
import { isVideoAttachmentCard } from 'utils/attachments';

export const getMediaDownloadSizes = (post: PostDetail) => {
  const hasVideoOnly = post.attachments.cards.every(isVideoAttachmentCard);

  return hasVideoOnly
    ? VIDEO_MEDIA_DOWNLOAD_SIZES
    : COMBINED_MEDIA_DOWNLOAD_SIZES;
};
