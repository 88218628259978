import { GetPostsParams, PostsApi } from 'app/modules/posts/api/posts';
import { MODULE_STATUS } from 'constants/modules';
import { createAction } from 'modules/helpers';
import { ModuleStatus } from 'types/ModuleStatus';
import { Dispatch, Store } from 'types/Store';
import Logger from 'utils/logger';
import axios, { CancelTokenSource } from 'axios';
import { ApiErrorMsg } from 'constants/api';
import { PostStatus } from '@kontentino/kontentino-constants/Posts';
import { queryClient } from 'api/client';
import { queryKey } from 'constants/queryKey';
import UserpilotUtils from 'app/utils/userpilot';
import { showToast } from 'app/hooks/useToast';
import { ListPost, PostDetail } from 'types/PostDetail';
import PostApi from 'api/post';
import DateUtils, { formatRawDate } from 'app/utils/date';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { Post } from 'types/Post';

export const PostActionName = {
  SET_POSTS_DATA: 'PostAction-SET_POSTS_DATA',
  SET_MODULE_STATUS: 'PostAction-SET_MODULE_STATUS',
  EDIT_POST_STATUS: 'PostAction-EDIT_POST_STATUS',
  EDIT_POST_DATE: 'PostAction-EDIT_POST_DATE',
  APPEND_POST: 'PostAction-APPEND_POST',
  REMOVE_TEMPORARY_POSTS: 'PostAction-REMOVE_TEMPORARY_POSTS',
  UPDATE_POST: 'PostAction-UPDATE_POST',
  SWAP_POSTS_INDEX: 'PostAction-SWAP_POSTS_INDEX',
  SAVE_POST_REQUEST_PARAMS: 'PostAction-SAVE_POST_REQUEST_PARAMS',
};

export const PostAction = {
  setPostData: createAction<{ posts: ListPost[]; totalCount: number }>(
    PostActionName.SET_POSTS_DATA,
  ),
  updatePost: createAction<{ postId: number; post: Partial<PostDetail> }>(
    PostActionName.UPDATE_POST,
  ),
  setModuleStatus: createAction<ModuleStatus>(PostActionName.SET_MODULE_STATUS),
  editPostStatus: createAction<{ postId: number; status: PostStatus }>(
    PostActionName.EDIT_POST_STATUS,
  ),
  editPostDate: createAction<{ id: number; date: Dayjs | null }>(
    PostActionName.EDIT_POST_DATE,
  ),
  appendPost: createAction<PostDetail>(PostActionName.APPEND_POST),
  swapPostsIndex: createAction<{ from: number; to: number }>(
    PostActionName.SWAP_POSTS_INDEX,
  ),
  removeTemporaryPosts: createAction(PostActionName.REMOVE_TEMPORARY_POSTS),
};

let requestSource: CancelTokenSource | undefined;

export const PostThunk = {
  requestPosts:
    (params: GetPostsParams) =>
    async (dispatch: Dispatch, getState: () => Store) => {
      dispatch(PostAction.setModuleStatus(MODULE_STATUS.Loading));

      requestSource?.cancel(ApiErrorMsg.AbortedDueNextRequest);
      requestSource = axios.CancelToken.source();

      try {
        let posts: ListPost[] = [];
        let totalCount = 0;

        if (params.profiles.length > 0 || params.profileGroups.length > 0) {
          const postsResponse = await PostsApi.posts(
            params,
            requestSource.token,
          );

          if (postsResponse.status) {
            posts = postsResponse.data;
            totalCount = postsResponse.pagination.totalCount;
          }
        }

        dispatch(PostAction.setPostData({ posts, totalCount }));
        dispatch(PostAction.setModuleStatus(MODULE_STATUS.Succeeded));
      } catch (e: any) {
        if (e?.message !== ApiErrorMsg.AbortedDueNextRequest) {
          Logger.error(e);
          dispatch(PostAction.setModuleStatus(MODULE_STATUS.Failed));
        }
      }

      requestSource = undefined;
    },
  editPostStatus:
    (postId: number, status: PostStatus) =>
    async (dispatch: Dispatch, getState: () => Store) => {
      const state = getState();
      const originalPost = state.post.posts.find((post) => post.id === postId);

      try {
        dispatch(PostAction.editPostStatus({ postId, status }));

        await PostApi.editPostStatus({ id: postId, status });
      } catch (e: any) {
        Logger.error(e);

        showToast(e?.userMessage ?? t('unableToChangePostStatus'), 'warning');

        if (originalPost) {
          dispatch(
            PostAction.editPostStatus({ postId, status: originalPost.status }),
          );
        }
      }
    },
  editPostDate:
    (id: number, date: Dayjs | null) =>
    async (dispatch: Dispatch, getState: () => Store) => {
      const originalPost = getState().post.posts.find((post) => post.id === id);

      try {
        dispatch(PostAction.editPostDate({ id, date }));

        if (date) {
          await PostApi.editPostDate(id, DateUtils.toDateString(date));
        } else {
          await PostApi.clearPostDate(id);
        }
      } catch (e: any) {
        showToast(
          e?.userMessage ??
            "We were unable to complete changing post date. Post was moved to it's original date",
          'warning',
        );

        if (originalPost) {
          const { date } = originalPost;

          dispatch(
            PostAction.editPostDate({
              id,
              date: date === null ? null : dayjs(date),
            }),
          );
        }
        Logger.error(e);
      }
    },
  copyPost:
    (oldPostId: number, tempPostId: number, newDate: Dayjs | null) =>
    async (dispatch: Dispatch, getState: () => Store) => {
      try {
        const originalPost = getState().post.posts.find(
          (post) => post.id === oldPostId,
        );

        if (!originalPost) {
          throw new Error();
        }

        dispatch(
          PostAction.appendPost({
            ...originalPost,
            id: tempPostId,
            date: !!newDate ? DateUtils.toDateString(newDate) : null,
          }),
        );

        let response: Post;

        if (newDate) {
          response = await PostApi.copyPostToDate(
            originalPost.id,
            DateUtils.toDateString(newDate),
          );
        } else {
          response = await PostApi.copyPostToDate(
            originalPost.id,
            formatRawDate(dayjs()),
          );
          response = await PostApi.clearPostDate(response.id);
        }

        dispatch(
          PostAction.updatePost({
            postId: tempPostId,
            post: { id: response.id },
          }),
        );

        UserpilotUtils.track(UserpilotUtils.events.POSTS_POST_DUPLICATED);

        if (!response) {
          throw new Error();
        }
      } catch (e) {
        showToast(`We were unable to complete copying post`, 'warning');
        dispatch(PostAction.removeTemporaryPosts());
        Logger.error(e);
      }
    },
  swapPostDates:
    (sourceId: number, targetId: number) =>
    async (dispatch: Dispatch, getState: () => Store) => {
      const posts = getState().post.posts;

      const sourceIndex = posts.findIndex((post) => post.id === sourceId);
      const targetIndex = posts.findIndex((post) => post.id === targetId);

      const source = posts[sourceIndex];
      const target = posts[targetIndex];

      if (source?.date && target?.date) {
        dispatch(
          PostAction.updatePost({
            postId: source.id,
            post: { date: target.date },
          }),
        );

        dispatch(
          PostAction.updatePost({
            postId: target.id,
            post: { date: source.date },
          }),
        );

        dispatch(
          PostAction.swapPostsIndex({ from: sourceIndex, to: targetIndex }),
        );

        await Promise.all([
          PostApi.editPostDate(source.id, target.date),
          PostApi.editPostDate(target.id, source.date),
        ]);

        queryClient.invalidateQueries(queryKey.posts());
      }
    },
};
