import React from 'react';
import { ApprovalDataUser } from 'types/PostWorkflow';
import DemoBadge from 'app/components/DemoBadge';
import { AssignedUser } from 'components/page/PageUsersContainers';

export function mapUserToSelectOption(user: ApprovalDataUser | AssignedUser) {
  return {
    role: user.role,
    value: user.id,
    name: user.name,
    label: (
      <span>
        {user.name}{' '}
        {user.is_demo && (
          <span className="tw-ml-1">
            <DemoBadge />
          </span>
        )}
      </span>
    ),
  };
}
