import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import InsightsPostsPerformancePDFReportRoute from 'app/modules/public/modules/pdfReports/modules/insightsPostsPerformance';
import routeConfig from 'app/config/routes';
import { colors } from '@kontentino/ui';
import InsightsCompetitionAnalysisPDFReportRoute from 'app/modules/public/modules/pdfReports/modules/InsightsCompetitionAnalysis';
import { publicPagePerformancePDFReportsRouter } from 'app/modules/public/modules/pdfReports/modules/insightsPagePerformance';

export const publicPDFReportsRouter: RouteObject = {
  path: routeConfig.public.routes.pdfReports.path,
  element: (
    <>
      <style>
        {`
          body {
            background-color: ${colors.white};
          }        
          @page {
            size: auto;
            margin: 1.3cm;
          }
        `}
      </style>
      <Outlet />
    </>
  ),
  children: [
    {
      index: true,
      element: (
        <Navigate
          to={
            routeConfig.public.routes.pdfReports.routes.insightsPostsPerformance
              .path
          }
        />
      ),
    },
    {
      path: routeConfig.public.routes.pdfReports.routes.insightsPostsPerformance
        .path,
      element: <InsightsPostsPerformancePDFReportRoute />,
    },
    {
      path: routeConfig.public.routes.pdfReports.routes
        .insightsCompetitionAnalysis.path,
      element: <InsightsCompetitionAnalysisPDFReportRoute />,
    },
    publicPagePerformancePDFReportsRouter,
  ],
};
