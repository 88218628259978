import { useQuery } from '@apollo/client';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { liPageCardQuery } from 'graphql/insights/queries/__generated__/liPageCardQuery';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { Page } from 'app/modules/insights/types/Page';
import { mapGraphqlPageCardQuery } from '../utils/mapGraphqlPageCardQuery';
import { useMemo } from 'react';
import { igPageCardQuery } from 'graphql/insights/queries/__generated__/igPageCardQuery';
import { LI_PAGE_CARD_QUERY } from 'graphql/insights/queries/liPageCardQuery';
import { IG_PAGE_CARD_QUERY } from 'graphql/insights/queries/igPageCardQuery';
import { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';
import { FB_PAGE_CARD_QUERY } from 'graphql/insights/queries/fbPageCardQuery';
import { fbPageCardQuery } from 'graphql/insights/queries/__generated__/fbPageCardQuery';
import { MetricCardData } from 'app/modules/insights/types/PagePerformance';

type Props = {
  page: Page;
  date: {
    start: Dayjs;
    end: Dayjs;
  };
  comparedDate: {
    start: Dayjs | null;
    end: Dayjs | null;
  };
  metric: MetricProperty;
};

export const usePageCardQuery = ({
  page,
  date,
  comparedDate,
  metric,
}: Props) => {
  let variables = {
    id: page.id,
    type: metric.queryParameter,
    dateFrom: DateUtils.toDateString(date.start),
    dateTo: DateUtils.toDateString(date.end),
    compareFrom: comparedDate.start
      ? DateUtils.toDateString(comparedDate.start)
      : null,
    compareTo: comparedDate.end
      ? DateUtils.toDateString(comparedDate.end)
      : null,
  };

  const liQuery = useQuery<liPageCardQuery>(LI_PAGE_CARD_QUERY, {
    variables,
    skip: page._type !== TYPE.LINKEDIN,
  });

  const igQuery = useQuery<igPageCardQuery>(IG_PAGE_CARD_QUERY, {
    variables,
    skip: page._type !== TYPE.INSTAGRAM,
  });

  const fbQuery = useQuery<fbPageCardQuery>(FB_PAGE_CARD_QUERY, {
    variables,
    skip: page._type !== TYPE.FACEBOOK,
  });

  const cardData = useMemo(() => {
    switch (page._type) {
      case TYPE.LINKEDIN:
        return mapGraphqlPageCardQuery(
          metric.key,
          liQuery.data?.linkedInPage?.cardData,
          page._type,
        );
      case TYPE.INSTAGRAM:
        return mapGraphqlPageCardQuery(
          metric.key,
          igQuery.data?.instagramPage?.cardData,
          page._type,
        );
      case TYPE.FACEBOOK:
        return mapGraphqlPageCardQuery(
          metric.key,
          fbQuery.data?.facebookPage?.cardData,
          page._type,
        );
    }
  }, [
    fbQuery.data?.facebookPage?.cardData,
    igQuery.data?.instagramPage?.cardData,
    liQuery.data?.linkedInPage?.cardData,
    metric.key,
    page._type,
  ]);

  function isNotEmpty(data: MetricCardData | undefined) {
    return (
      data?.generalData &&
      data.generalData.length > 0 &&
      data?.metricGraphData &&
      data.metricGraphData.length > 0
    );
  }

  return {
    data: cardData,
    loading: liQuery.loading || igQuery.loading || fbQuery.loading,
    isEmpty: !isNotEmpty(cardData),
  };
};
