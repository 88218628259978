import React, { FC } from 'react';
import { colors, Icon } from '@kontentino/ui';
import { faImageSlash } from '@fortawesome/pro-regular-svg-icons/faImageSlash';
import AttachmentsCarousel from './attachmentsCards/AttachmentsCarousel';
import { StandardPost } from 'app/types/StandardPostPreview';
type Props = {
  post: StandardPost;
  allowVideoPlayback?: boolean;
  showLinkInfo?: boolean;
};

const AttachmentsCards: FC<Props> = ({
  showLinkInfo,
  post,
  allowVideoPlayback,
}) => {
  return (
    <div className="tw-flex-1 tw-overflow-hidden">
      {post.attachments.cards.length === 0 && (
        <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-bg-grayscale-10">
          <Icon
            icon={faImageSlash}
            color={colors.gray80}
            size={20}
            className="tw-divide-grayscale-50"
          />
        </div>
      )}
      {post.attachments.cards.length > 0 && (
        <AttachmentsCarousel
          attachments={post.attachments}
          allowVideoPlayback={allowVideoPlayback}
          withLinkInfo={showLinkInfo}
        />
      )}
    </div>
  );
};

export default AttachmentsCards;
