import React, { FC } from 'react';
import { usePostsContainer } from 'components/posts/context';
import { mergeTailwindClasses } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const PostsLayoutTopBarPostsSelect: FC = () => {
  const postsContainer = usePostsContainer();
  const { t } = useTranslation();

  return (
    <span className="tw-ml-auto tw-hidden tw-whitespace-nowrap lg:tw-block">
      <span
        onClick={postsContainer.selectAllPosts}
        data-name="posts_select-all-posts_button"
        className={mergeTailwindClasses(
          clsx(
            'tw-cursor-pointer hover:tw-text-primary-100 hover:tw-underline',
            {
              'tw-text-primary-100':
                postsContainer.allPostsSelected &&
                postsContainer.selectedPosts.length !== 0,
              'tw-pointer-events-none tw-text-grayscale-50':
                postsContainer.posts.allPosts.length === 0,
            },
          ),
        )}
      >
        {t('all')}
      </span>{' '}
      <span
        className={clsx({
          'tw-text-grayscale-50': postsContainer.posts.allPosts.length === 0,
        })}
      >
        /
      </span>{' '}
      <span
        onClick={postsContainer.clearSelectedPosts}
        data-name="posts_select-none-posts_button"
        className={mergeTailwindClasses(
          clsx(
            'tw-cursor-pointer hover:tw-text-primary-100 hover:tw-underline',
            {
              'tw-text-primary-100': postsContainer.selectedPosts.length === 0,
              'tw-pointer-events-none tw-text-grayscale-50':
                postsContainer.posts.allPosts.length === 0,
            },
          ),
        )}
      >
        {t('none')}
      </span>
    </span>
  );
};

export default PostsLayoutTopBarPostsSelect;
