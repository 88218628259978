import { FC } from 'react';
import style from 'components/insights/competitionAnalysis/pagesPerformance/pageRow/PageRow.module.scss';
import NumberUtil from 'utils/number';
import { CountObject } from '../PageRow';
import { positionClassName } from '../../utils/positionClassName';
import { Tooltip } from '@kontentino/ui';
import clsx from 'clsx';

type Props = {
  imageUrl: string;
  pageName: string;
  followersTotal: CountObject;
  posts: CountObject;
  interactions: CountObject;
  comments: CountObject;
  likes: CountObject;
  isHighlighted: boolean;
};

const InstagramPage: FC<Props> = ({
  imageUrl,
  pageName,
  followersTotal,
  posts,
  interactions,
  comments,
  likes,
  isHighlighted,
}) => {
  const metrics = [followersTotal, posts, interactions, comments, likes];

  const renderMetric = (metric: CountObject, index: number) => {
    return (
      <span
        className={clsx(
          style.value,
          style[positionClassName(metric, isHighlighted)],
        )}
        key={`metric-${index}`}
      >
        {NumberUtil.format(metric.count)}
      </span>
    );
  };

  return (
    <div className={style.pageRow}>
      <div className={style.pageInfo}>
        <div
          className={style.pageImage}
          style={{ backgroundImage: `url('${imageUrl}')` }}
        />
        <Tooltip content={pageName}>
          <span
            className={style.pageName}
            data-cy="insights_competition-analysis_page-performance-page-name"
          >
            {pageName}{' '}
          </span>
        </Tooltip>
      </div>
      <div className={style.metricsInfo}>
        {metrics.map((metric, index) => renderMetric(metric, index))}
      </div>
    </div>
  );
};

export default InstagramPage;
