import React, { forwardRef } from 'react';
import {
  ActivityIndicator,
  Icon,
  SocialMediaIcon,
  SocialMediaIconProps,
  Tooltip,
} from '@kontentino/ui';
import clsx from 'clsx';
import PageTypeUtils from 'app/utils/pageType';
import { getDateTimeLabel } from '../utils/getDateTimeLabel';
import HorizontalPostCardImagePreviewWithPostTypeIcon from './horizontalPostCard/HorizontalPostCardImagePreviewWithPostTypeIcon';
import HorizontalPostCardTags from './horizontalPostCard/HorizontalPostCardTags';
import { PostContextMenu } from 'app/components/contextMenus/PostContextMenu';
import { ListPost } from 'types/PostDetail';
import WorkflowStatusItem from 'app/components/workflowStatus/WorkflowStatusItem';
import { useCurrencies } from 'modules/listings/listingsSelectors';
import CurrencyUtils from 'app/utils/currency';
import { faDollarCircle } from '@fortawesome/pro-regular-svg-icons/faDollarCircle';
import { useTranslation } from 'react-i18next';

type Props = {
  post: ListPost;
  onClick?: () => void;
  withPageInfo?: boolean;
  isLoading?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const HorizontalPostCard = forwardRef<HTMLDivElement, Props>(
  ({ post, onClick, withPageInfo, className, isLoading, ...props }, ref) => {
    const { t } = useTranslation();
    const dateTimeLabel = getDateTimeLabel(post.date, post.time);
    const currencies = useCurrencies();
    const boostBudget = post.budget
      ? CurrencyUtils.format(
          post.budget,
          currencies.list[post.budget_currency],
          navigator.language,
        )
      : null;

    return (
      <PostContextMenu post={post}>
        <div
          ref={ref}
          onClick={onClick}
          className={clsx(
            'tw-relative tw-flex tw-w-full tw-cursor-pointer tw-gap-3 tw-border-t tw-border-t-grayscale-10 tw-bg-white tw-p-2 hover:tw-bg-grayscale-10',
            className,
          )}
          data-post-id={post.id}
          {...props}
        >
          {isLoading && (
            <div
              className={clsx(
                'tw-pointer-events-none tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-z-10 tw-flex tw-h-full tw-w-full tw-cursor-not-allowed tw-items-center tw-justify-center tw-bg-white/30',
              )}
            >
              <ActivityIndicator />
            </div>
          )}
          <div>
            <HorizontalPostCardImagePreviewWithPostTypeIcon
              image={{
                src: post.preview_image?.src,
              }}
              postType={post.type}
              postSubtype={post.subtype}
            />
          </div>
          <div className="tw-flex tw-w-full tw-flex-col">
            {withPageInfo && (
              <div className="tw-mb-1 tw-flex tw-items-center tw-gap-1 tw-text-sm">
                <SocialMediaIcon
                  size={16}
                  type={
                    PageTypeUtils.getName(
                      post.page.type,
                    ) as SocialMediaIconProps['type']
                  }
                />
                <span className="tw-max-w-[180px] tw-truncate">
                  {post.page.name}
                </span>
              </div>
            )}
            <div className="tw-flex tw-items-center tw-justify-between">
              <div
                className={clsx(
                  'tw-text-sm tw-font-semibold tw-text-grayscale-180',
                  {
                    'tw-text-grayscale-50': dateTimeLabel.length === 0,
                  },
                )}
              >
                {dateTimeLabel.length ? dateTimeLabel : 'Date and time'}
              </div>
            </div>
            <div
              className={clsx(
                'tw-w-[210px] tw-truncate tw-break-all tw-text-sm',
                {
                  'tw-text-grayscale-100': !post.text.length,
                },
              )}
            >
              {post.text ? post.text : t('placeholder.noText')}
            </div>

            {!!post.tags.length && <HorizontalPostCardTags tags={post.tags} />}

            <div className="tw-flex tw-w-full tw-items-center tw-gap-1 tw-pt-2">
              <WorkflowStatusItem
                status={post.status}
                assignedUsers={post.assigned_users}
              />
              <div className="tw-ml-auto tw-flex tw-items-center tw-gap-1">
                {!!boostBudget && (
                  <Tooltip content={boostBudget}>
                    <Icon
                      icon={faDollarCircle}
                      className="tw-text-grayscale-100"
                      size={14}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      </PostContextMenu>
    );
  },
);

HorizontalPostCard.displayName = 'HorizontalPostCard';

export default HorizontalPostCard;
