import React, { FC } from 'react';

import { Icon, IconButton } from '@kontentino/ui';
import dayjs, { Dayjs } from 'dayjs';

import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

type Props = Pick<
  ReactDatePickerCustomHeaderProps,
  'decreaseYear' | 'increaseYear' | 'date'
> & {
  minDate: Dayjs;
  maxDate: Dayjs;
};

const DatePickerHeaderDefault: FC<Props> = ({
  decreaseYear,
  increaseYear,
  date,
  minDate,
  maxDate,
}) => {
  const year = date.getFullYear();

  const isNextYearDisabled = maxDate
    ? dayjs(date).add(1, 'year').isAfter(maxDate)
    : false;
  const isPreviousYearDisabled = minDate
    ? dayjs(date).subtract(1, 'year').isBefore(minDate)
    : false;

  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      <IconButton
        onClick={decreaseYear}
        variant="light"
        icon={<Icon size={'md'} icon={faChevronLeft} />}
        size={'small'}
        disabled={isPreviousYearDisabled}
      />
      <span className="tw-text-md">{year}</span>
      <IconButton
        onClick={increaseYear}
        variant="light"
        icon={<Icon size={'md'} icon={faChevronRight} />}
        size={'small'}
        disabled={isNextYearDisabled}
      />
    </div>
  );
};

export default DatePickerHeaderDefault;
