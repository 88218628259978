import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { Icon } from '@kontentino/ui';
import { FC } from 'react';
type Props = {
  lists: string[][];
  title: string;
};

const Benefits: FC<Props> = ({ lists, title }) => {
  return (
    <>
      <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-6">
        <div className="tw-font-medium">{title}</div>
        <div className="tw-flex tw-w-full tw-flex-row tw-items-start tw-gap-[60px]">
          {lists.map((list, index) => (
            <ul
              key={`list-${index}`}
              className="tw-list-inside tw-list-none tw-p-0"
            >
              {list.map((item, index) => (
                <li
                  key={`list-item-${index}`}
                  className="tw-flex tw-min-h-[46px] tw-flex-row tw-items-center tw-gap-2 tw-text-md tw-font-regular"
                >
                  <Icon
                    icon={faCircleCheck}
                    size={16}
                    className="tw-text-primary-100"
                  />
                  {item}
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </>
  );
};

export default Benefits;
