import React from 'react';
import { NavigationItem } from 'components/navigation/utils/libs';
import MenuSecondaryLink from './MenuSecondaryLink';
import UserPermissionGate from 'components/UserPermissionGate';

type Props = {
  item: NavigationItem;
  activeItem?: NavigationItem;
};

const MenuSecondaryLinkGroup: React.FC<Props> = ({ item, activeItem }) => {
  return (
    <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-y-2 first:tw-mt-0">
      <span className="tw-text-sm tw-text-grayscale-100">{item.label}</span>
      {item.items &&
        item.items
          .filter((subItem) => !subItem.isDisabled)
          .map((subItem) => (
            <UserPermissionGate
              key={subItem.key}
              scopes={subItem.permissionsScope}
            >
              <MenuSecondaryLink
                item={subItem}
                isActive={activeItem?.key === subItem.key}
              />
            </UserPermissionGate>
          ))}
    </div>
  );
};

export default MenuSecondaryLinkGroup;
