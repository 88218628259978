import { useToast } from 'app/hooks/useToast';
import { useMutation, useQueryClient } from 'react-query';
import { TYPE_FACEBOOK } from '@kontentino/kontentino-constants/Pages';
import { FACEBOOK_SCOPES, QUERY_KEY } from 'components/adsPlanner/constants';
import AdsPlannerApi from 'api/adsplanner';
import { useEffect } from 'react';
import { ApiClientError } from 'api/client';
import i18n from 'i18n';
import { FacebookAvailableAdAccount } from 'types/AdAccount';
import { useSocialLogin } from 'utils/hooks/useSocialLogin';
import { FacebookAuthorizedResponse } from 'types/SocialLogin';

const useAdAccountReconnect = (adAccount: {
  id: number;
  externalId: string | null;
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const facebookLogin = useSocialLogin<FacebookAuthorizedResponse>({
    pageType: TYPE_FACEBOOK,
    scopes: FACEBOOK_SCOPES.CONNECT_AD_ACCOUNT,
  });

  const { mutate: connectAdAccount } = useMutation<
    {
      associateAdAccount: Omit<FacebookAvailableAdAccount, 'internalId'>;
    },
    ApiClientError,
    { adAccountExternalId: string; accessToken: string }
  >(
    (params) =>
      AdsPlannerApi.adAccount.associate(
        {
          id: adAccount.id,
          externalId: params.adAccountExternalId,
        },
        params.accessToken,
      ),
    {
      onSuccess: () => {
        toast('Ad account was successfully connected', 'success');
        queryClient.invalidateQueries(QUERY_KEY.AD_ACCOUNTS);
      },
      onError: (err) => {
        toast(err?.userMessage ?? i18n.somethingWentWrongTryAgain, 'error');
      },
    },
  );

  useEffect(() => {
    if (
      !!facebookLogin.authorization?.accessToken.length &&
      adAccount.externalId !== null
    ) {
      connectAdAccount({
        accessToken: facebookLogin.authorization.accessToken,
        adAccountExternalId: adAccount.externalId,
      });
    }
  }, [
    adAccount.externalId,
    connectAdAccount,
    facebookLogin?.authorization?.accessToken,
  ]);

  return {
    reconnect: facebookLogin.authorize,
  };
};

export default useAdAccountReconnect;
