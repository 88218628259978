import React, { FC } from 'react';
import type { InAppNotification } from 'types/InAppNotification';
import Skeleton from 'react-loading-skeleton';
import ListNotificationItem from './list/ListNotificationItem';
import { Button, Divider } from '@kontentino/ui';
import ArrayUtils from 'app/utils/array';

type Props = {
  isLoading: boolean;
  isLoadingMore: boolean;
  notifications: InAppNotification[];
  canLoadMore: boolean;
  onLoadMore: () => void;
};

const List: FC<Props> = ({
  isLoading,
  isLoadingMore,
  canLoadMore,
  notifications,
  onLoadMore,
}) => {
  if (isLoading) {
    return (
      <div className="tw-overflow-y-auto tw-p-4">
        {ArrayUtils.generate(6).map((value) => (
          <Skeleton height={60} key={value} />
        ))}
      </div>
    );
  }

  return (
    <div className="tw-overflow-y-auto tw-p-4">
      {notifications.map((notification, index) => (
        <React.Fragment key={`${notification.id}-${index}`}>
          <ListNotificationItem notification={notification} />
          {index !== notifications.length - 1 && (
            <Divider className="tw-mx-auto tw-w-[98%]" />
          )}
        </React.Fragment>
      ))}
      {canLoadMore && (
        <div className="tw-mt-3">
          <Button
            className="tw-w-full"
            onClick={onLoadMore}
            isLoading={isLoadingMore}
            disabled={isLoadingMore}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};

export default List;
