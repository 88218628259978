import { Editor } from '@tiptap/core';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from 'utils/hooks/useDebounce';
import { Button, DropdownMenu, Icon, Tooltip } from '@kontentino/ui';
import { faArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons/faArrowRightToBracket';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faPenSwirl } from '@fortawesome/pro-regular-svg-icons/faPenSwirl';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { faSpellCheck } from '@fortawesome/pro-regular-svg-icons/faSpellCheck';
import TextEditorAiContentRefineTextSelectionResults from 'app/modules/textEditor/components/textEditorAiContent/textEditorAiContentRefineTextSelection/TextEditorAiContentRefineTextSelectionResults';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import TextEditorAiContentRefineTextSelectionPopover from 'app/modules/textEditor/components/textEditorAiContent/textEditorAiContentRefineTextSelection/TextEditorAiContentRefineTextSelectionPopover';
import { preventDefault } from 'utils/mouseEvent';
import { TextTransformAction } from 'app/modules/posts/api/GenerationsApi';

type Props = {
  editor: Editor;
};

const TextEditorAiContentRefineTextSelection: FC<Props> = ({
  editor,
}: Props) => {
  const { t } = useTranslation();
  const {
    refineTextSelectionVisible,
    setRefineTextSelectionVisible,
    queries: { transformText },
  } = useTextEditorAiContent();
  const visible = refineTextSelectionVisible;
  const debouncedVisible = useDebounce(visible, 750);
  const [textToRefine, setTextToRefine] = useState('');
  const [lastStyleUsed, setLastStyleUsed] = useState<
    TextTransformAction | undefined
  >();

  function changeTextToRefine(value: string) {
    transformText.reset();
    setTextToRefine(value);
  }

  function hidePopover() {
    setTextToRefine('');
    setRefineTextSelectionVisible(false);
    transformText.reset();
  }

  function submit(value: TextTransformAction) {
    if (transformText.isLoading) return;

    transformText.mutate({
      source: textToRefine,
      action: value,
    });

    setLastStyleUsed(value);
  }

  function resubmit() {
    if (transformText.isLoading || !lastStyleUsed) return;

    transformText.mutate({
      source: textToRefine,
      action: lastStyleUsed,
    });
  }

  function replaceContent(value: string) {
    editor.commands.insertContentAt(
      {
        from: editor.extensionStorage.aiContent?.lastSelection?.from ?? 0,
        to: editor.extensionStorage.aiContent?.lastSelection?.to ?? 0,
      },
      value,
    );
    hidePopover();
  }

  function onDropdownMenuOpenChange(isOpen: boolean) {
    if (!isOpen) {
      hidePopover();
    }
  }

  const isRefining = !!(transformText.isLoading || transformText.data);

  return (
    <TextEditorAiContentRefineTextSelectionPopover
      editor={editor}
      onOpenChange={onDropdownMenuOpenChange}
      visible={!visible ? visible : debouncedVisible}
      isRefining={isRefining}
    >
      <>
        {isRefining && (
          <TextEditorAiContentRefineTextSelectionResults
            onResubmit={resubmit}
            renderResultAction={(text) => (
              <Tooltip content={t('replaceSelection')}>
                <Button
                  onClick={() => replaceContent(text)}
                  data-name="ai-content_replace_text"
                  variant="plain"
                  size="small"
                  className="tw-mr-3"
                >
                  <Icon
                    icon={faArrowRightToBracket}
                    className="tw-text-grayscale-100"
                  />
                  <span className="text-sm">{t('replace')}</span>
                </Button>
              </Tooltip>
            )}
          />
        )}
        {!transformText.isLoading && !transformText.data && (
          <DropdownMenu.Root onOpenChange={onDropdownMenuOpenChange}>
            <DropdownMenu.Trigger>
              <Button
                iconBefore={
                  <Icon icon={faSparkles} className="tw-text-grayscale-100" />
                }
                size="small"
                variant="ghost"
                className="tw-border tw-border-grayscale-20 tw-shadow-xl enabled:tw-bg-white enabled:hover:tw-bg-grayscale-5"
                data-name="ai-content-text-selection-refine-text"
                onClick={() =>
                  changeTextToRefine(
                    editor.extensionStorage.aiContent.lastSelectionText,
                  )
                }
              >
                {t('refineWithAI')}
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className="tw-text-grayscale-180">
              <DropdownMenu.Item
                onPointerDown={preventDefault()}
                data-name="ai-content-text-selection-refine-text-fix"
                onSelect={() => submit('fix-grammar')}
              >
                <Icon icon={faSpellCheck} />
                <span className="">{t('fixGrammar')}</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onPointerDown={preventDefault()}
                data-name="ai-content-text-selection-refine-text-rewrite"
                onSelect={() => submit('rewrite')}
              >
                <Icon icon={faPenSwirl} />
                <span className="">{t('rewrite')}</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onPointerDown={preventDefault()}
                data-name="ai-content-text-selection-refine-text-expand"
                onSelect={() => submit('expand')}
              >
                <Icon icon={faIndent} />
                <span className="">{t('expand')}</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onPointerDown={preventDefault()}
                data-name="ai-content-text-selection-refine-text-shorten"
                onSelect={() => submit('shorten')}
              >
                <Icon icon={faIndent} className="tw-rotate-180 " />
                <span className="">{t('shorten')}</span>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        )}
      </>
    </TextEditorAiContentRefineTextSelectionPopover>
  );
};

export default TextEditorAiContentRefineTextSelection;
