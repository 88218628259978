import { FC, useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import type { InAppNotification } from 'types/InAppNotification';
import {
  NOTIFICATIONS_COUNT_PER_PAGE,
  IN_APP_NOTIFICATIONS_QUERY,
} from 'components/in-app-notifications/InAppNotifications.constants';
import InAppNotificationsApi from 'app/api/inAppNotifications';

type Params = {
  data: InAppNotification[];
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
  canLoadMore: boolean;
  loadMore(): void;
};

type Props = {
  children(params: Params): JSX.Element;
};

const InAppNotificationsRequest: FC<Props> = ({ children }) => {
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    fetchNextPage({ pageParam: page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const {
    data,
    isError,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    [IN_APP_NOTIFICATIONS_QUERY.RETRIEVE],
    ({ pageParam }) => InAppNotificationsApi.get(pageParam),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        return lastPage.length >= NOTIFICATIONS_COUNT_PER_PAGE;
      },
    },
  );

  const handleFetchNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return children({
    data: data?.pages.flat() ?? [],
    isLoading,
    isError,
    isFetching,
    loadMore: handleFetchNextPage,
    canLoadMore: !!hasNextPage,
  });
};

export default InAppNotificationsRequest;
