import { FC, useRef, useState } from 'react';
import { useInsightsContainer } from 'components/insights/context';
import { useLocation } from 'react-router-dom';
import routes from 'constants/routes';
import { Button, Icon } from '@kontentino/ui';

import InsightsPresetsDatePicker from 'app/modules/insights/components/InsightsPresetsDatePicker';
import InsightsPeriodToCompareDatePicker, {
  InsightsPeriodToCompareDatePickerRef,
} from 'app/modules/insights/components/InsightsPeriodToCompareDatePicker';

import { faArrowsCross } from '@fortawesome/pro-regular-svg-icons/faArrowsCross';

const DatesPickers: FC = () => {
  const {
    filterState,
    changeDateFilter,
    clearComparedDateFilter,
    changePreset,
  } = useInsightsContainer();
  const compareDatePickerRef =
    useRef<InsightsPeriodToCompareDatePickerRef>(null);
  const [isComparing, setIsComparing] = useState<boolean>(
    !!filterState.comparedStartDate && !!filterState.comparedEndDate,
  );

  const location = useLocation();

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      <InsightsPresetsDatePicker
        initialPreset={filterState.datePreset}
        startDate={filterState.startDate}
        endDate={filterState.endDate}
        onChange={(dates, preset) => {
          changeDateFilter(dates, false);
          changePreset(preset);

          if (isComparing && compareDatePickerRef.current) {
            const compareOffset =
              Math.abs(dates.endDate?.diff(dates.startDate, 'days') || 0) + 1;

            compareDatePickerRef.current?.onRangeSelect(
              [
                dates.startDate
                  ?.clone()
                  .subtract(compareOffset, 'days')
                  .toDate() || null,
                null,
              ],
              {
                startDate: dates.startDate,
                endDate: dates.endDate,
              },
            );
          }
        }}
      />
      {location.pathname.includes(routes.INSIGHTS_PAGE_PERFORMANCE) && (
        <>
          <Button
            iconBefore={<Icon icon={faArrowsCross} />}
            variant={isComparing ? 'primary' : 'secondary'}
            className="tw-h-[36px]"
            onClick={() => setIsComparing(!isComparing)}
            data-name={`insights_page-performance_compare${
              isComparing ? '-remove' : ''
            }`}
          >
            {!isComparing && <span>Compare</span>}
          </Button>
          {isComparing && (
            <InsightsPeriodToCompareDatePicker
              ref={compareDatePickerRef}
              startDate={filterState.comparedStartDate}
              endDate={filterState.comparedEndDate}
              originalDateSelection={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={changeDateFilter}
              clearComparedDateFilter={clearComparedDateFilter}
            />
          )}
        </>
      )}
    </div>
  );
};
export default DatesPickers;
