import React, { FC } from 'react';
import { StandardPostPreviewProps } from 'app/modules/insights/components/StandardPostPreview';
import { formatDateTimeUTC } from 'app/utils/date';
import {
  Avatar,
  mergeTailwindClasses,
  SocialMediaIcon,
  SocialMediaIconProps,
} from '@kontentino/ui';
import PageTypeUtils from 'app/utils/pageType';

type Props = {
  sizes?: {
    avatar?: number;
    socialIcon?: number;
  };
} & Pick<StandardPostPreviewProps, 'post' | 'classNames'>;

const StandardPostProfile: FC<Props> = ({ sizes, post, classNames }) => {
  return (
    <div
      className={mergeTailwindClasses(
        'tw-flex tw-items-start',
        classNames?.profileRoot,
      )}
    >
      <Avatar
        name={post.page.name}
        size={sizes?.avatar ?? 40}
        src={post.page.logo ?? undefined}
      />
      <div className="tw-ml-2 tw-mr-auto">
        <div className="tw-text-md tw-font-semibold">{post.page.name}</div>
        <div className="tw-whitespace-nowrap tw-text-sm tw-text-grayscale-100">
          {formatDateTimeUTC(post.createdAt)}
        </div>
      </div>
      <SocialMediaIcon
        size={sizes?.socialIcon ?? 24}
        variant="plain"
        type={
          PageTypeUtils.getName(post.page.type) as SocialMediaIconProps['type']
        }
      />
    </div>
  );
};

export default StandardPostProfile;
