import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { BrowserStorage } from 'utils/browserStorage';

export type PostsView = 'calendar' | 'list' | 'board' | 'grid';

export const usePostsLayoutState = create<{
  activeView: PostsView;
  leftPanelOpen: boolean;
  postsIdeasActive: boolean;
  postsThumbnailsOn: boolean;
  gridMobileViewActive: boolean;
  isSidebarSearchFieldVisible: boolean;
  isRangeDateViewActive: boolean | undefined;
  toggleLeftPanel: () => void;
  togglePostsIdeas: () => void;
  togglePostsThumbnails: () => void;
  toggleGridMobileView: () => void;
  setActiveView: (view: PostsView) => void;
  setSideBarSearchFieldVisibility: (isVisible: boolean) => void;
  setIsRangeDateViewActive: (isActive: boolean) => void;
  postStatusVariant: 'task' | 'flow';
  togglePostStatusVariant: () => void;
  isPostsPageVisible: boolean;
  togglePostsPageVisibility: () => void;
  isPostsLabelsVisible: boolean;
  togglePostsLabelsVisibility: () => void;
  isBoostBudgetVisible: boolean;
  toggleBoostBudgetVisibility: () => void;
  isInspirationsVisible: boolean;
  toggleInspirationsVisibility: () => void;
}>()(
  persist(
    (set, get) => ({
      activeView: 'calendar',
      leftPanelOpen: true,
      postsIdeasActive: false,
      postsThumbnailsOn: true,
      gridMobileViewActive: false,
      isSidebarSearchFieldVisible: true,
      postStatusVariant: 'flow',
      isPostsPageVisible: true,
      isPostsLabelsVisible: true,
      isBoostBudgetVisible: false,
      isInspirationsVisible: true,
      isRangeDateViewActive: false,
      setIsRangeDateViewActive: (isActive: boolean) =>
        set(() => ({ isRangeDateViewActive: isActive })),
      setSideBarSearchFieldVisibility: (isVisible: boolean) =>
        set(() => ({ isSidebarSearchFieldVisible: isVisible })),
      toggleLeftPanel: () =>
        set((state) => {
          return {
            leftPanelOpen: !state.leftPanelOpen,
            postsIdeasActive: false,
          };
        }),
      togglePostsIdeas: () =>
        set((state) => {
          return {
            leftPanelOpen: false,
            postsIdeasActive: !state.postsIdeasActive,
          };
        }),
      togglePostsThumbnails: () =>
        set((state) => ({
          postsThumbnailsOn: !state.postsThumbnailsOn,
        })),
      setActiveView: (activeView: PostsView) =>
        set((state) => ({
          leftPanelOpen: ['list', 'grid'].includes(activeView)
            ? false
            : state.leftPanelOpen,
          postsIdeasActive: ['list', 'grid'].includes(activeView)
            ? state.leftPanelOpen || state.postsIdeasActive
            : state.postsIdeasActive,
          activeView,
        })),
      toggleGridMobileView: () =>
        set((state) => ({ gridMobileViewActive: !state.gridMobileViewActive })),
      togglePostStatusVariant: () =>
        set((state) => ({
          postStatusVariant:
            state.postStatusVariant === 'task' ? 'flow' : 'task',
        })),
      togglePostsPageVisibility: () =>
        set((state) => ({ isPostsPageVisible: !state.isPostsPageVisible })),
      togglePostsLabelsVisibility: () =>
        set((state) => ({ isPostsLabelsVisible: !state.isPostsLabelsVisible })),
      toggleBoostBudgetVisibility: () =>
        set((state) => ({ isBoostBudgetVisible: !state.isBoostBudgetVisible })),
      toggleInspirationsVisibility: () =>
        set((state) => ({
          isInspirationsVisible: !state.isInspirationsVisible,
        })),
    }),
    {
      name: BrowserStorage.keys.Stores.PostsLayout,
    },
  ),
);
