import React, { FC } from 'react';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import { colors, Icon, mergeTailwindClasses, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
type Props = {
  segments: {
    color: string;
    valueColor?: string;
    width: number | string;
    value?: string;
  }[];
  labelPosition?: 'top' | 'bottom';
  labelColor?: string;
  label?: string;
  tooltip?: string;
  className?: string;
};

const SegmentDistributionBar: FC<Props> = ({
  segments,
  labelPosition = 'top',
  labelColor = colors.gray80,
  label,
  tooltip,
  className,
}) => {
  const renderLabelWithTooltip = () => {
    return (
      <div style={{ color: labelColor }} className="tw-text-md tw-font-medium">
        {label}
        {tooltip && (
          <Tooltip content={tooltip}>
            <Icon icon={faCircleInfo} size="inherit" marginLeft={4} />
          </Tooltip>
        )}
      </div>
    );
  };

  const segmentsToRender = segments.filter((item) => item.width !== '0%');

  return (
    <>
      {labelPosition === 'top' && renderLabelWithTooltip()}
      <div
        className={mergeTailwindClasses(
          clsx(
            'tw-my-2 tw-flex tw-h-2 tw-w-full tw-flex-nowrap tw-items-center',
            className,
          ),
        )}
      >
        {segmentsToRender.map(({ color, width, value }, i) => (
          <div
            style={{
              width: width,
              backgroundColor: color,
              ...getSegmentBorderRadius(i, segmentsToRender.length),
            }}
            className="tw-h-full tw-min-w-[1%]"
            key={`segment-${i}`}
          />
        ))}
        {segmentsToRender.length === 1 && (
          <div
            className="tw-ml-1 tw-flex-none tw-text-md tw-font-medium"
            style={{
              color:
                segmentsToRender[0]?.valueColor ?? segmentsToRender[0].color,
            }}
          >
            {segmentsToRender[0].value}
          </div>
        )}
      </div>
      {label && labelPosition === 'bottom' && renderLabelWithTooltip()}
    </>
  );
};

function getSegmentBorderRadius(index: number, totalCount: number) {
  const isOddIndex = index % 2 === 0;
  const borderRadius = 4;

  if (totalCount === 1) {
    return { borderRadius };
  }

  if (totalCount > 1 && isOddIndex) {
    return {
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
    };
  }

  if (totalCount > 1 && !isOddIndex) {
    return {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    };
  }

  if (totalCount > 1 && index === totalCount - 1) {
    return {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    };
  }
}

export default SegmentDistributionBar;
