import React, { ReactNode } from 'react';
import { PostCommunicationContent } from '../../../types/postCommunicationContent';
import { Button, Icon } from '@kontentino/ui';
import { faThumbtack } from '@fortawesome/pro-regular-svg-icons/faThumbtack';
import { useTranslation } from 'react-i18next';
import useCommentPin from '../../hooks/useCommentPin';
import { usePostCommunicationContext } from '../../../PostCommunicationProvider';
import { Comment } from 'app/modules/comments/types';
interface CommentsListItemPinnedWrapperProps {
  comment: Comment;
  children: ReactNode;
}

const CommentsListItemPinnedWrapper: React.FC<
  CommentsListItemPinnedWrapperProps
> = ({ comment, children }) => {
  const { t } = useTranslation();
  const { toggleCommentPin } = useCommentPin(comment);
  const { disableCommunicationActions } = usePostCommunicationContext();

  return (
    <div className="tw-my-1 tw-flex tw-flex-col tw-rounded-md tw-border tw-border-grayscale-20">
      <div className="tw-inline-flex tw-items-center tw-justify-between tw-gap-4 tw-rounded-tl-md tw-rounded-tr-md tw-bg-grayscale-10 tw-px-2 tw-py-1">
        <div className="tw-flex tw-items-center tw-gap-1 tw-text-grayscale-190">
          <Icon icon={faThumbtack} size={'sm'} />
          <div className="tw-text-xs tw-font-semibold">
            {t('pinnedOn')}{' '}
            {comment.thread === PostCommunicationContent.CLIENT &&
              t('threads.client')}
            {comment.thread === PostCommunicationContent.INTERNAL &&
              t('threads.internal')}
          </div>
        </div>
        {!disableCommunicationActions && (
          <Button
            variant={'ghost'}
            className="tw-h-auto tw-text-xs tw-font-semibold tw-text-primary-90"
            onClick={toggleCommentPin}
          >
            {t('unpin')}
          </Button>
        )}
      </div>
      <div className="tw-px-3 tw-py-4">{children}</div>
    </div>
  );
};

export default CommentsListItemPinnedWrapper;
