import { FC } from 'react';
import PostsSelection from 'components/workflowOptions/PostsSelection';
import PostsSelectionContainer from 'components/workflowOptions/postsSelection/PostsSelectionContainer';
import { STATUSES_ALLOWED_FOR_TASK_ASSIGNMENT } from '@kontentino/kontentino-constants/Posts';
import Footer from 'app/components/timeSavers/assignMultipleTasksModal/Footer';
import { STEPS } from 'app/components/timeSavers/assignMultipleTasksModal/utils/constants';
import AssignForm from 'app/components/timeSavers/assignMultipleTasksModal/AssignForm';
import Modal from 'components/shared/Modal';
import { ListPost } from 'types/PostDetail';

type Props = {
  selectedStatuses: number[];
  onSelectPostStatus: (status: number) => void;
  selectedPosts: ListPost[];
  onSelectPost: (post: ListPost) => void;
  onSelectMultiplePosts: (posts: ListPost[]) => void;
  onGoNext: () => void;
  onGoBack: () => void;
  activeStep: number;
  selectedPagesIds: number[];
  taskMessage: string;
  isUrgent: boolean;
  canSendEmail: boolean;
  onChangeSelectedUsers: (users: number[]) => void;
  initialSelectedUsersIds: number[];
  onTaskMessageChange: (value: string) => void;
  onSelectUrgent: () => void;
  onSelectEmailNotification: () => void;
  onAssignTasks: () => void;
  isSubmitting: boolean;
  formErrors: Record<string, string>;
};

const ModalBody: FC<Props> = ({
  selectedStatuses,
  onSelectPostStatus,
  selectedPosts,
  onSelectPost,
  onSelectMultiplePosts,
  onGoNext,
  activeStep,
  selectedPagesIds,
  taskMessage,
  isUrgent,
  canSendEmail,
  onChangeSelectedUsers,
  onTaskMessageChange,
  onSelectUrgent,
  onSelectEmailNotification,
  onAssignTasks,
  isSubmitting,
  initialSelectedUsersIds,
  formErrors,
}) => {
  let bodyComponent = null;

  switch (activeStep) {
    case STEPS.SELECT_POSTS.step:
      bodyComponent = (
        <PostsSelectionContainer
          allowedStatuses={STATUSES_ALLOWED_FOR_TASK_ASSIGNMENT}
          selectedStatuses={selectedStatuses}
        >
          {(params) => (
            <PostsSelection
              allowedStatuses={STATUSES_ALLOWED_FOR_TASK_ASSIGNMENT}
              selectedStatuses={selectedStatuses}
              onSelectPostStatus={onSelectPostStatus}
              posts={params.posts}
              onSelectPost={onSelectPost}
              selectedPosts={selectedPosts}
              onSelectAllPosts={() => onSelectMultiplePosts(params.posts)}
              onUnselectAllPosts={() => onSelectMultiplePosts([])}
            />
          )}
        </PostsSelectionContainer>
      );
      break;
    case STEPS.SELECT_ASSIGNEES.step:
      bodyComponent = (
        <AssignForm
          isUrgent={isUrgent}
          canSendEmail={canSendEmail}
          taskMessage={taskMessage}
          pagesIds={selectedPagesIds}
          onSelectedUsersChange={onChangeSelectedUsers}
          initialSelectedUsersIds={initialSelectedUsersIds}
          onTaskMessageChange={onTaskMessageChange}
          onSelectUrgent={onSelectUrgent}
          onSelectEmailNotification={onSelectEmailNotification}
          formErrors={formErrors}
        />
      );
      break;
  }

  return (
    <>
      <Modal.Content>{bodyComponent}</Modal.Content>
      <Footer
        activeStep={activeStep}
        onGoNext={onGoNext}
        canGoNext={selectedPosts.length !== 0}
        onAssignTasks={onAssignTasks}
        isLoading={isSubmitting}
      />
    </>
  );
};

export default ModalBody;
