import { FC } from 'react';
import { Button, Chip, DropdownMenu, Icon } from '@kontentino/ui';
import { UserPermission } from 'constants/userPermission';
import UserPermissionGate from 'components/UserPermissionGate';
import Link from 'components/shared/Link';
import { modalRoutes } from 'constants/routes';
import { createSearchFromParams } from 'utils/url';
import UserpilotUtils from 'app/utils/userpilot';
import IntercomUtils from 'app/utils/intercom';
import { faArrowTurnDownRight } from '@fortawesome/pro-regular-svg-icons/faArrowTurnDownRight';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faUserLock } from '@fortawesome/pro-regular-svg-icons/faUserLock';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { useTranslation } from 'react-i18next';
type Props = {
  selectedPostsIds: number[];
  activePagesIds: number[];
};

const TIME_SAVERS_OPTIONS = [
  {
    hash: modalRoutes.DUPLICATE_POSTS,
    dataAttributes: {
      'data-name': 'time-savers-dropdown-duplicate-posts',
    },
    element: (label: string) => (
      <>
        <Icon icon={faCopy} />
        {label}
      </>
    ),
    i18nKey: 'duplicatePosts',
    permissions: [UserPermission.POST_DUPLICATE],
    events: {
      userPilot: UserpilotUtils.events.POSTS_TIME_SAVERS_DUPLICATE,
      intercom: IntercomUtils.events.POSTS_TIME_SAVERS_DUPLICATE,
    },
  },
  {
    hash: modalRoutes.MOVE_POSTS,
    dataAttributes: {
      'data-name': 'time-savers-dropdown-move-post',
    },
    element: (label: string) => (
      <>
        <Icon icon={faArrowTurnDownRight} />
        {label}
      </>
    ),
    i18nKey: 'movePosts',
    permissions: [UserPermission.POST_MOVE],
    events: {
      userPilot: UserpilotUtils.events.POSTS_TIME_SAVERS_MOVE,
      intercom: IntercomUtils.events.POSTS_TIME_SAVERS_MOVE,
    },
    separator: true,
  },
  {
    hash: modalRoutes.CHANGE_POSTS_STATUS,
    dataAttributes: {
      'data-name': 'time-savers-dropdown-change-post-status',
    },
    element: (label: string) => (
      <>
        <Icon icon={faCheckCircle} />
        {label}
      </>
    ),
    i18nKey: 'changePostStatus',
    permissions: [UserPermission.POST_MASS_CHANGE_POST_STATUS],
    events: {
      userPilot: UserpilotUtils.events.POSTS_TIME_SAVERS_CHANGE_STATUS,
      intercom: IntercomUtils.events.POSTS_TIME_SAVERS_CHANGE_STATUS,
    },
    separator: true,
  },
  {
    hash: modalRoutes.ASSIGN_MULTIPLE_TASKS,
    dataAttributes: {
      'data-name': 'time-savers-dropdown-assign-multiple-tasks',
    },
    element: (label: string) => (
      <>
        <Icon icon={faUserPlus} />
        {label}
      </>
    ),
    i18nKey: 'assignMultipleTasks',
    permissions: [UserPermission.USER_ASSIGN_TASK],
    events: {
      userPilot: UserpilotUtils.events.POSTS_TIME_SAVERS_ASSIGN,
      intercom: IntercomUtils.events.POSTS_TIME_SAVERS_ASSIGN,
    },
    separator: true,
  },
  {
    hash: modalRoutes.SEND_FOR_CLIENT_APPROVAL,
    dataAttributes: {
      'data-name': 'time-savers-dropdown-send-for-client-approval',
    },
    element: (label: string) => (
      <>
        <Icon icon={faUserLock} />
        {label}
      </>
    ),
    i18nKey: 'sendForClientApproval',
    permissions: [UserPermission.POST_SEND_FOR_CLIENT_APPROVAL],
    events: {
      userPilot: UserpilotUtils.events.POSTS_TIME_SAVERS_CLIENT_APPROVAL,
      intercom: IntercomUtils.events.POSTS_TIME_SAVERS_CLIENT_APPROVAL,
    },
  },
  {
    hash: modalRoutes.SEND_FOR_INTERNAL_APPROVAL,
    dataAttributes: {
      'data-name': 'time-savers-dropdown-send-for-internal-approval',
    },
    element: (label: string) => (
      <>
        <Icon icon={faUsers} />
        {label}
      </>
    ),
    i18nKey: 'sendForInternalApproval',
    permissions: [UserPermission.POST_SEND_FOR_INTERNAL_APPROVAL],
    events: {
      userPilot: UserpilotUtils.events.POSTS_TIME_SAVERS_INTERNAL_APPROVAL,
      intercom: IntercomUtils.events.POSTS_TIME_SAVERS_INTERNAL_APPROVAL,
    },
    separator: true,
  },
  {
    hash: modalRoutes.SCHEDULE_POSTS,
    dataAttributes: {
      'data-name': 'time-savers-dropdown-schedule-posts',
    },
    element: (label: string) => (
      <>
        <Icon icon={faClock} />
        {label}
      </>
    ),
    i18nKey: 'schedulePost',
    permissions: [UserPermission.POST_SCHEDULE],
    events: {
      userPilot: UserpilotUtils.events.POSTS_TIME_SAVERS_SCHEDULE,
      intercom: IntercomUtils.events.POSTS_TIME_SAVERS_SCHEDULE,
    },
  },
];

const PostsTimeSaversDropdown: FC<Props> = ({
  selectedPostsIds,
  activePagesIds,
}) => {
  const { t } = useTranslation();
  const postsIdsSearchParam =
    selectedPostsIds.length !== 0
      ? createSearchFromParams({
          posts: selectedPostsIds,
          profiles: activePagesIds,
        })
      : '';

  const commonSearchParams = { search: postsIdsSearchParam };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button
          variant="secondary"
          className="tw-relative tw-hidden tw-h-9 tw-w-9 tw-gap-0 sm:tw-flex 2xl:tw-w-auto 2xl:tw-gap-1"
          data-name="posts-time-savers"
          iconBefore={<Icon icon={faLayerGroup} />}
        >
          <span className="tw-relative tw-flex tw-h-full tw-items-center">
            <span className="tw-hidden tw-whitespace-nowrap 2xl:tw-flex">
              {t('timeSavers.title')}
            </span>
            {selectedPostsIds.length > 0 && (
              <Chip
                className="tw-absolute -tw-right-4 -tw-top-2 tw-border tw-border-white 2xl:-tw-right-2"
                variant="alert"
              >
                {selectedPostsIds.length}
              </Chip>
            )}
          </span>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10">
        {TIME_SAVERS_OPTIONS.map((option, index) => {
          return (
            <UserPermissionGate
              key={`time-savers-option-${index}`}
              scopes={option.permissions}
            >
              <Link to={{ hash: option.hash, ...commonSearchParams }}>
                <DropdownMenu.Item
                  onClick={() => {
                    UserpilotUtils.track(option.events.userPilot);
                    IntercomUtils.track(option.events.intercom);
                  }}
                  {...option.dataAttributes}
                >
                  {option.element(t(option.i18nKey))}
                </DropdownMenu.Item>
                {option.separator && <DropdownMenu.Separator />}
              </Link>
            </UserPermissionGate>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default PostsTimeSaversDropdown;
