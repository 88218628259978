import React, { FC, ReactNode } from 'react';
import Modal from 'components/shared/Modal';
import { ModalRouteProps } from 'context/ModalRouter';

interface Props extends Partial<ModalRouteProps> {
  image: ReactNode;
  title: ReactNode;
  description: ReactNode;
  buttons: ReactNode;
  open?: boolean;
  dataName?: string;
}

const BaseWelcomeModal: FC<Props> = (props) => {
  return (
    <Modal
      modalManager={props.modalManager}
      size="responsive"
      open={props.open}
      classNames={{
        modal: '!tw-w-[480px]',
      }}
    >
      <Modal.Header />
      <Modal.Content data-name={props.dataName} className="tw-pt-6">
        <div className="tw-mb-4 tw-flex tw-min-h-[174px] tw-justify-center">
          {props.image}
        </div>
        <div className="tw-mb-4 tw-text-center">
          <p className="tw-mb-1 tw-text-lg tw-font-semibold">
            {props.title} 👋
          </p>
          <p className="tw-text-md tw-font-regular tw-text-grayscale-140">
            {props.description}
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer withDivider>{props.buttons}</Modal.Footer>
    </Modal>
  );
};

export default BaseWelcomeModal;
