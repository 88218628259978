import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons/faShareNodes';
import { faSquareList } from '@fortawesome/pro-regular-svg-icons/faSquareList';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import PageTypeUtils from 'app/utils/pageType';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faGooglePlus } from '@fortawesome/free-brands-svg-icons/faGooglePlus';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faThreads } from '@fortawesome/free-brands-svg-icons/faThreads';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { colors } from '@kontentino/ui';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  ProfileSelectorFilterOptionKey,
  ProfileSelectorPageTypes,
} from 'app/components/profileSelector/types/profileSelectorFilter';

export const PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES: Record<
  ProfileSelectorPageTypes | ProfileSelectorFilterOptionKey,
  {
    name: string;
    icon: IconDefinition;
    iconColor?: string;
    i18nKey?: string;
  }
> = {
  [ProfileSelectorFilterOptionKey.All]: {
    name: 'All',
    icon: faSquareList,
    i18nKey: 'all',
  },
  [ProfileSelectorFilterOptionKey.ProfileGroups]: {
    name: 'Profile groups',
    icon: faLayerGroup,
    i18nKey: 'profileGroups',
  },
  [ProfileSelectorFilterOptionKey.Profiles]: {
    name: 'Profiles',
    icon: faShareNodes,
    i18nKey: 'profiles',
  },
  [ProfileSelectorFilterOptionKey.Selected]: {
    name: 'Selected',
    icon: faCheckSquare,
    i18nKey: 'selected',
  },
  [TYPE.FACEBOOK]: {
    name: PageTypeUtils.getLabel(TYPE.FACEBOOK),
    icon: faFacebook,
    iconColor: colors.facebook,
  },
  [TYPE.INSTAGRAM]: {
    name: PageTypeUtils.getLabel(TYPE.INSTAGRAM),
    icon: faInstagram,
    iconColor: colors.instagram.top,
  },
  [TYPE.TWITTER]: {
    name: PageTypeUtils.getLabel(TYPE.TWITTER),
    icon: faXTwitter,
    iconColor: colors.twitterX,
  },
  [TYPE.LINKEDIN]: {
    name: PageTypeUtils.getLabel(TYPE.LINKEDIN),
    icon: faLinkedin,
    iconColor: colors.linkedin,
  },
  [TYPE.GOOGLE_PLUS]: {
    name: PageTypeUtils.getLabel(TYPE.GOOGLE_PLUS),
    icon: faGooglePlus,
  },
  [TYPE.FACEBOOK_AD]: {
    name: PageTypeUtils.getLabel(TYPE.FACEBOOK_AD),
    icon: faFacebook,
    iconColor: colors.facebook,
  },
  [TYPE.PINTEREST]: {
    name: PageTypeUtils.getLabel(TYPE.PINTEREST),
    icon: faPinterest,
    iconColor: colors.pinterest,
  },
  [TYPE.GOOGLE_MY_BUSINESS]: {
    name: PageTypeUtils.getLabel(TYPE.GOOGLE_MY_BUSINESS),
    icon: faGoogle,
    iconColor: colors.google_my_business,
  },
  [TYPE.YOUTUBE]: {
    name: PageTypeUtils.getLabel(TYPE.YOUTUBE),
    icon: faYoutube,
    iconColor: colors.youtube,
  },
  [TYPE.TIKTOK]: {
    name: PageTypeUtils.getLabel(TYPE.TIKTOK),
    icon: faTiktok,
    iconColor: colors.tiktok,
  },
  [TYPE.THREADS]: {
    name: PageTypeUtils.getLabel(TYPE.THREADS),
    icon: faThreads,
    iconColor: colors.threads,
  },
};
