import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { Icon } from '@kontentino/ui';
import { Page } from 'app/modules/insights/types/Page';
import { TYPE_INSTAGRAM } from '@kontentino/kontentino-constants/Pages';
type Props = {
  page: Page;
};

const PageMetricCardContentEmpty: FC<Props> = ({ page }) => {
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-gap-1">
      <Icon icon={faCircleExclamation} className="tw-text-grayscale-100" />
      <span className="tw-max-w-sm tw-text-center tw-text-sm tw-text-grayscale-100">
        {t(
          page._type === TYPE_INSTAGRAM
            ? 'somethingWentWrongMetricMightNotBeAvailable'
            : 'somethingWentWrongWhenFetchingData',
        )}
      </span>
    </div>
  );
};

export default PageMetricCardContentEmpty;
