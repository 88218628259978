import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { BrowserStorage } from 'utils/browserStorage';
import { useMemo } from 'react';

type LanguageOption = {
  label: string;
  value: string;
};

type GroupedOptions = {
  label: string;
  options: LanguageOption[];
}[];

type LastSelectedLanguagesStore = {
  languages: LanguageOption[];
  addLanguage: (language: LanguageOption) => void;
  getGroupedOptions: (allLanguages: LanguageOption[]) => GroupedOptions;
};

const useLastSelectedOutputLanguagesStore =
  create<LastSelectedLanguagesStore>()(
    persist(
      (set, get) => ({
        languages: [],
        addLanguage: (language: LanguageOption) => {
          set((state) => ({
            languages: [
              language,
              ...state.languages.filter(
                (lang) => lang.value !== language.value,
              ),
            ].slice(0, 3),
          }));
        },
        getGroupedOptions: (
          allLanguages: LanguageOption[],
        ): { label: string; options: LanguageOption[] }[] => {
          const lastUsedLanguages = get().languages;

          return [
            {
              label: 'Last used',
              options: lastUsedLanguages,
            },
            {
              label: 'All',
              options: allLanguages.filter(
                (language) =>
                  !lastUsedLanguages
                    .map((language) => language.value)
                    .includes(language.value),
              ),
            },
          ];
        },
      }),
      {
        name: BrowserStorage.keys.Stores.OutputLanguageSelect,
      },
    ),
  );

export function useLastSelectedOutputLanguages() {
  const { languages, addLanguage, getGroupedOptions } =
    useLastSelectedOutputLanguagesStore();

  return useMemo(
    () => ({
      languages,
      addLanguage,
      getGroupedOptions,
    }),
    [languages, addLanguage, getGroupedOptions],
  );
}
