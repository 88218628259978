import React, { FC, useState } from 'react';
import KAI_HAND from 'app/modules/aiContent/assets/kai-hand.svg';
import useInterval from 'app/hooks/useInterval';
import clsx from 'clsx';
import { mergeTailwindClasses } from '@kontentino/ui';

type Props = {
  title: string;
  classNames?: {
    img?: string;
    root?: string;
  };
};

const loadingPhrases = [
  'Brewing content ideas...',
  'Finding viral potential...',
  'Making content magic...',
  'Crafting the perfect hashtag...',
  'Trending any second now...',
  'Bribing the algorithm...',
  'Counting those likes...',
  'Perfecting your feed...',
  'Seeking engagement...',
  'Dodging bad hashtags...',
  'Polishing your posts...',
  'Racing trending topics...',
  'Fueling with coffee...',
  'Catching attention spans...',
  'Spreading good vibes...',
  'Making content pop...',
  'Chasing engagement...',
];

const LoadingGeneratedResults: FC<Props> = (props) => {
  const [phraseIndex, setPhraseIndex] = useState(0);

  useInterval(() => {
    setPhraseIndex((prevIndex) =>
      prevIndex + 1 === loadingPhrases.length ? prevIndex : prevIndex + 1,
    );
  }, 3000);

  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-p-4',
          props.classNames?.root,
        ),
      )}
    >
      <img
        alt="Kai hand"
        src={KAI_HAND}
        className={mergeTailwindClasses(
          clsx(
            'tw-mb-8 tw-h-auto tw-w-full tw-max-w-[14.5rem]',
            props.classNames?.img,
          ),
        )}
      />
      <div className="tw-mb-2 tw-text-md tw-font-medium tw-text-grayscale-180">
        {props.title}
      </div>
      <div className="tw-relative tw-mb-2 tw-h-5 tw-w-full tw-max-w-[20.5rem] tw-text-center tw-text-md tw-text-grayscale-180">
        {loadingPhrases.map((desc, index) => (
          <div
            key={`description-${index}`}
            className={clsx(
              'tw-absolute tw-left-0 tw-top-0 tw-w-full tw-transition-opacity',
              {
                'tw-opacity-1': index === phraseIndex,
                'tw-opacity-0': index !== phraseIndex,
              },
            )}
          >
            {desc}
          </div>
        ))}
      </div>
      <div
        className="tw-border-t-opacity-0 tw-inline-flex tw-h-4 tw-w-4 tw-animate-spin tw-rounded-full tw-border-[2px] tw-border-t-white tw-border-opacity-0 tw-text-primary-100"
        role="status"
        aria-label="loading"
      />
    </div>
  );
};

export default LoadingGeneratedResults;
