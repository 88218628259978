import React, { FC } from 'react';
import LabelsView from 'components/shared/detail/LabelsView';
import AccordionWidgetIcon from 'app/modules/posts/components/accordionWidget/AccordionWidgetIcon';
import AccordionWidget from 'app/modules/posts/components/AccordionWidget';
import AccordionWidgetTitle from 'app/modules/posts/components/accordionWidget/AccordionWidgetTitle';
import { Chip } from '@kontentino/ui';
import { PostDetail } from 'types/PostDetail';

type Props = {
  labels: PostDetail['tags'];
  isLoading: boolean;
  isError: boolean;
  accordionProps?: Partial<React.ComponentProps<typeof AccordionWidget>>;
};

const Labels: FC<Props> = ({ labels, isLoading, isError, accordionProps }) => {
  return (
    <AccordionWidget
      data-cy="post-preview-labels"
      icon={<AccordionWidgetIcon variant="labels" />}
      title={<AccordionWidgetTitle variant="labels" />}
      titleSuffix={(isOpen) =>
        !!labels.length &&
        !isOpen && <Chip variant="darkGrey">{labels.length}</Chip>
      }
      {...accordionProps}
    >
      <LabelsView labels={labels} isLoading={isLoading} isError={isError} />
    </AccordionWidget>
  );
};

export default Labels;
