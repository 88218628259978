import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
export const addProfile = (context: ActionContext) =>
  createAction(
    {
      id: 'add-profile',
      label: i18n.t('addProfile'),
      icon: <Icon icon={faPlus} />,
      scopes: UserPermission.PAGE_CREATE,
      perform: () => {
        if (
          context.account.plan.pageCount !== null &&
          context.account.plan.pageCount <= (context.account.pageCount || 0)
        ) {
          context.modalHistory.push({
            hash: modalRoutes.PROFILE_LIMIT_REACHED,
          });
        } else {
          context.modalHistory.push({
            hash: modalRoutes.NEW_PROFILE,
          });
        }
      },
    },
    context,
  );
