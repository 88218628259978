import React, { FC, ReactNode } from 'react';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import { useTranslation } from 'react-i18next';
import AiContentRemainingAttemptsBadge from 'app/modules/aiContent/components/AiContentRemainingAttemptsBadge';
import TextGeneratedResults from 'app/modules/aiContent/components/results/TextGeneratedResults';
import TextEditorAiContentPopupGenerateFooter from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentPopupGenerateTextFooter';

type Props = {
  renderResultAction: (text: string) => ReactNode;
  onResubmit(): void;
};

const TextEditorAiContentRefineTextSelectionResults: FC<Props> = ({
  renderResultAction,
  onResubmit,
}) => {
  const {
    queries: { transformText, options },
  } = useTextEditorAiContent();
  const { t } = useTranslation();

  return (
    <div
      className="tw-shadow tw-max-h-[410px] tw-w-[480px] tw-rounded tw-border tw-border-grayscale-20 tw-bg-white"
      data-name="ai-content-popup"
    >
      <div className="tw-p-4 tw-pb-0">
        <div className="tw-flex tw-justify-between">
          <div className="tw-flex tw-items-center tw-gap-2 tw-text-lg tw-font-semibold tw-text-grayscale-180">
            <span>{t('refineWithAI')}</span>
          </div>
          <div>
            <AiContentRemainingAttemptsBadge options={options} />
          </div>
        </div>
      </div>
      <TextGeneratedResults
        renderAction={renderResultAction}
        suggestions={transformText.data ? [transformText.data] : []}
        isLoading={transformText.isLoading}
        classNames={{
          root: 'tw-overflow-y-auto',
          loadingImg: 'tw-h-[8.5rem]',
          emptyImg: 'tw-h-[8.5rem]',
          resultText: 'tw-overflow-y-auto tw-max-h-[180px]',
        }}
      />
      {!transformText.isLoading && !!transformText.data && (
        <TextEditorAiContentPopupGenerateFooter
          hasResults={!!transformText.data}
          onSubmit={onResubmit}
        />
      )}
    </div>
  );
};

export default TextEditorAiContentRefineTextSelectionResults;
