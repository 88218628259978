import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'components/shared/Modal';
import { ModalRouteProps } from 'context/ModalRouter';
import { Button, Field, TextInput } from '@kontentino/ui';
import Link from 'components/shared/Link';
import Popup from 'utils/popup';
import UserApi from 'api/user';
import { PublicUtils } from 'components/publicPreviews/publicUtils';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import Logger from 'utils/logger';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ApiClientError } from 'api/client';

type Props = ModalRouteProps & {
  token: string;
};

type Form = z.infer<typeof schema>;
const schema = z.object({
  name: z.string(),
  email: z.string().email(),
});

const PublicInviteUser: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    resolver: zodResolver(schema),
  });

  const sharedBy = PublicUtils.getSharedData();
  const actionType = props.modalManager.state?.actionType;
  const { t } = useTranslation();
  const { mutate, isLoading } = useMutation(UserApi.requestInvitation, {
    onSuccess: () => {
      Popup.success({
        text: t('publicInviteUser.requestSent', { user: sharedBy.name }),
      });
      props.modalManager.close();
    },
    onError: (error: ApiClientError) => {
      Popup.error({
        text:
          error?.userMessage ??
          t('publicInviteUser.somethingWentWrongTryAgain'),
      });
      Logger.error(error);
    },
  });

  const onSubmit = (values: { name: string; email: string }) => {
    mutate({
      name: values.name,
      email: values.email,
      userEmail: sharedBy.email,
      accountUsername: sharedBy.accountUsername,
      token: props.token,
    });
  };

  return (
    <Modal
      modalManager={props.modalManager}
      classNames={{
        root: '!tw-z-[10000]',
      }}
    >
      <Modal.Header title={t('publicInviteUser.modalHeader')} />
      <Modal.Content className="tw-mb-6">
        <span>
          {['cta', 'comment'].includes(actionType) && (
            <Trans
              i18nKey="publicInviteUser.feedbackMessage"
              values={{ name: sharedBy.name }}
            >
              To provide feedback to the post, you need to be invited to{' '}
              <strong>{sharedBy.name}</strong>'s company account.
            </Trans>
          )}
          {actionType === 'navbar' && (
            <Trans
              i18nKey="publicInviteUser.featureTryMessage"
              values={{ name: sharedBy.name }}
            >
              To try this feature, you need to be invited to{' '}
              <strong>{sharedBy.name}</strong>’s company account or create your
              own.
            </Trans>
          )}
          <Trans
            i18nKey="publicInviteUser.askInvite"
            values={{ name: sharedBy.name }}
          >
            Ask <strong>{sharedBy.name}</strong> to invite you.
          </Trans>
        </span>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="tw-mt-4 tw-flex tw-flex-col tw-gap-4"
        >
          <Field.Group>
            <Field.Label required>{t('publicInviteUser.yourName')}</Field.Label>
            <TextInput
              placeholder={t('publicInviteUser.yourNamePlaceholder')}
              {...register('name')}
              disabled={isLoading}
              error={!!errors.name?.message}
              data-cy="public-invite-user_name-input"
            />
            <Field.Error>{errors.name?.message}</Field.Error>
          </Field.Group>
          <Field.Group>
            <Field.Label required>
              {t('publicInviteUser.yourEmail')}
            </Field.Label>
            <TextInput
              placeholder={t('publicInviteUser.yourEmailPlaceholder')}
              {...register('email')}
              disabled={isLoading}
              error={!!errors.email?.message}
              data-cy="public-invite-user_email-input"
            />
            <Field.Error>{errors.email?.message}</Field.Error>
          </Field.Group>
          <div>
            <Button
              type="submit"
              data-name="public-link-request-invitation"
              data-cy="public-link-request-invitation"
            >
              {t('publicInviteUser.requestInvitation')}
            </Button>
          </div>
          <div>
            {t('publicInviteUser.alreadyInvited')}&nbsp;
            <Link
              to="https://www.kontentino.com/login"
              className="tw-font-medium"
            >
              {t('publicInviteUser.signIn')}
            </Link>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default PublicInviteUser;
