import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isActiveMenu?: boolean;
  className?: string;
};

export const VerticalMenuItemWrapper = forwardRef<HTMLDivElement, Props>(
  ({ children, isActiveMenu, className }, ref) => (
    <div
      ref={ref}
      className={mergeTailwindClasses(
        clsx(
          "before:content-'' tw-group tw-relative tw-flex tw-h-8 tw-items-center tw-justify-center before:tw-invisible before:tw-absolute before:tw-right-0 before:tw-block before:tw-h-full before:tw-w-[2px] before:tw-bg-primary-100 before:tw-opacity-0 ",
          {
            'tw-text-primary-100 before:tw-visible before:tw-opacity-100':
              isActiveMenu,
          },
          className,
        ),
      )}
    >
      {children}
    </div>
  ),
);
