import { FC } from 'react';
import { Button } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type Props = {
  isCancelDisabled?: boolean;
  disabled?: boolean;
  onRangeSelectCancel: () => void;
  onDateRangeSubmit: () => void;
  onClearCustomPeriod?: () => void;
};

const DatePickerFooter: FC<Props> = ({
  isCancelDisabled,
  disabled,
  onRangeSelectCancel,
  onDateRangeSubmit,
  onClearCustomPeriod,
}) => {
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-w-full tw-justify-between tw-p-2">
      {onClearCustomPeriod && (
        <Button variant={'ghost'} onClick={onClearCustomPeriod}>
          {t('clear')}
        </Button>
      )}
      <div
        className={clsx('tw-flex tw-gap-x-3', {
          'tw-ml-auto': !onClearCustomPeriod,
        })}
      >
        <Button
          variant={'light'}
          onClick={onRangeSelectCancel}
          disabled={isCancelDisabled}
        >
          {t('cancel')}
        </Button>
        <Button disabled={disabled} onClick={onDateRangeSubmit}>
          {t('apply')}
        </Button>
      </div>
    </div>
  );
};

export default DatePickerFooter;
