import React, { FC, useRef, useState } from 'react';
import style from 'app/modules/insights/components/standardPostPreview/attachmentsCards/attachmentsCarousel/AttachmentsCarousel.module.scss';
import NukaCarousel from 'nuka-carousel';
import { useElementSize } from '@kontentino/kontentino-utils/hooks';
import { StandardPost } from 'app/types/StandardPostPreview';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import LinkInfo from 'app/modules/insights/components/standardPostPreview/LinkInfo';
import { Icon } from '@kontentino/ui';
import AttachmentMedium from 'app/modules/insights/components/standardPostPreview/attachmentsCards/attachmentsCarousel/AttachmentMedium';
import clsx from 'clsx';
type Props = {
  attachments: StandardPost['attachments'];
  allowVideoPlayback?: boolean;
  withLinkInfo?: boolean;
};

const AttachmentsCarousel: FC<Props> = ({
  attachments: { cards },
  allowVideoPlayback,
  withLinkInfo = true,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const ref = useRef(null);
  const { height } = useElementSize(ref);

  return (
    <div className={style.attachmentsCarousel} ref={ref}>
      <NukaCarousel
        slideIndex={activeSlide}
        disableEdgeSwiping
        beforeSlide={(currentSlideIndex, endSlideIndex) =>
          setActiveSlide(endSlideIndex)
        }
        renderCenterLeftControls={(p) =>
          p.currentSlide !== 0 && (
            <div className={style.arrow} onClick={p.previousSlide}>
              <Icon icon={faChevronLeft} />
            </div>
          )
        }
        renderCenterRightControls={(p) =>
          p.currentSlide !== p.slideCount - 1 && (
            <div className={style.arrow} onClick={p.nextSlide}>
              <Icon icon={faChevronRight} />
            </div>
          )
        }
        renderBottomCenterControls={null}
      >
        {cards.map((card, i) => (
          <div
            style={{ height, width: '100%' }}
            className={style.carouselItem}
            key={`post-card-${i}`}
          >
            <AttachmentMedium
              medium={card.medium}
              allowVideoPlayback={allowVideoPlayback}
              height={height}
            />
            {withLinkInfo && (card.title || card.description) && (
              <LinkInfo link={card} className={style.carouselItemLinkInfo} />
            )}
          </div>
        ))}
      </NukaCarousel>
      {cards.length > 1 && (
        <div
          className={clsx(
            style.pagingDots,
            cards.some((card) => !!card.title || !!card.description) &&
              style.pagingDotsWithLinks,
          )}
        >
          {cards.map((_, index) => (
            <div
              key={index}
              className={clsx(style.dot, activeSlide === index && style.active)}
              onClick={() => setActiveSlide(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AttachmentsCarousel;
