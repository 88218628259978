import React, { FC } from 'react';
import { SelectNewProfileTypeProps } from './SelectNewProfileType';
import {
  Button,
  Icon,
  SocialMediaIcon,
  SocialMediaIconProps,
} from '@kontentino/ui';
import PageTypeUtils from 'app/utils/pageType';
import { faCircleEllipsis } from '@fortawesome/pro-regular-svg-icons/faCircleEllipsis';
export type NewManualProfileCardProps = {
  type: number | 'other';
  dataCy?: string;
  dataName?: string;
} & Pick<SelectNewProfileTypeProps, 'openManualForm'>;

const NewManualProfileCard: FC<NewManualProfileCardProps> = ({
  type,
  openManualForm,
  dataCy,
  dataName,
}) => {
  return (
    <div
      className="tw-flex tw-h-[44px] tw-items-center tw-justify-between tw-rounded-md tw-border tw-border-grayscale-20 tw-bg-white tw-px-3 tw-py-2"
      data-cy={dataCy}
      data-name={dataName}
    >
      <div className="tw-flex tw-items-center tw-space-x-2">
        {type === 'other' ? (
          <Icon
            icon={faCircleEllipsis}
            className="tw-text-grayscale-50"
            size="lg"
          />
        ) : (
          <SocialMediaIcon
            type={PageTypeUtils.getName(type) as SocialMediaIconProps['type']}
            variant="plain"
            size={20}
          />
        )}

        <span>{type === 'other' ? 'Other' : PageTypeUtils.getLabel(type)}</span>
      </div>
      <Button
        variant="tertiary"
        onClick={() => openManualForm?.(type === 'other' ? undefined : type)}
        size="small"
        className="tw-w-[86px]"
      >
        Create
      </Button>
    </div>
  );
};

export default NewManualProfileCard;
