import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import { colors } from '@kontentino/ui';
import InsightsPagePerformanceCurrentSelectionPDFReportRoute from 'app/modules/public/modules/pdfReports/modules/insightsPagePerformance/modules/InsightsPagePerformanceCurrentSelection';
import InsightsPagePerformanceOverviewPDFReportRoute from 'app/modules/public/modules/pdfReports/modules/insightsPagePerformance/modules/insightsPagePerformanceOverview';

export const publicPagePerformancePDFReportsRouter: RouteObject = {
  path: routeConfig.public.routes.pdfReports.routes.insightsPagePerformance
    .path,
  element: (
    <>
      <style>
        {`
          body {
            background-color: ${colors.white};
          }        
          @page {
            size: auto;
            margin: 1.3cm;
          }
        `}
      </style>
      <Outlet />
    </>
  ),
  children: [
    {
      path: routeConfig.public.routes.pdfReports.routes.insightsPagePerformance
        .routes.currentSelection.path,
      element: <InsightsPagePerformanceCurrentSelectionPDFReportRoute />,
    },
    {
      path: routeConfig.public.routes.pdfReports.routes.insightsPagePerformance
        .routes.overview.path,
      element: <InsightsPagePerformanceOverviewPDFReportRoute />,
    },
  ],
};
