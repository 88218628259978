import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { DemoContentApi } from 'app/modules/posts/api/demoContent';
import { PageThunk } from 'modules/page/pageActions';
import Logger from 'utils/logger';
import { DemoContentStatus } from 'types/DemoContent';
import { PageData, ProfileGroup } from 'types/Page';
import UserpilotUtils from 'app/utils/userpilot';

export function useDemoContentToggle(params?: {
  onToggle?: (data: { enabled: boolean; profiles: PageData[] }) => void;
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data } = useQuery(
    queryKey.demoContentIsEnabled(),
    DemoContentApi.getIsEnabled,
  );

  const toggle = useMutation(
    (variables: { enabled: boolean }) =>
      DemoContentApi.toggle({ enabled: variables.enabled }),
    {
      async onSettled(data, error, variables) {
        try {
          const res = await (dispatch(
            PageThunk.requestPages(),
          ) as unknown as Promise<
            | {
                profiles: PageData[];
                profileGroups: ProfileGroup[];
              }
            | undefined
          >);

          if (res && params?.onToggle) {
            params.onToggle({
              enabled: variables.enabled,
              profiles: res.profiles,
            });
          }

          UserpilotUtils.track(
            variables.enabled
              ? UserpilotUtils.events.DEMO_CONTENT_SWITCHED_ON
              : UserpilotUtils.events.DEMO_CONTENT_SWITCHED_OFF,
          );
        } catch (e) {
          Logger.error(e);
        }
        queryClient.invalidateQueries(queryKey.demoContentIsEnabled());
      },
      onMutate(variables) {
        queryClient.setQueryData<DemoContentStatus>(
          queryKey.demoContentIsEnabled(),
          { enabled: variables.enabled },
        );
      },
    },
  );

  return { toggle, isEnabled: !!data?.enabled };
}
