import React, { FC } from 'react';
import FileSourceSelect from 'app/components/fileSourceInput/fileSourceDropZone/FileSourceSelect';
import useDropZone from './DropZoneProvider';

const DropZoneFileSelect: FC = () => {
  const { fileSources, disabled } = useDropZone();
  return <FileSourceSelect fileSources={fileSources} disabled={disabled} />;
};

export default DropZoneFileSelect;
