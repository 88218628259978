import React, { FC } from 'react';
import DemoBadge from 'app/components/DemoBadge';
import ProfileSelectorOptionEditButton from 'app/components/profileSelector/components/profileSelectorOption/ProfileSelectorOptionEditButton';
import { Button, Icon, IconButton, Tooltip } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { stopPropagation } from 'utils/mouseEvent';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';

type Props = {
  canShowDemoBadge?: boolean;
  editButtonClassName?: string;
  isExpanded?: boolean;
  eventHandlers: {
    onEditClick?: () => void;
    onReconnectClick?: () => void;
    onExpandToggle?: () => void;
  };
  isOptionGroup?: boolean;
};

const ProfileSelectorOptionActions: FC<Props> = ({
  canShowDemoBadge,
  eventHandlers,
  editButtonClassName,
  isExpanded,
  isOptionGroup,
}) => {
  const { onEditClick, onExpandToggle, onReconnectClick } = eventHandlers;
  const { t } = useTranslation();

  if (
    !canShowDemoBadge &&
    !onEditClick &&
    !onReconnectClick &&
    !onExpandToggle
  ) {
    return null;
  }

  return (
    <div className="tw-flex tw-content-stretch tw-items-center tw-gap-2">
      {canShowDemoBadge && <DemoBadge />}
      {onEditClick && (
        <UserPermissionGate scopes={UserPermission.PAGE_EDIT}>
          <ProfileSelectorOptionEditButton
            onClick={onEditClick}
            className={editButtonClassName}
          />
        </UserPermissionGate>
      )}
      {onReconnectClick && (
        <UserPermissionGate scopes={UserPermission.PAGE_EDIT}>
          <Tooltip
            content={
              <div
                className="tw-text-center"
                dangerouslySetInnerHTML={{
                  __html: isOptionGroup
                    ? i18n.profileSelector.profileGroupsDisconnectTooltip
                    : i18n.profileSelector.profilesDisconnectTooltip,
                }}
              />
            }
          >
            <span>
              {!isOptionGroup && (
                <Button
                  variant={'danger'}
                  size={'small'}
                  onClick={stopPropagation(onReconnectClick)}
                  data-name="profile-selector_reconnect-profile-button"
                >
                  {t('reconnect')}
                </Button>
              )}

              {isOptionGroup && (
                <IconButton
                  className="tw-pointer-events-none"
                  variant={'danger'}
                  size="small"
                  icon={<Icon icon={faTriangleExclamation} />}
                />
              )}
            </span>
          </Tooltip>
        </UserPermissionGate>
      )}

      {onExpandToggle && (
        <Icon
          marginLeft={8}
          icon={isExpanded ? faChevronUp : faChevronDown}
          onClick={onExpandToggle}
        />
      )}
    </div>
  );
};

export default ProfileSelectorOptionActions;
