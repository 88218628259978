import React, { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Skeleton from 'react-loading-skeleton';
import { Outlet } from 'react-router';
import { BrandHubProvider } from 'app/modules/brandHub/components/BrandHubContext';

const BrandHub = React.lazy(
  () => import('app/modules/brandHub/components/BrandHub'),
);

export const brandHubRouter: RouteObject = {
  path: routeConfig.brandHub.path,
  element: (
    <BrandHubProvider>
      <Suspense
        fallback={
          <div className="tw-h-full tw-p-4">
            <Skeleton width="100%" height="100%" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </BrandHubProvider>
  ),
  children: [
    {
      index: true,
      element: <BrandHub />,
    },
  ],
};
