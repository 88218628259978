import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
import { PostDetail } from 'types/PostDetail';
export const changePostStatus = (context: ActionContext) =>
  createAction(
    {
      id: 'change-post-status',
      label: i18n.t('changePostStatus'),
      icon: <Icon icon={faCheckCircle} />,
      scopes: UserPermission.POST_MASS_CHANGE_POST_STATUS,
      perform: ({ id }: { id: PostDetail['id'] }) => {
        context.modalHistory.push({
          hash: modalRoutes.CHANGE_POSTS_STATUS,
          search: `?posts=${id}`,
        });
      },
    },
    context,
  );
