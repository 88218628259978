import React, { createContext, useContext } from 'react';
import { FileSource } from '../types/fileSource';

type Props = {
  fileSources: [FileSource, ...FileSource[]];
  isDragActive: boolean;
  disabled?: boolean;
  specifications: {
    accept?: string;
    multiple?: boolean;
    pageType?: number;
  };
} & React.HTMLAttributes<HTMLDivElement>;

const DropZoneContext = createContext<Props | undefined>(undefined);

const DropZoneProvider: React.FC<Props> = ({ children, ...props }) => {
  return (
    <DropZoneContext.Provider value={{ ...props }}>
      {children}
    </DropZoneContext.Provider>
  );
};

const useDropZone = () => {
  const dropZoneProps = useContext(DropZoneContext);

  if (!dropZoneProps) {
    throw new Error('useDropZone must be used within a DropZoneProvider');
  }

  return dropZoneProps;
};

export default useDropZone;

export { DropZoneContext, DropZoneProvider };
