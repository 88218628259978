import React, { FC } from 'react';
import { LabelList } from 'recharts';
import { formatCompactValue } from 'app/modules/insights/modules/pagePerformance/utils/format';

type Props = {
  dataKey?: string;
};

const GraphContentHorizontalBarValueLabel: FC<Props> = ({ dataKey }) => {
  return (
    <LabelList
      dataKey={dataKey}
      content={({ x, y, value, width, height }) => {
        if (
          typeof x === 'number' &&
          typeof y === 'number' &&
          typeof width === 'number' &&
          typeof height === 'number' &&
          value
        ) {
          return (
            <text
              x={Math.round(x + width + 8)}
              y={Math.round(y + height)}
              className="tw-text-sm tw-font-medium tw-text-grayscale-180"
              textAnchor="start"
            >
              {formatCompactValue(value)}
            </text>
          );
        }

        return null;
      }}
    />
  );
};

export default GraphContentHorizontalBarValueLabel;
