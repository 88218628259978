import React, { FC, ReactNode } from 'react';
import { Button, Icon, IconButton, mergeTailwindClasses } from '@kontentino/ui';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faClose } from '@fortawesome/pro-regular-svg-icons/faClose';
import {
  ModalContextValue,
  useModalContext,
} from 'components/shared/modal/ModalProvider';
import clsx from 'clsx';
type Props = {
  children?: ReactNode | ((context: ModalContextValue) => ReactNode);
  onGoBack?: () => void;
  title?: ReactNode;
  subtitle?: string;
  withDivider?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'title'>;

const ModalHeader: FC<Props> = ({
  children,
  title,
  subtitle,
  onGoBack,
  className,
  withDivider = false,
  ...rest
}) => {
  const modalContext = useModalContext();

  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-relative tw-p-6',
          {
            'tw-sticky tw-top-0 tw-z-10 tw-bg-white':
              modalContext.isContentHeightAutoResizable,
            'tw-border-b tw-border-b-grayscale-10': withDivider,
          },
          className,
        ),
      )}
      {...rest}
    >
      {onGoBack && (
        <div className="tw-mb-4">
          <Button
            variant="plain"
            onClick={onGoBack}
            className="-tw-ml-3"
            data-name="modal_header_go-back"
          >
            <Icon icon={faChevronLeft} />
            Back
          </Button>
        </div>
      )}
      {title && (
        <div
          className={clsx(
            'tw-flex tw-items-center tw-pr-4 tw-text-lg tw-font-semibold tw-text-grayscale-180',
          )}
        >
          {title}
        </div>
      )}
      {subtitle && (
        <div className="tw-mt-1 tw-pr-4 tw-text-md tw-text-grayscale-140">
          {subtitle}
        </div>
      )}
      {children && (
        <div className="tw-mt-4">
          {typeof children === 'function' ? children(modalContext) : children}
        </div>
      )}
      <IconButton
        size="small"
        variant="plain"
        onClick={modalContext.onClose}
        className="tw-absolute tw-right-3 tw-top-3"
        icon={<Icon icon={faClose} size="lg" />}
        data-name={modalContext.dataName?.closeButton}
        data-cy={modalContext.dataCy?.closeButton}
      />
    </div>
  );
};

export default ModalHeader;
