import React, { ChangeEvent, FC } from 'react';
import { Field, Icon, IconButton, TextInput } from '@kontentino/ui';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import DateUtils from 'app/utils/date';

type Props = Pick<
  ReactDatePickerCustomHeaderProps,
  'decreaseMonth' | 'increaseMonth' | 'date'
> & {
  error: {
    startDate: boolean;
    endDate: boolean;
  };
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  onStartDateChange: (date: Dayjs | null) => void;
  onEndDateChange: (date: Dayjs | null) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
};

const DatePickerHeaderCustomPeriod: FC<Props> = ({
  decreaseMonth,
  increaseMonth,
  date,
  error,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  minDate,
  maxDate,
}) => {
  const { t } = useTranslation();

  const year = date.getFullYear();

  const isNextMonthDisabled = maxDate
    ? dayjs(date).add(1, 'month').isAfter(maxDate.endOf('month'))
    : false;

  const isPreviousMonthDisabled = minDate
    ? dayjs(date).subtract(1, 'month').isBefore(minDate.startOf('month'))
    : false;

  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      if (dayjs(new Date(e.target.value)).isBefore(dayjs(startDate))) {
        onStartDateChange?.(dayjs(e.target.value));
        onEndDateChange?.(null);
      } else {
        onEndDateChange?.(dayjs(e.target.value));
      }
    } else {
      onEndDateChange?.(null);
    }
  };

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      if (dayjs(dayjs(e.target.value)).isAfter(dayjs(endDate))) {
        onStartDateChange?.(dayjs(e.target.value));
        onEndDateChange?.(null);
      } else {
        onStartDateChange?.(dayjs(e.target.value));
      }
    } else {
      onStartDateChange?.(null);
      onEndDateChange?.(null);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-y-4">
      <Field.Group>
        <Field.Label required>{t('start')}</Field.Label>
        <TextInput
          onChange={handleStartDateChange}
          type="date"
          value={DateUtils.toDateString(dayjs(startDate))}
          max={DateUtils.toDateString(dayjs(maxDate))}
          min={minDate ? DateUtils.toDateString(dayjs(minDate)) : undefined}
          error={!error.startDate}
        />
        <Field.Error>{!error.startDate && t('invalidDate')}</Field.Error>
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('end')}</Field.Label>
        <TextInput
          disabled={!startDate}
          type="date"
          onChange={handleEndDateChange}
          value={DateUtils.toDateString(dayjs(endDate))}
          max={maxDate ? DateUtils.toDateString(dayjs(maxDate)) : undefined}
          min={
            startDate && !endDate
              ? DateUtils.toDateString(dayjs(startDate))
              : minDate
              ? DateUtils.toDateString(dayjs(minDate))
              : undefined
          }
          error={!error.endDate}
        />
        <Field.Error>{!error.endDate && t('invalidDate')}</Field.Error>
      </Field.Group>
      <div className="tw-flex tw-items-center tw-justify-between">
        <span className="tw-text-md tw-font-medium">
          {dayjs(date).format('MMMM')} {year}
        </span>
        <div className="tw-flex tw-gap-x-1">
          <IconButton
            onClick={decreaseMonth}
            variant="light"
            icon={<Icon size={'md'} icon={faChevronLeft} />}
            size={'small'}
            disabled={isPreviousMonthDisabled}
          />
          <IconButton
            onClick={increaseMonth}
            variant="light"
            icon={<Icon size={'md'} icon={faChevronRight} />}
            size={'small'}
            disabled={isNextMonthDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default DatePickerHeaderCustomPeriod;
