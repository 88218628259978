import Logger from 'utils/logger';

export const BrowserStorage = {
  set(key: string, value: string) {
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      Logger.error(e);
    }
  },
  get(key: string): string | null {
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      Logger.error(e);
    }
    return null;
  },
  remove(key: string) {
    try {
      return window.localStorage.removeItem(key);
    } catch (e) {
      Logger.error(e);
    }
  },
  logoutClear() {
    BrowserStorage.remove(BrowserStorage.keys.AuthToken);
    BrowserStorage.remove(BrowserStorage.keys.Stores.UnsavedValues);
  },
  keys: {
    PaymentInfoBannerStatus: 'info-banner-last-payment-status',
    ExpiredReloadDate: 'expiredReloaded',
    Insights: {
      InsightsFilters: 'insights-filters',
      InsightsGraphsConfig: 'insights-graphs-config',
      InsightsPageFansActiveTab: 'insights-page-fans-active-tab',
      InsightsPagePostReachActiveTab:
        'insights-page-post-cumulative-reach-active-tab',
    },
    AuthToken: 'auth-token',
    PostsGridPreviewVariant: 'posts-grid-preview-variant',
    Stores: {
      AppLayout: 'app.layout',
      UnsavedValues: 'app.unsaved-values',
      PostsLayout: 'posts.layout',
      OutputLanguageSelect: 'output-language-select',
    },
    Posts: {
      PreviewVariant: 'posts.post-preview-variant',
    },
    InsightsLite: {
      InvitationDismissedAt: 'insights-lite-invitation-dismissed-at',
    },
  },
};
