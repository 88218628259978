import { FC } from 'react';
import { UserPermission } from 'constants/userPermission';
import { useNavigate } from 'react-router-dom';
import UserPermissionGate from 'components/UserPermissionGate';
import { Button, ButtonProps, Icon } from '@kontentino/ui';
import { modalRoutes } from 'constants/routes';
import { isMacintosh } from 'utils/helpers';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { Trans, useTranslation } from 'react-i18next';
import { ActionButton } from 'app/components/actions/ActionButton';
import { useActions } from 'app/hooks/useActions';
const BUTTON_PROPS: ButtonProps = {
  variant: 'tertiary',
  size: 'small',
  style: { width: '100%' },
  iconBefore: <Icon icon={faPlus} />,
};

const PostsProfileSelectorFooter: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { profileActions } = useActions('profile-selector');

  return (
    <div className="tw-border-t tw-border-grayscale-20">
      <p className="tw-py-2 tw-text-center tw-text-[14px] tw-text-grayscale-100 [&>strong]:tw-text-grayscale-160">
        <Trans
          i18nKey="profileSelector.multipleSelectInfo"
          values={{
            command: isMacintosh() ? 'CMD ⌘' : 'CTRL',
          }}
        />
      </p>
      <UserPermissionGate
        exact={false}
        scopes={[
          UserPermission.PAGE_CREATE,
          UserPermission.PAGE_PRESETS_MANAGE,
        ]}
      >
        <div className="tw-flex tw-gap-4 tw-border-t tw-border-grayscale-20 tw-p-4">
          <ActionButton
            {...BUTTON_PROPS}
            action={profileActions.add}
            data-name="posts-profile-select-new-social-profile"
            data-cy="page-selector-new-social-media-profile-button"
          />
          <UserPermissionGate scopes={UserPermission.PAGE_PRESETS_MANAGE}>
            <Button
              {...BUTTON_PROPS}
              onClick={() =>
                navigate({ hash: modalRoutes.MANAGE_PROFILE_GROUP })
              }
              data-cy="page-selector-new-profile-group-button"
              data-name="posts-profile-select-new-profile-group"
            >
              {t('createGroup')}
            </Button>
          </UserPermissionGate>
        </div>
      </UserPermissionGate>
    </div>
  );
};

export default PostsProfileSelectorFooter;
