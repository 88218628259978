import React, { forwardRef } from 'react';
import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';
import { FileSource } from 'app/components/fileSourceInput/types/fileSource';
import DropZoneActiveDragOverlay from './DropZoneActiveDragOverlay';
import DropZoneSpecifications from './DropZoneSpecifications';
import DropZoneHelperText from './DropZoneHelperText';
import DropZoneFileSelect from './DropZoneFileSelect';
import DropZoneFilePicker from './DropZoneFilePicker';
import { DropZoneProvider } from './DropZoneProvider';

type Props = {
  fileSources: [FileSource, ...FileSource[]];
  isDragActive: boolean;
  disabled?: boolean;
  specifications: {
    accept?: string;
    multiple?: boolean;
    pageType?: number;
  };
  removeDefaultClassName?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

interface CompoundedDropZoneComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
  > {
  DropZoneHelperText: typeof DropZoneHelperText;
  DropZoneFileSelect: typeof DropZoneFileSelect;
  DropZoneFilePicker: typeof DropZoneFilePicker;
  DropZoneSpecifications: typeof DropZoneSpecifications;
  DropZoneActiveDragOverlay: typeof DropZoneActiveDragOverlay;
}

const DropZone = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    isDragActive,
    fileSources,
    className,
    specifications,
    disabled,
    removeDefaultClassName,
    children,
    ...rest
  } = props;

  return (
    <div
      {...rest}
      className={mergeTailwindClasses(
        clsx(
          'tw-relative',
          {
            'tw-pointer-events-none tw-cursor-not-allowed tw-border-grayscale-30 tw-bg-grayscale-5':
              disabled,
          },
          {
            'tw-flex tw-w-full tw-flex-col tw-items-center tw-rounded tw-border tw-border-dashed tw-border-primary-100 tw-bg-white tw-p-6 tw-transition-colors hover:tw-cursor-pointer hover:tw-bg-primary-10':
              !removeDefaultClassName,
          },
          className,
        ),
      )}
      ref={ref}
      data-name="file-source_dropzone"
    >
      <DropZoneProvider {...props}>{children}</DropZoneProvider>
    </div>
  );
}) as CompoundedDropZoneComponent;

DropZone.DropZoneHelperText = DropZoneHelperText;
DropZone.DropZoneFileSelect = DropZoneFileSelect;
DropZone.DropZoneFilePicker = DropZoneFilePicker;
DropZone.DropZoneSpecifications = DropZoneSpecifications;
DropZone.DropZoneActiveDragOverlay = DropZoneActiveDragOverlay;

export default DropZone;
