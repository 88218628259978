import React, { FC } from 'react';
import { Icon, IconProps } from '@kontentino/ui';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons/faCalendarStar';
import { faCircleDot } from '@fortawesome/pro-regular-svg-icons/faCircleDot';
import { faClapperboardPlay } from '@fortawesome/pro-regular-svg-icons/faClapperboardPlay';
import { faGalleryThumbnails } from '@fortawesome/pro-regular-svg-icons/faGalleryThumbnails';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faLinkSimple } from '@fortawesome/pro-regular-svg-icons/faLinkSimple';
import { faPercentage } from '@fortawesome/pro-regular-svg-icons/faPercentage';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faText } from '@fortawesome/pro-regular-svg-icons/faText';
import { faVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import { PseudoPostType } from 'constants/post';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
type Props = {
  type: PseudoPostType;
} & Omit<IconProps, 'icon'>;

const ICON_BY_PSEUDO_TYPE: {
  [x: string]: IconDefinition;
} = {
  [PseudoPostType.Link]: faLinkSimple,
  [PseudoPostType.Photo]: faImage,
  [PseudoPostType.Gallery]: faImages,
  [PseudoPostType.Video]: faVideo,
  [PseudoPostType.Status]: faText,
  [PseudoPostType.Story]: faCircleDot,
  [PseudoPostType.Reel]: faClapperboardPlay,
  [PseudoPostType.Event]: faCalendarStar,
  [PseudoPostType.Offer]: faPercentage,
  [PseudoPostType.Carousel]: faGalleryThumbnails,
};

const PostTypeIcon: FC<Props> = ({ type, ...props }) => {
  const icon = ICON_BY_PSEUDO_TYPE[type] || faSquare;

  return (
    <Icon icon={icon} className="tw-text-grayscale-100" size="md" {...props} />
  );
};

export default PostTypeIcon;
