import { NavigationItemKey } from 'components/navigation/utils/libs';
import { isUSDateTimeFormat } from '@kontentino/kontentino-utils/date';
import { faCircleDollar } from '@fortawesome/pro-regular-svg-icons/faCircleDollar';
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons/faGrid2';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faImagePolaroidUser } from '@fortawesome/pro-regular-svg-icons/faImagePolaroidUser';
import { faObjectsColumn } from '@fortawesome/pro-regular-svg-icons/faObjectsColumn';

type SharedBy = {
  name: string;
  email: string;
  accountUsername: string;
};

export const PublicUtils = {
  publicMock: {
    accountPlanType: 'trial',
    user: {
      id: 0,
      name: 'Guest',
      role: 'admin',
      avatar: null,
      userMeridianTime: !isUSDateTimeFormat(),
    } as const,
    menuItems: [
      {
        key: NavigationItemKey.Dashboard,
        label: 'Dashboard',
        icon: faObjectsColumn,
      },
      {
        key: NavigationItemKey.Posts,
        label: 'Posts',
        icon: faImagePolaroidUser,
      },
      {
        featureTitle: 'Ads planner',
        key: NavigationItemKey.AdsPlanner,
        label: 'Ads planner',
        icon: faCircleDollar,
      },
      {
        featureTitle: 'Analytics & Reporting:',
        key: NavigationItemKey.Insights,
        label: 'Insights',
        icon: faGrid2,
      },
      {
        key: NavigationItemKey.Albums,
        label: 'Media Library',
        icon: faImage,
      },
    ],
  },
  setSharedData: (user: SharedBy) => {
    PublicUtils.sharedBy = user;
  },
  getSharedData: () => PublicUtils.sharedBy,
  sharedBy: {
    name: '',
    email: '',
    accountUsername: '',
  } as SharedBy,
};

export default PublicUtils;
