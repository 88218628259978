import config from 'config';
import { get, post } from 'api/client';
import { getExtendedTimeout } from 'utils/request';

export type TextTransformAction =
  | 'translate'
  | 'shorten'
  | 'expand'
  | 'fix-grammar'
  | 'rewrite';

export const GenerationsApi = {
  transformText(data: {
    source: string;
    planType: string;
    action: TextTransformAction;
    targetLanguage?: string;
  }): Promise<{ text: string }> {
    return post(`/generations/text/${data.action}`, data, {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
      timeout: getExtendedTimeout(3),
    });
  },
  getTransformTextOptions(): Promise<{
    options: {
      actions: TextTransformAction[];
      language: {
        value: string;
        label: string;
      }[];
    };
  }> {
    return get('/generations/text/translate', {
      baseURL: config.BRAND_HUB_SERVICE_ENDPOINT,
    });
  },
};
