import { FC } from 'react';
import style from 'components/insights/infoCard/header/Header.module.scss';
import { GraphType } from 'constants/analytics';
import { Icon } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import clsx from 'clsx';
type Props = {
  title: string;
  isExpanded: boolean;
  onExpandToggle: AnyFunction;
  availableGraphTypes?: GraphType[];
  activeGraphType?: GraphType;
  onChangeGraphType?: (type: GraphType) => void;
  isOnExport?: boolean;
};

const Header: FC<Props> = (props) => {
  return (
    <div className={style.header} onClick={props.onExpandToggle}>
      <span className={style.title}>{props.title}</span>
      <div className={style.actions}>
        {!props.isOnExport && (
          <Icon
            icon={faChevronDown}
            className={clsx(style.toggler, props.isExpanded && style.inverted)}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
