import React, { FC } from 'react';
import { Legend, LegendProps } from 'recharts';

type Props = {
  legendLabels: {
    [labelKey: string]: string;
  };
  isCombinedMetric?: boolean;
  isCompared?: boolean;
} & LegendProps;

const GraphLegendContent: FC<Props> = ({
  payload,
  isCompared,
  isCombinedMetric,
  legendLabels,
}) => {
  const data = isCombinedMetric && isCompared ? payload?.slice(0, 2) : payload;

  return (
    <div className="tw-flex tw-gap-4 tw-pb-4">
      {data?.map((item, index) => {
        const legendValue = legendLabels[item.value];

        if (!legendValue) return null;

        return (
          <div
            key={`graph-legend-${index}`}
            className="tw-flex tw-items-center"
          >
            <div
              className="tw-h-4 tw-w-4 tw-rounded-sm"
              style={{ backgroundColor: item.color }}
            />
            <div className="tw-pl-2 tw-text-sm tw-font-regular tw-capitalize tw-text-grayscale-90">
              {legendValue}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const GraphLegend: FC<Props> = ({
  legendLabels,
  isCombinedMetric,
  isCompared,
}) => {
  return (
    <Legend
      content={
        <GraphLegendContent
          legendLabels={legendLabels}
          isCombinedMetric={isCombinedMetric}
          isCompared={isCompared}
        />
      }
      verticalAlign="top"
      wrapperStyle={{
        top: 0,
        left: 0,
      }}
    />
  );
};

export default GraphLegend;
