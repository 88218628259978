import Logger from 'utils/logger';
import { Userpilot } from 'userpilot';
import config from 'config';
import { isDevelopmentMode } from 'utils/helpers';
import { IntercomUser } from 'types/User';

const UserpilotUtils = {
  init() {
    try {
      if (this.shouldSkip()) {
        Logger.log('Skipping userpilot initialization');
        return;
      }

      Userpilot.initialize(config.USERPILOT_APP_ID);
    } catch (e) {
      Logger.error(e);
    }
  },
  identify(user: IntercomUser & { id: number; accountPublicId: string }) {
    try {
      const { app_id, user_hash, accountPublicId, id, ...rest } = user;

      Userpilot.identify(`${accountPublicId}-${id}`, {
        ...rest,
        companySignedUp: new Date(
          (user.company?.remote_created_at ?? 0) * 1000,
        ).toISOString(),
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  reload() {
    try {
      if (this.shouldSkip()) {
        return;
      }

      Userpilot.reload();
    } catch (e) {
      Logger.error(e);
    }
  },
  track(eventName: string) {
    try {
      if (this.shouldSkip() && config.USERPILOT_DEBUG_ON) {
        Logger.info('Track userpilot event: %s', eventName);
        return;
      }

      Userpilot.track(eventName);
    } catch (e) {
      Logger.error(e);
    }
  },
  events: {
    AD_ACCOUNT_CONNECTED: 'ad account connected',
    AD_CREATED: 'ad created',
    AD_UPDATED: 'ad updated',
    AD_EXPORTED: 'ad exported',
    AD_SENT_FOR_INTERNAL_APPROVAL: 'ad sent for internal approval',
    AD_SENT_FOR_EXTERNAL_APPROVAL: 'ad sent for external approval',
    CAMPAIGN_CREATED: 'campaign created',
    CAMPAIGN_UPDATED: 'campaign updated',
    AD_SET_CREATED: 'ad set created',
    AD_SET_UPDATED: 'ad set updated',
    IMPORTED_CONTENT_FROM_FB_BM: 'imported content from FB business manager',
    POST_CREATED: 'post created',
    POST_UPDATED: 'post updated',
    POST_SCHEDULED: 'post scheduled',
    POST_SENT_FOR_INTERNAL_APPROVAL: 'post sent for internal approval',
    POST_SENT_FOR_EXTERNAL_APPROVAL: 'post sent for external approval',
    ASSIGNED_TASK: 'assigned task',
    SUBMITTED_INTERNAL_COMMENT: 'submitted internal comment',
    SUBMITTED_CLIENT_COMMENT: 'submitted client comment',
    POST_CREATED_WITHOUT_DATE: 'post created without date',
    SOCIAL_MEDIA_PROFILE_CONNECTED: 'social media profile connected',
    SOCIAL_MEDIA_PROFILES_CONNECTED: 'social media profiles connected',
    SOCIAL_MEDIA_PROFILE_MANUALLY_ADDED: 'social media profile manually added',
    POSTS_WELCOME_MODAL_CLOSED: 'posts welcome modal closed',
    LABEL_CREATED: 'label created',
    POSTS_INSPIRATION_CREATED: 'posts inspiration created',
    POSTS_POST_DUPLICATED: 'posts post duplicated',
    POSTS_POST_MOVED: 'posts post moved',
    POST_REQUIREMENT_TOGGLED: 'post requirement toggled',
    POSTS_POST_SELECTED: 'posts post selected',
    DEMO_CONTENT_SWITCHED_ON: 'demo content switched on',
    DEMO_CONTENT_SWITCHED_OFF: 'demo content switched off',
    POSTS_SEARCH_FIELD_FOCUSED: 'post filter search field focused',
    POST_NOTE_FIELD_FOCUSED: 'post field note focused',
    USER_SELECTED_LABEL_FROM_SUGGESTIONS:
      'user selected label from suggestions',
    POSTS_TIME_SAVERS_DUPLICATE: 'posts time savers duplicate posts',
    POSTS_TIME_SAVERS_MOVE: 'posts time savers move posts',
    POSTS_TIME_SAVERS_CHANGE_STATUS: 'posts time savers change posts status',
    POSTS_TIME_SAVERS_ASSIGN: 'posts time savers assign multiple tasks',
    POSTS_TIME_SAVERS_CLIENT_APPROVAL:
      'posts time savers send for client approval',
    POSTS_TIME_SAVERS_INTERNAL_APPROVAL:
      'posts time savers send for internal approval',
    POSTS_TIME_SAVERS_SCHEDULE: 'posts time savers schedule posts',
  },
  shouldSkip() {
    return isDevelopmentMode() || !config.USERPILOT_APP_ID;
  },
};

export default UserpilotUtils;
