import React, { ReactNode } from 'react';
import { Button, Icon, mergeTailwindClasses, Tooltip } from '@kontentino/ui';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import useCopyToClipboard from 'app/hooks/useCopyToClipboard';
import HeapUtils from 'app/utils/heap';
import EmptyGeneratedResults from 'app/modules/aiContent/components/results/EmptyGeneratedResults';
import LoadingGeneratedResults from './LoadingGeneratedResults';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { runIfFn } from 'app/utils/run-if-fn';
type Props = {
  suggestions: { text: string }[];
  isLoading: boolean;
  renderAction?: (text: string) => ReactNode;
  classNames?: {
    root?: string;
    loadingImg?: string;
    emptyImg?: string;
    resultText?: string;
  };
};

const TextGeneratedResults: React.FC<Props> = ({
  suggestions,
  isLoading,
  renderAction,
  classNames,
}) => {
  const { t } = useTranslation();
  const copyValueToClipboard = useCopyToClipboard();
  const isEmpty = suggestions.length === 0;

  if (isLoading) {
    return (
      <LoadingGeneratedResults
        title={`${t('generatingYourText')}...`}
        classNames={{
          img: classNames?.loadingImg,
        }}
      />
    );
  }

  if (isEmpty) {
    return (
      <EmptyGeneratedResults
        title={t('noTextGenerated')}
        classNames={{
          img: classNames?.emptyImg,
        }}
      />
    );
  }

  return (
    <div
      className={clsx('tw-flex tw-flex-col tw-gap-4 tw-p-4', classNames?.root)}
    >
      {suggestions.map((suggestion, index) => (
        <div
          key={`suggestion-${index}`}
          className="tw-rounded-lg tw-border tw-border-grayscale-20 tw-bg-white tw-p-4"
        >
          <div className="tw-grayscale-180 tw-mb-3 tw-flex tw-items-center tw-text-md tw-font-medium">
            <div className="tw-mr-auto tw-capitalize">
              {t('result')} {suggestions.length > 1 ? index + 1 : ''}
            </div>
            {runIfFn(renderAction, suggestion.text)}
            <Tooltip content={t('copyToClipboard')}>
              <Button
                onClick={() => copyValueToClipboard(suggestion.text)}
                data-name={`ai-content_copy-text`}
                variant="plain"
                size="small"
                className="tw-mr-3"
                iconBefore={
                  <Icon icon={faCopy} className="tw-text-grayscale-100" />
                }
              >
                <span className="text-sm">{t('copy')}</span>
              </Button>
            </Tooltip>
          </div>
          <div
            className={mergeTailwindClasses(
              clsx(
                'tw-whitespace-pre-wrap tw-break-words tw-text-md tw-text-grayscale-180',
                classNames?.resultText,
              ),
            )}
            onCopy={() => {
              HeapUtils.track(
                HeapUtils.events.AI_CONTENT_COPY_TEXT_TO_CLIPBOARD,
              );
            }}
          >
            {suggestion.text}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TextGeneratedResults;
