import { FC } from 'react';
import Modal from 'components/shared/Modal';
import { Button, Field, TextArea } from '@kontentino/ui';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import { ApiClientError } from 'api/client';
import { useTranslation } from 'react-i18next';
import { useToast } from 'app/hooks/useToast';
import AIGenerateButton from 'app/modules/aiContent/components/AIGenerateButton';
import { queryKey } from 'constants/queryKey';
import { BrandProfile } from 'app/modules/brandHub/types';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import BrandHubSuggestProfileModalWebLinkField from 'app/modules/brandHub/components/modals/BrandHubSuggestProfileModal/BrandHubSuggestProfileModalAppendLink';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBrandProfileSuggested: (brandProfile: BrandProfile) => void;
};

const schema = z
  .object({
    postsText: z.string().optional(),
    webCrawl: z
      .object({
        url: z.string(),
        title: z.string(),
        description: z.string().optional(),
        image: z.string().optional(),
        logo: z.string().optional(),
        keywords: z.string().optional(),
        h1: z.array(z.string()),
        h2: z.array(z.string()),
        h3: z.array(z.string()),
        paragraphs: z.array(z.string()),
        socialTags: z.array(
          z.object({
            property: z.string(),
            content: z.string().optional(),
          }),
        ),
        bodyText: z.string(),
      })
      .optional(),
  })
  .partial()
  .superRefine((data, ctx) => {
    if (!data.postsText && !data.webCrawl) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['webCrawl'],
        message: 'Ensure that at least one field is set',
      });
    }
  });

type FormValues = z.infer<typeof schema>;

const BrandHubSuggestProfileModal: FC<Props> = ({
  isOpen,
  onClose,
  onBrandProfileSuggested,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { accountPlanType } = useSubscriptionInfo();
  const queryClient = useQueryClient();
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      postsText: '',
      webCrawl: undefined,
    },
  });
  const mutation = useMutation(BrandHubApi.suggestBrandProfile, {
    onSuccess: (brandProfile) => {
      onClose();
      onBrandProfileSuggested(brandProfile);
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey.aiContentOptions());
    },
  });

  function onSubmit({ postsText, webCrawl }: FormValues) {
    mutation.mutate({
      postsText,
      bodyText: webCrawl?.bodyText,
      planType: accountPlanType,
      logo: webCrawl?.logo,
    });
  }

  return (
    <Modal
      dataName={{
        closeButton: 'brand-hub_suggest-profile_close',
        wrapper: 'brand-hub_suggest-profile_modal',
      }}
      dataCy={{
        closeButton: 'brand-hub_suggest-profile_close',
        wrapper: 'brand-hub_suggest-profile_modal',
      }}
      open={isOpen}
      onClose={onClose}
      isLoading={mutation.isLoading}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Modal.Header
          title={t('generateBrandProfileTitle')}
          subtitle={t('generateFromMyPreviousPostsDescription')}
        />
        <Modal.Content className="tw-space-y-4 tw-pb-4">
          <Field.Group>
            <Field.Label required>{t('webLink')}</Field.Label>
            <Controller
              control={form.control}
              name="webCrawl"
              render={({ field }) => (
                <BrandHubSuggestProfileModalWebLinkField
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {!!form.formState.errors.webCrawl?.message && (
              <Field.Error>
                <>{form.formState.errors.webCrawl?.message}</>
              </Field.Error>
            )}
          </Field.Group>
          <Field.Group>
            <Field.Label required>{t('postExamples')}</Field.Label>
            <TextArea
              {...form.register('postsText')}
              data-name="brand-hub_suggest-profile_posts-text"
              placeholder={t('postExamplesPlaceholder')}
              rows={15}
            />
            {!!form.formState.errors.postsText?.message && (
              <Field.Error>
                {form.formState.errors.postsText?.message}
              </Field.Error>
            )}
          </Field.Group>
        </Modal.Content>
        <Modal.Footer withDivider>
          <Button
            data-name="brand-hub_suggest-profile_close"
            onClick={onClose}
            variant="secondary"
          >
            {t('close')}
          </Button>
          <AIGenerateButton
            type="button"
            data-name="brand-hub_suggest-profile_generate"
            onClick={form.handleSubmit(onSubmit)}
            isLoading={mutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BrandHubSuggestProfileModal;
