import { AvatarGroup, mergeTailwindClasses } from '@kontentino/ui';
import React, { FC, useMemo } from 'react';
import WorkflowStatusIcon from './WorkflowStatusIcon';
import { WorkflowStatusUtils } from 'app/utils/workflowStatus';
import clsx from 'clsx';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';
import { ListPost } from 'types/PostDetail';

type Props = {
  status: number;
  isDisabled?: boolean;
  className?: string;
  assignedUsers: ListPost['assigned_users'];
};

const WorkflowStatusItem: FC<Props> = ({
  status,
  className,
  isDisabled,
  assignedUsers,
}) => {
  const { postStatusVariant } = usePostsLayoutState();

  return useMemo(() => {
    return (
      <div
        className={mergeTailwindClasses(
          'tw-inline-flex tw-w-full tw-gap-1 tw-overflow-hidden',
          className,
        )}
      >
        <WorkflowStatusIcon status={status} variant="regular" />
        <span
          className={mergeTailwindClasses(
            clsx(
              `tw-text-status-${postStatusVariant}-${status}-primary tw-truncate tw-text-sm tw-font-medium`,
              { 'tw-text-grayscale-50': isDisabled },
            ),
          )}
          data-cy="posts_calendar_post-card_status"
        >
          {WorkflowStatusUtils.getLabel(status)}
        </span>
        {!!assignedUsers.length && (
          <AvatarGroup
            avatars={assignedUsers.map((user) => ({
              ...user,
              src: user.avatar?.src,
            }))}
            limit={2}
            avatarSize={16}
          />
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postStatusVariant, status, assignedUsers, isDisabled]);
};

export default WorkflowStatusItem;
