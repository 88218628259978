import {
  BrandHubQueryKey,
  ContentPlanQueryKey,
  DashboardQueryKey,
  PostsQueryKey,
  QueryKey,
} from 'app/utils/queryKey';
import {
  PostsRequestParams,
  TasksRequestParams,
} from 'app/modules/dashboard/api';
import {
  GetPostsMetaParams,
  GetPostsParams,
  GetPostTextInfoParams,
} from 'app/modules/posts/api/posts';
import { WidgetsSetupTarget } from 'app/modules/posts/api/widgetsSetup';
import { AudienceRestrictionField } from 'app/modules/posts/modules/detail/modules/audienceRestrictions/constants/audienceRestrictions';

export const queryKey = {
  postDetail: (id: number) => PostsQueryKey('postDetail', [id]),
  postPreview: (id: number) => PostsQueryKey('postPreview', [id]),
  postRevisions: (params?: { postId: number }) =>
    PostsQueryKey('postVersions', params),
  postRevisionDetail: (params: { postId: number; revisionId: number }) =>
    PostsQueryKey('postRevisionDetail', params),
  aiContentOptions: () => PostsQueryKey('aiContentOptions'),
  posts: (params?: GetPostsParams) => PostsQueryKey('posts', params),
  postsMeta: (params?: GetPostsMetaParams) =>
    PostsQueryKey('postsMeta', params),
  postComposerDetail: 'postComposerDetail',
  postToDelete: (id?: number | null) => PostsQueryKey('postToDelete', [id]),
  postScheduleValidation: (id: number) =>
    PostsQueryKey('postScheduleValidation', [id]),
  approvalData: (ids: number[], type: string) =>
    PostsQueryKey('approvalData', [...ids, type]),
  pageDetail: (id?: number) => PostsQueryKey('pageDetail', [id]),
  pageTimezone: (id?: number) => PostsQueryKey('pageTimezone', [id]),
  demoContentIsEnabled: () => PostsQueryKey('demoContentIsEnabled'),
  pageTags: (pageId?: number | null) => PostsQueryKey('pageTags', pageId),
  recentTags: (pageId?: number | null) => PostsQueryKey('recentTags', pageId),
  postTextInfo: (params: GetPostTextInfoParams) =>
    PostsQueryKey('postTextInfo', params),
  widgetsSetup: (params: { target: WidgetsSetupTarget }) =>
    PostsQueryKey('widgetsSetup', params),
  multipleScheduleValidations: 'multipleScheduleValidations',
  allMediaAlbums: 'allMediaAlbums',
  albumMedia: (albumId?: number) => PostsQueryKey('albumMedia', albumId),
  videoMedium: 'videoMedium',
  linkDomainVerification: (input: { pageId: number; url: string }) =>
    PostsQueryKey('linkDomainVerification', input),
  /**
   * @deprecated deprecated use postCommentsThread instead
   */
  commentsForPost: (id: number) => PostsQueryKey('commentsForPost', [id]),
  postCommentsThread: (params?: { postId: number; thread: string }) =>
    PostsQueryKey('postCommentsThread', params),
  postCommentsPinnedThread: (params?: { postId: number; thread: string }) =>
    PostsQueryKey('postCommentsPinnedThread', params),
  postCommentsSummary: (params?: { postId: number; thread: string }) =>
    PostsQueryKey('postCommentsSummary', params),
  publicPostComments: (params?: { postId: number; token: string }) =>
    PostsQueryKey('postComments', params),
  pageUsersWithMobileApp: (pageId: number) =>
    PostsQueryKey('usersWithMobileApp', [pageId]),
  locationsSearch: (input: string) =>
    PostsQueryKey('pageInfoFacebookMentions', input),
  commentsForPostMentionableUsers: (id: number) =>
    PostsQueryKey('commentsForPostMentionableUsers', [id]),
  pageImageTemplates: (id?: number) =>
    PostsQueryKey('pageImageTemplates', [id]),
  hashtagPresets: 'hashtagPresets',
  shortenLinks: 'shortenLinks',
  linkShortenerAvailableDomains: () =>
    PostsQueryKey('linkShortenerAvailableDomains'),
  shortenLinkDetail: 'shortenLinkDetail',
  hashtagPresetsAssignablePages: 'hashtagPresetsAssignablePages',
  postsOnFBPage: 'postsOnFBPage',
  profileGroupDetail: 'profileGroupDetail',
  userOptionsForProfileGroup: 'userOptionsForProfileGroup',
  mentionableUsers: 'mentionableUsers',
  allInspirations: () => PostsQueryKey('inspirations'),
  inspirations: (params?: {
    startDate: string;
    endDate: string;
    pages: number[];
  }) => PostsQueryKey('inspirations', [params]),
  inspirationDetail: 'inspirationDetail',
  pageAudienceRestrictions: (params: {
    term: string;
    type: AudienceRestrictionField;
    pageType: number;
  }) => PostsQueryKey('pageAudienceRestrictions', [params]),
  audienceRestrictionsOptionsByOptionIds:
    'audienceRestrictionsOptionsByOptionIds',
  publicPostPreview: ({ token, id }: { token: string; id: number }) =>
    PostsQueryKey('publicPostPreview', [id, token]),
  postPublicPreviewUrl: 'postPublicPreviewUrl',
  assignedPageUsers: (ids?: number[]) =>
    PostsQueryKey('assignedPageUsers', ids),
  timezones: () => PostsQueryKey('timezones'),
  referralLink: (email: string) => PostsQueryKey('referralLink', [email]),
  textEditorSuggestions: (params: (string | number)[]) =>
    QueryKey('textEditor', 'suggestions', params),
  postActivityLog: (postId: number) =>
    PostsQueryKey('postActivityLog', [postId]),
  dashboardProfiles: (params?: { sortBy: string }) =>
    DashboardQueryKey('dashboardProfiles', params),
  dashboardProfileGroups: (params?: { sortBy: string }) =>
    DashboardQueryKey('dashboardProfileGroups', params),
  dashboardPosts: (params?: PostsRequestParams) =>
    DashboardQueryKey('dashboardPosts', params),
  dashboardMyTasks: (params?: TasksRequestParams) =>
    DashboardQueryKey('dashboardMyTasks', params),
  dashboardTasksStatus: (params?: TasksRequestParams) =>
    DashboardQueryKey('dashboardTasksStatus', params),
  pagesContentPlans: (params?: number[]) =>
    ContentPlanQueryKey('pagesContentPlans', params),
  insightsLiteInvitationAccess: () =>
    PostsQueryKey('insightsLiteInvitationAccess'),
  brandHubProfiles: () => BrandHubQueryKey('profiles'),
  brandHubProfile: (id: string | number) => BrandHubQueryKey('profile', [id]),
  brandHubProfileCreatives: (params?: { id: string | number }) =>
    BrandHubQueryKey('profileCreatives', params),
  brandHubCreateCreative: (id: string | number) =>
    BrandHubQueryKey('createCreative', id),
  brandHubCreativesOptions: () => BrandHubQueryKey('creativesOptions'),
  transformTextOptions: () => PostsQueryKey('transformTextOptions'),
};
