import React, { FC } from 'react';
import ErrorLoadingDetailProperty from 'components/shared/detail/ErrorLoadingDetailProperty';
import { PostLabel } from 'types/Post';
import AccordionWidgetEmptyMessage from 'app/modules/posts/components/accordionWidget/AccordionWidgetEmptyMessage';
import LabelTag from '../LabelTag';
import Skeleton from 'react-loading-skeleton';

type Props = {
  isLoading?: boolean;
  isError?: boolean;
  labels: PostLabel[];
};

const LabelsView: FC<Props> = ({ isLoading, isError, labels }) => {
  return (
    <>
      {isLoading && <Skeleton height={60} />}
      {isError && <ErrorLoadingDetailProperty />}
      {labels && (
        <div className="tw-flex tw-flex-wrap tw-gap-2">
          {labels.map((label) => (
            <LabelTag key={label.id} name={label.name} color={label.color} />
          ))}
        </div>
      )}
      {labels?.length === 0 && !isLoading && !isError && (
        <AccordionWidgetEmptyMessage variant="labels" />
      )}
    </>
  );
};

export default LabelsView;
