import {
  createSearchFromParams,
  createStandardSearch,
  getBasePath,
  openBlank,
} from 'utils/url';
import config from 'config';
import { post } from 'api/client';
import routes from 'constants/routes';
import { formatDate } from 'app/utils/date';
import { Page } from 'app/modules/insights/types/Page';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { getInsightsPageTypeAlias } from 'app/modules/insights/utils/getInsightsPageTypeAlias';

type ExportPdfPostsPerformanceParams = {
  startDate: string;
  endDate: string;
  metric: MetricProperty;
  page: Page;
  types: number[];
};

type ExportPdfCompetitionAnalysisParams = {
  startDate: string;
  endDate: string;
  page: Page;
  showReactions: boolean;
  comparedPageIds: string[];
};

const AnalyticsExportsApi = {
  token: (ids: string): Promise<{ accessToken: string }> => {
    return post(
      `/api/export?ids=${ids}`,
      {},
      {
        baseURL: config.ANALYTICS_ENDPOINT,
      },
    );
  },
  async exportCSVPagePerformance(data: {
    page: Page;
    startDate: string;
    endDate: string;
  }) {
    const { accessToken } = await AnalyticsExportsApi.token(data.page.id);

    const searchParams = createSearchFromParams({
      dateFrom: data.startDate,
      dateTo: data.endDate,
      ids: data.page.id,
      accessToken,
    });

    const socialMediaType = getInsightsPageTypeAlias(data.page._type);

    openBlank(
      `${config.ANALYTICS_ENDPOINT}/${socialMediaType}/export/pages/${searchParams}`,
    );
  },
  async exportPDFPagePerformance(
    data: {
      page: Page;
      startDate: string;
      endDate: string;
      types: number[];
      metric: MetricProperty;
      comparedStartDate?: string;
      comparedEndDate?: string;
    },
    options: {
      type: 'current-selection' | 'overview';
    },
  ) {
    const { accessToken } = await AnalyticsExportsApi.token(data.page.id);
    const exportPublicPath =
      options.type === 'current-selection'
        ? routes.PUBLIC_PDF_REPORTS_INSIGHTS_PAGE_PERFORMANCE_CURRENT_SELECTION
        : routes.PUBLIC_PDF_REPORTS_INSIGHTS_PAGE_PERFORMANCE_OVERVIEW;

    const url = `${
      window.location.origin
    }${getBasePath()}${exportPublicPath}${createStandardSearch({
      startDate: data.startDate,
      endDate: data.endDate,
      page: JSON.stringify(data.page),
      types: data.types,
      metricKey: data.metric.key,
      token: accessToken,
      comparedStartDate: data.comparedStartDate,
      comparedEndDate: data.comparedEndDate,
    })}`;

    const dateRangeForFilename = formatDateRangeForFilename({
      startDate: data.startDate,
      endDate: data.endDate,
    });

    const pdfDocumentConfigParams = createStandardSearch({
      url,
      filename: `${data.page.name} - Report - ${
        options.type === 'overview' ? 'Page overview' : 'Current selection'
      } - ${dateRangeForFilename}`,
      scale: options.type === 'overview' ? 0.6 : undefined,
    });

    return openBlank(
      `${config.SCREENSHOT_SERVICE}/pdf${pdfDocumentConfigParams}`,
    );
  },
  async exportPDFCompetitionAnalysis(
    params: ExportPdfCompetitionAnalysisParams,
  ) {
    const { accessToken } = await AnalyticsExportsApi.token(params.page.id);

    const url = `${window.location.origin}${getBasePath()}${
      routes.PUBLIC_PDF_REPORTS_INSIGHTS_COMPETITION_ANALYSIS
    }${createStandardSearch({
      startDate: params.startDate,
      endDate: params.endDate,
      page: JSON.stringify(params.page),
      showReactions: params.showReactions ? 1 : 0,
      token: accessToken,
      comparedPageIds: params.comparedPageIds,
    })}`;

    const dateRangeForFilename = formatDateRangeForFilename({
      startDate: params.startDate,
      endDate: params.endDate,
    });

    const queryParams = createStandardSearch({
      url,
      filename: `${params.page.name} - Competition analysis ${dateRangeForFilename}`,
    });

    return openBlank(`${config.SCREENSHOT_SERVICE}/pdf${queryParams}`);
  },
  async exportPDFPostsPerformance(params: ExportPdfPostsPerformanceParams) {
    const { accessToken } = await AnalyticsExportsApi.token(params.page.id);

    const url = `${window.location.origin}${getBasePath()}${
      routes.PUBLIC_PDF_REPORTS_INSIGHTS_POSTS_PERFORMANCE
    }${createStandardSearch({
      startDate: params.startDate,
      endDate: params.endDate,
      metricKey: params.metric.key,
      page: JSON.stringify(params.page),
      token: accessToken,
      types: params.types,
    })}`;

    const dateRangeForFilename = formatDateRangeForFilename({
      startDate: params.startDate,
      endDate: params.endDate,
    });

    const queryParams = createStandardSearch({
      url,
      filename: `${params.page.name} - ${params.metric.title} ${dateRangeForFilename}`,
    });

    return openBlank(`${config.SCREENSHOT_SERVICE}/pdf${queryParams}`);
  },
};

function formatDateRangeForFilename({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  return `(${formatDate(startDate)}-${formatDate(endDate)})`.replace(
    /\//g,
    '.',
  );
}

export default AnalyticsExportsApi;
