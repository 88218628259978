import { useAccount } from 'modules/account/accountSelector';
import dayjs from 'dayjs';
import { useMemo } from 'react';

const useSubscriptionInfo = () => {
  const {
    isExpired,
    isTrial,
    lastTrialEndedAt,
    isSubscription,
    featuresPermissions,
    plan,
  } = useAccount();

  const trialDaysLeft = useMemo(() => {
    const today = dayjs();
    const trialEndDate = dayjs(lastTrialEndedAt);
    const difference = trialEndDate.diff(today, 'day');

    return isTrial && difference >= 0 ? difference + 1 : 0;
  }, [isTrial, lastTrialEndedAt]);

  const isTrialExpired = isExpired && isTrial;
  const isSubscriptionExpired = isExpired && isSubscription;
  const accountPlanType = plan.label.toLowerCase().replace(/\s/g, '_');

  return {
    isTrial,
    trialDaysLeft,
    isTrialExpired,
    isSubscriptionExpired,
    featuresPermissions,
    accountPlanType,
  };
};

export default useSubscriptionInfo;
