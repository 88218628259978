import React, { FC, ReactNode } from 'react';
import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';
import BrandHubNewCreativeForm from 'app/modules/brandHub/components/brandHubCreatives/BrandHubNewCreativeForm';
import { useMutation } from 'react-query';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import ArrayUtils from 'app/utils/array';
import TextGeneratedResults from 'app/modules/aiContent/components/results/TextGeneratedResults';
import TextEditorAiContentPopupGenerateFooter from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentPopupGenerateTextFooter';
import {
  BrandHubNewCreativeFormProvider,
  BrandHubNewCreativeFormValues,
} from 'app/modules/brandHub/components/brandHubCreatives/brandHubNewCreativeForm/BrandHubNewCreativeFormProvider';

type Props = {
  renderResultAction?: (text: string) => ReactNode;
  pageType?: number;
};

const TextEditorAiContentPopupGenerateText: FC<Props> = ({
  renderResultAction,
  pageType,
}) => {
  const { accountPlanType } = useSubscriptionInfo();

  const createCreatives = useMutation(
    (values: BrandHubNewCreativeFormValues) => {
      return Promise.all(
        ArrayUtils.generate(values.creativeCount).map((_) =>
          BrandHubApi.createCreative({
            ...values,
            pageType: values.pageType ?? pageType,
            planType: accountPlanType,
          }),
        ),
      );
    },
  );

  const onSubmit = (values: BrandHubNewCreativeFormValues) => {
    if (createCreatives.isLoading) return;

    createCreatives.mutate(values);
  };

  return (
    <div
      className={mergeTailwindClasses(
        clsx('tw-flex tw-h-[410px] tw-w-full tw-flex-col tw-overflow-hidden', {
          'tw-h-[374px]': !createCreatives.isLoading && createCreatives.data,
        }),
      )}
      data-name="ai-content-generate-text"
    >
      <BrandHubNewCreativeFormProvider onSubmit={onSubmit}>
        {(context) => (
          <>
            {!createCreatives.data && !createCreatives.isLoading && (
              <BrandHubNewCreativeForm className="tw-mt-4 tw-px-4 tw-pb-4" />
            )}

            {(createCreatives.data || createCreatives.isLoading) && (
              <TextGeneratedResults
                suggestions={
                  createCreatives.data?.map((creative) => creative) ?? []
                }
                isLoading={createCreatives.isLoading}
                renderAction={renderResultAction}
                classNames={{
                  root: 'tw-overflow-y-auto tw-flex-grow',
                  loadingImg: 'tw-h-[8.5rem]',
                  emptyImg: 'tw-h-[8.5rem]',
                }}
              />
            )}

            {!createCreatives.isLoading && (
              <TextEditorAiContentPopupGenerateFooter
                isLoading={createCreatives.isLoading}
                hasResults={!!createCreatives.data}
                onSubmit={context.submit}
              />
            )}
          </>
        )}
      </BrandHubNewCreativeFormProvider>
    </div>
  );
};

export default TextEditorAiContentPopupGenerateText;
