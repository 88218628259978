import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useDropZone from './DropZoneProvider';

const DropZoneActiveDragOverlay: FC = () => {
  const { isDragActive } = useDropZone();
  const { t } = useTranslation();

  if (!isDragActive) {
    return null;
  }

  return (
    <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded tw-bg-primary-10">
      <span className="tw-text-md tw-font-medium tw-text-primary-100">
        {t('fileUpload.dragToUpload')}
      </span>
    </div>
  );
};

export default DropZoneActiveDragOverlay;
