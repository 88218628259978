import DateUtils from 'app/utils/date';
import dayjs, { Dayjs } from 'dayjs';
import PostsConfig from 'app/config/posts';
import {
  defaultEndDate,
  defaultStartDate,
} from 'app/modules/posts/hooks/useFilterState';

export function getMaximumPostsFilterEndDate(startDate: Dayjs | string) {
  return dayjs(startDate)
    .add(PostsConfig.FILTER_DATE_RANGE_MAX_MONTHS, 'month')
    .format('YYYY-MM-DD');
}

export function getValidPostsFilterDateRange(
  startDate: string,
  endDate: string,
) {
  const start = DateUtils.isValid(startDate) ? startDate : defaultStartDate;
  const end = DateUtils.isValid(endDate) ? endDate : defaultEndDate;

  const diffInMonths = dayjs(end).diff(dayjs(start), 'month', true);

  if (diffInMonths <= PostsConfig.FILTER_DATE_RANGE_MAX_MONTHS) {
    return {
      startDate: start,
      endDate: end,
    };
  }

  const newEndDate = getMaximumPostsFilterEndDate(dayjs(start));

  return {
    startDate: start,
    endDate: newEndDate,
  };
}
