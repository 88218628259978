import { restrictedTypesByMetric } from '../constants/postTypeFilter';

export function getIsMetricDisabled({
  metric,
  pageType,
  optionType,
}: {
  metric: string;
  pageType: number;
  optionType: number[] | number;
}) {
  const restrictedMetrics = restrictedTypesByMetric[pageType]?.[metric];
  const arrayOptionType = Array.isArray(optionType) ? optionType : [optionType];

  return (
    restrictedMetrics &&
    !arrayOptionType.some((type) => restrictedMetrics.includes(type))
  );
}
