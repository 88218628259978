import React, { FC } from 'react';
import { useHashtagsPresetsContainer } from 'app/modules/textEditor/components/hashtagsPresets/HashtagPresets.utils';
import ArrayUtils from 'app/utils/array';
import HashtagsPresetItem from 'app/modules/textEditor/components/hashtagsPresets/HashtagsPresetItem';
import CreateEditHashtagModal from 'app/modules/textEditor/components/hashtagsPresets/CreateEditHashtagModal';
import StringUtils from 'utils/string';
import { Icon } from '@kontentino/ui';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import Skeleton from 'react-loading-skeleton';
import OptionsBarFeature from '../OptionsBarFeature';
import { useTranslation } from 'react-i18next';
type Props = {
  pageId: number;
  onCopyToText?(hashtags: string): void;
  dataName?: string;
  dataCy?: string;
};

const skeletons = ArrayUtils.generate(5);

const HashtagsPresets: FC<Props> = (props) => {
  const container = useHashtagsPresetsContainer(props);

  const { t } = useTranslation();
  const filteredPresets = container.hashtagPresets
    ?.filter((hashtagPreset) =>
      StringUtils.isSubstring(container.filter, hashtagPreset.name),
    )
    .reverse();

  return (
    <>
      <OptionsBarFeature
        title={t('hashtagPresets')}
        iconAfter={
          <Icon icon={faInfoCircle} className="tw-text-grayscale-100" />
        }
        tooltipContent={t('saveCommonlyUsedHashtagsToGroups')}
        itemAction={{
          icon: <Icon icon={faPlus} />,
          onClick: () => container.setHashtagToEdit(null),
        }}
        dataName={props.dataName}
        dataCy={props.dataCy}
        search={{
          action: (value) => container.setFilter(value),
          value: container.filter,
        }}
        manageHref="/dashboard/settings#hashtag-presets"
      >
        {container.isLoading &&
          skeletons.map((i) => <Skeleton height={35} key={i} />)}

        {filteredPresets?.map((hashtagPreset) => (
          <HashtagsPresetItem
            hashtagPreset={hashtagPreset}
            key={hashtagPreset.id}
            onCopyToClipboard={container.copyToClipboard}
            onCopyToText={props.onCopyToText}
            onEdit={(hashtagPreset) =>
              container.setHashtagToEdit(hashtagPreset)
            }
            onDelete={container.remove}
          />
        ))}

        <p className="tw-py-3 tw-text-center tw-text-sm tw-text-grayscale-100">
          {container.hashtagPresets?.length === 0 && t('noHashtagPreset')}

          {filteredPresets?.length === 0 &&
            !!container.hashtagPresets?.length &&
            t('weDidntFindAnyHashtagPreset')}
        </p>
      </OptionsBarFeature>
      <CreateEditHashtagModal
        hashtagToEdit={container.hashtagToEdit}
        onClose={() => container.setHashtagToEdit(undefined)}
      />
    </>
  );
};

export default HashtagsPresets;
