import React, { FC, useEffect, useRef, useState } from 'react';
import {
  NavigationItem,
  NavigationItemKey,
} from 'components/navigation/utils/libs';

const VerticalNavigationContext = React.createContext<{
  hoveredItemKey?: NavigationItemKey;
  wrapperRef: React.RefObject<HTMLDivElement>;
  onMouseEnter: (key: NavigationItemKey) => void;
  isOpen: boolean;
}>({
  hoveredItemKey: undefined,
  wrapperRef: { current: null },
  onMouseEnter: () => {},
  isOpen: false,
});

type VerticalNavigationProviderProps = {
  menuItems: NavigationItem[];
  children: React.ReactNode;
};

export const VerticalNavigationProvider: FC<
  VerticalNavigationProviderProps
> = ({ children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isTempExpanded, setIsTempExpanded] = useState<boolean>(false);
  const [hoveredItemKey, setHoveredItemKey] = useState<NavigationItemKey>();
  const isOpen = isTempExpanded;

  const onMouseEnter = (itemKey: NavigationItemKey) => {
    setHoveredItemKey(itemKey);
    setIsTempExpanded(true);
  };

  useEffect(() => {
    const handleMouseLeave = () => {
      setIsTempExpanded(false);
      setHoveredItemKey(undefined);
    };

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (wrapperRef.current) {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        wrapperRef.current.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  return (
    <VerticalNavigationContext.Provider
      value={{
        hoveredItemKey,
        wrapperRef,
        onMouseEnter,
        isOpen,
      }}
    >
      {children}
    </VerticalNavigationContext.Provider>
  );
};

export const useVerticalNavigation = () => {
  const context = React.useContext(VerticalNavigationContext);

  if (!context) {
    throw new Error('Must be used within VerticalNavigationProvider ');
  }

  return context;
};
