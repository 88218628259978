import clsx from 'clsx';
import { FC } from 'react';
import PostRowHeaderValueTitle from './PostRowHeaderValueTitle';
import { mergeTailwindClasses } from '@kontentino/ui';
import ReactionsIcons from 'app/modules/insights/modules/competitionAnalysis/components/ReactionsIcons';

type Props = {
  canShowReactions: boolean;
};

const FacebookPostRowHeader: FC<Props> = ({ canShowReactions }) => {
  return (
    <div
      className="tw-flex tw-items-center tw-pb-2 tw-text-md tw-font-semibold"
      data-cy="insights_competition-analysis_post-performance-section-headings-facebook"
    >
      <span
        className="tw-w-[7%]"
        data-cy="insights_competition-analysis_post-performance-section-page"
      >
        Page
      </span>
      <span
        className="tw-w-[15%]"
        data-cy="insights_competition-analysis_post-performance-section-post"
      >
        Post
      </span>
      <div
        className={mergeTailwindClasses(
          clsx('tw-flex tw-w-[35%] tw-items-center tw-justify-between', {
            'tw-w-[78%]': !canShowReactions,
          }),
        )}
        data-cy="insights_competition-analysis_post-performance-section-metrics-facebook"
      >
        <PostRowHeaderValueTitle>Interactions</PostRowHeaderValueTitle>
        <PostRowHeaderValueTitle>Comments</PostRowHeaderValueTitle>
        <PostRowHeaderValueTitle>Shares</PostRowHeaderValueTitle>
        {!canShowReactions && (
          <PostRowHeaderValueTitle>Reactions</PostRowHeaderValueTitle>
        )}
      </div>
      {canShowReactions && <ReactionsIcons />}
    </div>
  );
};

export default FacebookPostRowHeader;
