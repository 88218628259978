import { t } from 'i18next';

export const WorkflowStatusUtils = {
  getLabel(status: number) {
    return t(`workflowStatus.label.${status}`);
  },
  getShortLabel(status: number) {
    return t(`workflowStatus.shortLabel.${status}`);
  },
};
