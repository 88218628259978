import { FC } from 'react';
import { InAppNotification } from 'types/InAppNotification';
import {
  useInAppNotificationClick,
  useToggleNotificationRead,
} from 'components/in-app-notifications/InAppNotifications.hooks';
import { Avatar } from '@kontentino/ui';
import { formatDateTimeUTCToLocalTimezone } from 'app/utils/date';
import { notificationMessageHTML } from '@kontentino/in-app-notifications';
import clsx from 'clsx';

type Props = {
  notification: InAppNotification;
};

const ListNotificationItem: FC<Props> = ({ notification }) => {
  const { mutate: toggleRead } = useToggleNotificationRead(
    notification.id,
    notification.is_read,
  );
  const openNotification = useInAppNotificationClick(notification);

  return (
    <div
      className="tw-relative tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded tw-p-4 hover:tw-bg-primary-10"
      onClick={openNotification}
    >
      <Avatar name={notification.author} size={50} />
      <div className="tw-flex tw-flex-col tw-gap-1">
        <div
          className="tw-text-md tw-text-grayscale-180"
          dangerouslySetInnerHTML={{
            __html: notificationMessageHTML({
              // @ts-ignore
              type: notification.type,
              adName: notification.ad_name ?? '',
              adReason: notification.reason ?? '',
              adRecommendation: notification.recommendation ?? '',
              adSetName: '', // TODO
              author: notification.author ?? '',
              facebookImportErrors: notification.errors ?? [],
              pageName: notification.page_name ?? '',
            }),
          }}
        />
        <span className="tw-text-sm tw-text-grayscale-90">
          {formatDateTimeUTCToLocalTimezone(notification.created_at)}
        </span>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleRead();
        }}
        className={clsx(
          'tw-absolute tw-right-4 tw-top-4 tw-h-[12px] tw-w-[12px] tw-rounded-full tw-border tw-text-grayscale-120',
          {
            'tw-bg-primary-100': !notification.is_read,
          },
        )}
      />
    </div>
  );
};

export default ListNotificationItem;
