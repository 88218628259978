import React, { FC, useMemo } from 'react';
import { getMetricsForPageType } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { Page } from 'app/modules/insights/types/Page';
import { Dayjs } from 'dayjs';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { useBestPostsForAllMetricsQuery } from 'app/modules/insights/modules/postsPerformance/hooks/useBestPostsForAllMetricsQuery';
import SimpleGrid from 'app/components/SimpleGrid';
import StandardPostPreview from 'app/modules/insights/components/StandardPostPreview';
import { getMedalRankFromIndex } from 'app/modules/insights/utils/getMedalRankFromIndex';
import { getMetricValue } from 'app/modules/insights/utils/getMetricValue';
import ArrayUtils from 'app/utils/array';
import InsightsConfig from 'app/config/insights';
import PostPreviewPlaceholder from 'app/modules/insights/modules/postsPerformance/components/bestPostsForMetrics/PostPreviewPlaceholder';
import PostsUnavailable from 'app/modules/insights/modules/postsPerformance/components/bestPostsForMetrics/PostsUnavailable';
import { Alert } from '@kontentino/ui';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  types: number[];
  metricsToShow: string[];
};

type RowProps = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  types: number[];
};

const Row: FC<RowProps> = (props) => {
  const { posts, loading, error } = useBestPostsForAllMetricsQuery({
    ...props,
    first: InsightsConfig.PAGE_PERFORMANCE_REPORT_POSTS_PER_METRIC,
  });

  if (!loading && posts === undefined) return null;

  if (posts) {
    if (posts.length === 0) {
      return (
        <div className="tw-mt-4">
          <div className="tw-mb-4 tw-flex tw-items-center tw-text-md tw-font-bold tw-text-grayscale-180">
            {props.metric.title}
          </div>
          <Alert variant="info">
            There are no posts available for this metric.
          </Alert>
        </div>
      );
    }

    return (
      <div className="tw-mt-4">
        <div className="tw-rounded tw-border tw-border-grayscale-20 tw-p-4">
          <div className="tw-mb-4 tw-flex tw-items-center tw-text-md tw-font-bold tw-text-grayscale-180">
            {props.metric.title}
          </div>
          {posts && (
            <>
              {posts.length > 0 && (
                <SimpleGrid
                  columns={
                    InsightsConfig.PAGE_PERFORMANCE_REPORT_POSTS_PER_METRIC
                  }
                  gap={8}
                  className="tw-mb-4"
                >
                  {posts.map(({ post, metrics }, index) => (
                    <StandardPostPreview
                      key={`post-${index}`}
                      post={post}
                      medalRank={getMedalRankFromIndex(index)}
                      sizes={{ avatar: 28, socialIcon: 16 }}
                      showLinkInfo={false}
                      metric={{
                        ...props.metric,
                        value: getMetricValue(metrics, props.metric),
                      }}
                      classNames={{
                        root: 'tw-h-[300px] tw-w-full tw-border tw-border-grayscale-20',
                        profileRoot: '!tw-text-xs',
                        textContent: 'tw-line-clamp-2',
                      }}
                    />
                  ))}
                  {ArrayUtils.generate(
                    InsightsConfig.PAGE_PERFORMANCE_REPORT_POSTS_PER_METRIC -
                      posts.length,
                  ).map((value) => (
                    <PostPreviewPlaceholder
                      key={`post-preview-placeholder-${value}`}
                    />
                  ))}
                </SimpleGrid>
              )}
              {posts.length === 0 && (
                <PostsUnavailable variant="info">
                  There are no posts available for this metric.
                </PostsUnavailable>
              )}
            </>
          )}

          {error && (
            <PostsUnavailable variant="danger">
              {error.message ??
                `We were unable to get posts available for this metric and selected
          time frame in your profile.`}
            </PostsUnavailable>
          )}
        </div>
      </div>
    );
  }

  return null;
};

const TopPostsForMetric: FC<Props> = (props) => {
  const queryableMetrics = useMemo(
    () =>
      Object.values(getMetricsForPageType(props.page._type)).filter(
        (metric) => metric.queryParameter,
      ),
    [props.page._type],
  ).filter((metric) => props.metricsToShow.includes(metric.key));

  return (
    <>
      {queryableMetrics.map((metric, index) => (
        <Row {...props} metric={metric} key={metric.key} />
      ))}
    </>
  );
};

export default TopPostsForMetric;
