import { UserPageConfiguration } from '../../../types/UserPageConfiguration';
import { getDefaultPagePerformanceMetricsOrder } from 'app/modules/insights/modules/pagePerformance/constants/defaultPagePerformanceMetrics';
import { OrderedMetricCard } from '../types/graphs';

export const getUserMetricsConfiguration = (
  pageId: string,
  pageType: number,
  userPageConfiguration: UserPageConfiguration,
): OrderedMetricCard[] => {
  function mergeConfigs(
    userMetricConfig: OrderedMetricCard[],
    defaultMetricConfig: OrderedMetricCard[],
  ) {
    const savedConfigsAlsoInDefault = userMetricConfig.filter((userConfig) =>
      defaultMetricConfig.some(
        (defaultConfig) => defaultConfig.metricKey === userConfig.metricKey,
      ),
    );

    return defaultMetricConfig.map((defaultConfig) => {
      const userConfig = savedConfigsAlsoInDefault.find(
        (mergedArrItem) => mergedArrItem.metricKey === defaultConfig.metricKey,
      );

      return userConfig || defaultConfig;
    });
  }

  return mergeConfigs(
    userPageConfiguration[pageId] ?? [],
    getDefaultPagePerformanceMetricsOrder(pageType),
  );
};
