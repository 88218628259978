import { Button, Icon, mergeTailwindClasses, Tooltip } from '@kontentino/ui';
import React, { ComponentProps, FC } from 'react';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useAiContentQueries from 'app/modules/aiContent/hooks/useAiContentQueries';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';

type Props = {
  regenerate?: boolean;
  dataName?: string;
  dataCy?: string;
} & ComponentProps<typeof Button>;

export const AI_GENERATE_BUTTON_STYLE_CLASSNAME =
  'tw-bg-gradient-to-r tw-from-purple-100 tw-from-0% tw-to-primary-100 tw-to-100% hover:tw-from-purple-120 hover:tw-to-primary-120';

const AIGenerateButton: FC<Props> = ({
  className,
  regenerate,
  dataName,
  dataCy,
  ...rest
}) => {
  const { t } = useTranslation();
  const { accountPlanType } = useSubscriptionInfo();
  const { options } = useAiContentQueries({ accountPlanType });

  const limitReached = options?.data?.remainingAttempts.all === 0;

  return (
    <Tooltip content={limitReached ? t('aiContentLimitReached') : undefined}>
      <div>
        <Button
          variant="primary"
          disabled={limitReached}
          className={mergeTailwindClasses(
            clsx(
              {
                [AI_GENERATE_BUTTON_STYLE_CLASSNAME]: !regenerate,
              },
              className,
            ),
          )}
          iconBefore={<Icon icon={faSparkles} />}
          data-name={
            dataName ?? `ai-content_${regenerate ? 'regenerate' : 'generate'}`
          }
          data-cy={dataCy}
          {...rest}
        >
          {rest.children ?? t(regenerate ? 'regenerate' : 'generate')}
        </Button>
      </div>
    </Tooltip>
  );
};

export default AIGenerateButton;
