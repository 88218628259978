import React, { FC } from 'react';
import { Icon } from '@kontentino/ui';
import {
  WIDGET_VARIANT_PROPERTIES,
  WidgetVariant,
} from 'app/modules/posts/components/accordionWidget/constants/widgetVariant';
import clsx from 'clsx';

type Props = {
  variant: WidgetVariant;
} & React.HTMLAttributes<HTMLDivElement>;

const AccordionWidgetIcon: FC<Props> = ({ variant, className, ...rest }) => {
  const widget = WIDGET_VARIANT_PROPERTIES[variant];

  return (
    <div
      className={clsx(
        'tw-flex tw-h-7 tw-w-7 tw-items-center tw-justify-center tw-rounded-full',
        widget.className,
        className,
      )}
      {...rest}
    >
      <Icon icon={widget.icon} />
    </div>
  );
};

export default AccordionWidgetIcon;
