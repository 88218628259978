import { FC } from 'react';
import clsx from 'clsx';
import { mergeTailwindClasses } from '@kontentino/ui';

export const PASSWORDS_VALIDATION_MESSAGES = {
  length: 'At least 8 characters long',
  uppercase: 'Contains 1 uppercase character',
  numberOrSymbol: 'Contains 1 number or symbol',
};

type Props = {
  errors: string[];
};

const PasswordValidationPoints: FC<Props> = ({ errors }) => {
  return (
    <div>
      {Object.values(PASSWORDS_VALIDATION_MESSAGES).map((message, index) => (
        <div
          key={index}
          className={mergeTailwindClasses(
            clsx('tw-text-success-100', {
              'tw-text-danger-100': errors.includes(message),
            }),
          )}
        >
          <span className="tw-text-md">&#x2022;</span> {message}
        </div>
      ))}
    </div>
  );
};

export default PasswordValidationPoints;
