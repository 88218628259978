import React, { FC, ReactNode } from 'react';
import { ContextMenu } from '@kontentino/ui';
import { useActions } from 'app/hooks/useActions';
import { ActionContextMenuItem } from '../actions/ActionContextMenuItem';
import { ListPost } from 'types/PostDetail';

export type PostContextMenuProps = {
  post: Pick<ListPost, 'id' | 'type' | 'preview_image'>;
  children: ReactNode;
};

export const PostContextMenu: FC<PostContextMenuProps> = ({
  post,
  children,
}) => {
  const { postActions } = useActions('post-card');

  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>{children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className="tw-z-[1]">
          <ActionContextMenuItem
            action={postActions.edit}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            action={postActions.duplicate}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            action={postActions.copyLink}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            action={postActions.shareExternally}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            action={postActions.move}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            disabled={!postActions.downloadAssets.isApplicable(post)}
            action={postActions.downloadAssets}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            separator={{ before: true }}
            action={postActions.changeStatus}
            actionParams={{ id: post.id }}
          />
          <ActionContextMenuItem
            separator={{ before: true }}
            action={postActions.delete}
            actionParams={{ id: post.id }}
          />
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};
