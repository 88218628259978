import {
  PSEUDO_POST_TYPE_OPTIONS_BY_PAGE_TYPE,
  PseudoPostTypeOption,
} from 'app/modules/posts/constants/pseudoPostTypeProperties';
import { PseudoPostType } from 'constants/post';
import { Attachments } from 'types/Attachments';

export const PseudoPostTypesUtils = {
  label: {
    [PseudoPostType.Unknown]: 'Unknown',
    [PseudoPostType.Ad]: 'Ad',
    [PseudoPostType.Status]: 'Status',
    [PseudoPostType.Link]: 'Link',
    [PseudoPostType.Carousel]: 'Carousel',
    [PseudoPostType.Story]: 'Story',
    [PseudoPostType.Gallery]: 'Gallery',
    [PseudoPostType.Video]: 'Video',
    [PseudoPostType.Photo]: 'Photo',
    [PseudoPostType.Event]: 'Event',
    [PseudoPostType.Offer]: 'Offer',
    [PseudoPostType.Reel]: 'Reel',
    [PseudoPostType.PhotoGallery]: 'Photo/Gallery',
  } as Record<PseudoPostType, string>,
  getOptionsByPageType(pageType: number): PseudoPostTypeOption[] {
    return PSEUDO_POST_TYPE_OPTIONS_BY_PAGE_TYPE[pageType] || [];
  },
  findOptionByCombination({
    type,
    subtype,
    pageType,
    attachments,
  }: {
    type: number;
    subtype: number;
    pageType: number;
    attachments: Attachments;
  }) {
    return PseudoPostTypesUtils.getOptionsByPageType(pageType).find(
      (option) => {
        const { type: optionType, subtype: optionSubtype } =
          option.getTypeAndSubtype({ attachments });
        return optionType === type && optionSubtype === subtype;
      },
    );
  },
};
