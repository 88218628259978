import {
  AggregatedMetric,
  MetricCardData,
} from 'app/modules/insights/types/PagePerformance';
import { FC, useMemo } from 'react';
import AggregatedMetricValue from './components/AggregatedMetricValue';
import { Divider } from '@kontentino/ui';

type Props = {
  expanded: boolean;
  metricCardData: MetricCardData;
  isCompared?: boolean;
  dataName?: {
    summaryMetrics?: string;
  };
};

const GENERAL_METRIC_KEYS = ['total', 'daily_average'];

const AggregatedMetricsValues: FC<Props> = ({
  metricCardData,
  isCompared,
  dataName,
  expanded,
}) => {
  const { generalMetrics, specificMetrics } = useMemo(() => {
    let _generalMetrics: AggregatedMetric[] = [];
    let _specificMetrics: AggregatedMetric[] = [];

    if (!expanded) {
      return {
        generalMetrics: metricCardData.generalData.filter(
          (metric) => metric.metricKey === GENERAL_METRIC_KEYS[0],
        ),
        specificMetrics: [],
      };
    }

    metricCardData.generalData.forEach((metric) => {
      if (GENERAL_METRIC_KEYS.includes(metric.metricKey)) {
        _generalMetrics.push(metric);
      } else {
        _specificMetrics.push(metric);
      }
    });

    return {
      generalMetrics: _generalMetrics,
      specificMetrics: _specificMetrics,
    };
  }, [metricCardData.generalData, expanded]);

  if (!generalMetrics.length && !specificMetrics.length) {
    return null;
  }

  return (
    <div
      className="tw-flex tw-flex-wrap tw-items-center tw-gap-8"
      data-name={dataName?.summaryMetrics}
    >
      {generalMetrics.map((item, index) => (
        <AggregatedMetricValue
          key={index}
          label={item.label}
          value={item.value}
          difference={item.difference}
          isCompared={isCompared}
        />
      ))}
      {!!specificMetrics.length && (
        <>
          {generalMetrics.length > 0 && (
            <Divider
              orientation="vertical"
              className="!tw-h-[50px] !tw-bg-grayscale-20"
            />
          )}
          {specificMetrics.map((item, index) => (
            <AggregatedMetricValue
              key={index}
              label={item.label}
              value={item.value}
              difference={item.difference}
              isCompared={isCompared}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default AggregatedMetricsValues;
