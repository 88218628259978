import { ALL_METRICS_PROPERTY } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import {
  areSomeQueryParamsPresent,
  getQueryParameter,
  parseQueryParamToNumbers,
  parseQueryParamToStrings,
  setQueryParameter,
} from 'utils/url';
import { BrowserStorage } from 'utils/browserStorage';
import Logger from 'utils/logger';
import { PostType } from '@kontentino/kontentino-constants/Posts';
import useSetState from 'utils/hooks/useSetState';
import { OrderDirection } from 'app/types';
import dayjs, { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';
import { DatePreset } from 'app/components/datepicker-dropdown/enums';

export type FilterState = {
  selectedProfiles: string[];
  orderDirection: OrderDirection;
  startDate: Dayjs;
  endDate: Dayjs;
  datePreset: string | null;
  selectedPostTypes: number[];
  selectedMetric: string;
  comparedStartDate: Dayjs | null;
  comparedEndDate: Dayjs | null;
};

export const defaultStartDate = dayjs().startOf('month');

export const defaultEndDate = dayjs();

let defaultInitialState: FilterState = {
  selectedProfiles: [],
  orderDirection: OrderDirection.Desc,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  datePreset: DatePreset.THIS_MONTH,
  selectedPostTypes: [],
  selectedMetric: ALL_METRICS_PROPERTY.key,
  comparedStartDate: null,
  comparedEndDate: null,
};

function getInitialFilterState(): FilterState {
  let filtersState = defaultInitialState;

  if (areSomeQueryParamsPresent()) {
    filtersState = getInitialFilterStateFromUrl(filtersState);
  } else {
    filtersState = getInitialFilterStatFromLocalStorage(filtersState);
  }

  return filtersState;
}

function getInitialFilterStatFromLocalStorage(
  initialState: FilterState,
): FilterState {
  function loadFiltersFromStorage(): Partial<FilterState> | null {
    let savedFilters = BrowserStorage.get(
      BrowserStorage.keys.Insights.InsightsFilters,
    );

    if (savedFilters) {
      try {
        const {
          orderDirection,
          selectedMetric,
          selectedProfiles,
          selectedPostTypes,
        } = JSON.parse(savedFilters) as Partial<FilterState>;

        return {
          orderDirection,
          selectedMetric,
          selectedProfiles,
          selectedPostTypes,
        };
      } catch (e) {
        Logger.error(e);
      }
    }

    return null;
  }

  const filters = loadFiltersFromStorage();

  if (filters) {
    return { ...initialState, ...filters };
  }

  return initialState;
}

function getInitialFilterStateFromUrl(initialState: FilterState) {
  try {
    const startDate = dayjs(getQueryParameter('startDate'));
    const endDate = dayjs(getQueryParameter('endDate'));
    const comparedStartDate = dayjs(getQueryParameter('comparedStartDate'));
    const comparedEndDate = dayjs(getQueryParameter('comparedEndDate'));
    const datePreset = getQueryParameter('preselect');

    if (startDate.isValid() && endDate.isValid()) {
      initialState.startDate = startDate;
      initialState.endDate = endDate;
    }

    if (datePreset) {
      initialState.datePreset = datePreset;
    }

    if (comparedStartDate.isValid() && comparedEndDate.isValid()) {
      initialState.comparedStartDate = comparedStartDate;
      initialState.comparedEndDate = comparedEndDate;
    }

    if (!startDate.isValid() && !endDate.isValid()) {
      setQueryParameter('startDate', DateUtils.toDateString(defaultStartDate));
      setQueryParameter('endDate', DateUtils.toDateString(defaultEndDate));
    }

    const pages = parseQueryParamToStrings(getQueryParameter('pages'));

    if (pages.length) {
      initialState.selectedProfiles = pages;
    }

    if (getQueryParameter('order') === OrderDirection.Asc) {
      initialState.orderDirection = OrderDirection.Asc;
    }

    const metric = getQueryParameter('metric');

    if (metric) {
      initialState.selectedMetric = metric;
    }

    const types = parseQueryParamToNumbers(getQueryParameter('types'));

    if (types.length) {
      initialState.selectedPostTypes = types as PostType[];
    }

    // TODO metric but it's dependable on pageType which is not present in url
  } catch (e) {
    Logger.error(e);
  }

  return initialState;
}

export function useFilterState() {
  return useSetState<FilterState>(getInitialFilterState);
}
