import { FC, useEffect, useRef } from 'react';
import Logo from 'components/navigation/verticalNavigation/Logo';
import MenuItems from 'components/navigation/verticalNavigation/mainMenu/MenuItems';
import { NavigationItem } from 'components/navigation/utils/libs';
import UserActions from 'components/navigation/verticalNavigation/userActionsMenu/Profile';
import { User } from 'types/User';
import { animated, useSpring } from '@react-spring/web';
import PublicMenuItems from './verticalNavigation/mainMenu/PublicMenuItems';
import Link from 'components/shared/Link';
import { modalRoutes } from 'constants/routes';
import PublicUserActions from 'components/navigation/verticalNavigation/PublicUserActions';
import { VerticalNavigationWidth } from 'constants/appLayout';
import PerfectScrollbar from 'perfect-scrollbar';
import UserSubActions from 'components/navigation/verticalNavigation/userActionsMenu/UserActionsMenu';
import clsx from 'clsx';
import MenuSecondaryItems from './verticalNavigation/mainMenu/MenuSecondaryItems';
import {
  useVerticalNavigation,
  VerticalNavigationProvider,
} from './verticalNavigation/provider/VerticalNavigationProvider';
import { Divider } from '@kontentino/ui';
import { useActiveModalRoute } from 'context/ModalRouter';

type Props = {
  menuItems: NavigationItem[];
  user: Pick<User, 'role' | 'avatar' | 'name'>;
  activeItem?: NavigationItem;
  isPublic?: boolean;
  logoLinkProps?: Record<string, any>;
};

const VerticalNavigationContent: FC<Props> = (props) => {
  const { isOpen, wrapperRef, hoveredItemKey } = useVerticalNavigation();

  const activeWelcomeModalRoute = useActiveModalRoute(modalRoutes.WELCOME);

  const scrollbar = useRef<PerfectScrollbar>();
  const ref = useRef<HTMLDivElement>(null);

  const styles = useSpring({
    to: {
      width: isOpen
        ? VerticalNavigationWidth.large
        : VerticalNavigationWidth.hidden,
      boxShadow: isOpen
        ? '32px 0px 64px -32px rgba(16, 24, 40, 0.14)'
        : '0 4px 16px rgba(0,0,0,0)',
    },
  });

  useEffect(() => {
    if (ref.current) {
      scrollbar.current = new PerfectScrollbar(ref.current);
    }
  }, [ref]);

  const hoveredItem =
    hoveredItemKey &&
    props.menuItems.find((item) => item.key === hoveredItemKey);

  return (
    <div
      className={clsx('tw-relative', {
        'tw-z-[1001]': !!activeWelcomeModalRoute,
      })}
      ref={wrapperRef}
    >
      <nav
        className={
          'tw-user-select-none tw-absolute tw-z-[20] tw-flex !tw-h-full tw-w-[64px] tw-flex-col tw-border-r tw-border-grayscale-20 tw-bg-white'
        }
        data-name="public-link-sidebar"
      >
        <Logo
          linkProps={{
            'data-name': 'vertical-navigation-logo',
            ...props.logoLinkProps,
          }}
          className={'tw-flex-shrink-0'}
          data-cy="navigation-link-default"
        />
        <div
          className={
            'tw-relative tw-my-4 tw-flex tw-flex-1 tw-flex-col tw-justify-between tw-overflow-y-auto'
          }
          ref={ref}
        >
          {props.isPublic ? (
            <PublicMenuItems menuItems={props.menuItems} />
          ) : (
            <MenuItems
              menuItems={props.menuItems}
              activeItem={props.activeItem}
            />
          )}
        </div>
        {props.isPublic ? (
          <Link
            to={{
              hash: modalRoutes.PUBLIC_INVITE_USER,
            }}
            state={{ actionType: 'navbar' }}
          >
            <PublicUserActions user={props.user} />
          </Link>
        ) : (
          <>
            <UserSubActions />
            <Divider className="tw-my-4 !tw-w-8 tw-self-center" />
            <UserActions user={props.user} />
          </>
        )}
      </nav>
      <animated.div
        className={clsx(
          'tw-r-0 tw-absolute tw-left-0 tw-z-[999] tw-ml-[64px] tw-min-h-full tw-overflow-hidden tw-bg-white',
        )}
        style={styles}
      >
        {hoveredItem && (
          <div className="tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-min-w-[240px] tw-flex-col tw-gap-y-4 tw-bg-white tw-p-4">
            <span className="tw-whitespace-nowrap tw-font-semibold">
              {hoveredItem.label}
            </span>
            <MenuSecondaryItems hoveredItem={hoveredItem} />
          </div>
        )}
      </animated.div>
    </div>
  );
};

const VerticalNavigation: FC<Props> = (props) => (
  <VerticalNavigationProvider {...props}>
    <VerticalNavigationContent {...props} />
  </VerticalNavigationProvider>
);

export default VerticalNavigation;
