import React, { FC } from 'react';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import { useMutation } from 'react-query';
import AnalyticsExportsApi from 'api/analytics/exports';
import { ActiveState } from 'app/modules/insights/hooks/useActiveState';
import { FilterState } from 'app/modules/insights/hooks/useFilterState';
import DateUtils from 'app/utils/date';
type Props = {
  activeState: ActiveState;
  filterState: FilterState;
};

const ExportAsSelect: FC<Props> = ({ activeState, filterState }) => {
  const { mutate } = useMutation(() => {
    if (activeState.page) {
      return AnalyticsExportsApi.exportPDFPostsPerformance({
        startDate: DateUtils.toDateString(filterState.startDate),
        endDate: DateUtils.toDateString(filterState.endDate),
        types: filterState.selectedPostTypes,
        metric: activeState.metric,
        page: activeState.page,
      });
    }

    return Promise.reject();
  });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <IconButton
          className="tw-h-[36px]"
          icon={<Icon icon={faArrowDownToLine} />}
          variant="secondary"
          disabled={!activeState.page}
          data-name="insights-posts-performance-download"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10">
        <DropdownMenu.Item
          className="tw-capitalize"
          onSelect={() => mutate()}
          data-name="insights_posts-performance_download_pdf"
        >
          <Icon icon={faFile} />
          PDF
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default ExportAsSelect;
