import React, { FC, useEffect, useState } from 'react';
import MediaUtils from 'app/utils/media';
import { useToast } from 'app/hooks/useToast';
import FilesUtil from 'utils/files';
import Logger from 'utils/logger';
import i18n from 'i18n';
import ImagePreview from 'app/components/singleImageFilePicker/ImagePreview';
import { Icon } from '@kontentino/ui';
import { faLaptop } from '@fortawesome/pro-regular-svg-icons/faLaptop';
import { useFileDropzone } from 'app/hooks/useFileDropzone';
import DropZone from './fileSourceInput/dropZone';
type Props = {
  onChange: (file: File | null) => void;
  imagePreview?: string;
};
const SingleImageFilePicker: FC<Props> = (props) => {
  const [imagePreview, setImagePreview] = useState<string | undefined>();
  const [file, setFile] = useState<File | undefined>();
  const [fileUrl, setFileUrl] = useState<string | undefined>();
  const dropzone = useFileDropzone({
    onDrop: (files) => handleChange(files),
    accept: MediaUtils.getAcceptedImageAttachments().accept,
    maxFiles: 1,
    multiple: false,
  });
  const toast = useToast();

  useEffect(() => {
    setImagePreview(props.imagePreview);
  }, [props.imagePreview]);

  async function handleChange(files: File[]) {
    try {
      if (files.length === 0) {
        toast('Please select Image', 'info');
        return;
      }

      if (files.length) {
        const [file] = files;
        const error = FilesUtil.isValidImage(file);

        if (error) {
          toast(error, 'error');
          return;
        }

        setFileUrl(URL.createObjectURL(file));
        setFile(file);
        props.onChange?.(file);
      }
    } catch (e) {
      Logger.error(e);
      toast(i18n.somethingWentWrongTryAgain, 'error');
    }
  }

  function removeImage() {
    setFile(undefined);
    setFileUrl(undefined);
    setImagePreview(undefined);
    props.onChange?.(null);
  }

  if (fileUrl && file) {
    return <ImagePreview src={fileUrl} onRemove={removeImage} />;
  }

  if (imagePreview) {
    return <ImagePreview src={imagePreview} onRemove={removeImage} />;
  }

  const inputProps = dropzone.getInputProps();

  return (
    <>
      <input {...inputProps} />
      <DropZone
        {...dropzone.getRootProps()}
        specifications={{
          accept: inputProps.accept,
          multiple: inputProps.multiple,
        }}
        fileSources={[
          {
            shortLabel: 'From device',
            label: (
              <>
                <Icon icon={faLaptop} />
                From your device
              </>
            ),
            onClick: dropzone.open,
            attributes: {
              dataName: 'file-source-device-option',
              dataCy: 'file-source-device-option',
            },
          },
        ]}
        isDragActive={dropzone.isDragActive}
      >
        <DropZone.DropZoneHelperText />
        <DropZone.DropZoneFileSelect />
        <DropZone.DropZoneActiveDragOverlay />
      </DropZone>
    </>
  );
};
export default SingleImageFilePicker;
